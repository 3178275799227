import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const OurHistoryCard = ({ title, description }) => {
	const classes = useStyles();

	return (
		<Paper
			className={`${classes.root} saiba-mais-card`}
			elevation={4}
		>
			<Grid
				container
				className={classes.gridContainer}
				justifyContent="center"
			>
				<Grid
					item
					xs={12}
					container
					alignContent="center"
					className="saiba-mais-grid-img"
				>
					<Typography
						color="secondary"
						align="left"
						className={`${classes.titleText} description-saiba-mais-card poppins residencia gradient-text`}
					>
						{title}
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					className="grid-description-saiba-mais"
				>
					<Typography
						color="secondary"
						align="left"
						className={`${classes.content} description-saiba-mais-card poppins`}
					>
						{description}
					</Typography>
				</Grid>
			</Grid>
		</Paper>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		color: 'unset !important',
		backgroundColor: '#333333 !important',
		height: '359px',
		width: '332px',
		borderRadius: '15px !important',
		padding: '1.5rem',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('xl')]: {
			width: 270,
		},
		[theme.breakpoints.down('lg')]: {
			width: 205,
		},
		[theme.breakpoints.down('md')]: {
			width: 260,
		},
	},
	gridContainer: {
		height: '100%',
	},
	titleText: {
		fontSize: '1.8em !important',
		fontWeight: 'bold !important',
	},
	content: {
		lineHeight: '1.2 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '.85rem !important',
		},
	},
}));

export default OurHistoryCard;
