import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function OnlineBonusCard({ text }) {
	const classes = useStyles();
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLarge = useMediaQuery(theme.breakpoints.down('lg'));

	return (
		<Container
			fixed
			maxWidth="xl"
			disableGutters={true}
			className={`${classes.root} revalida`}
		>
			<Grid className="section">
				<Grid>
					<Typography className={`${classes.title} gradient-text roboto-condensed }`}>
						E ainda, um super bônus:
					</Typography>
				</Grid>
				<Grid
					container
					className={`${classes.mainContainer}`}
				>
					<Grid
						item
						lg={5}
						sm={6}
						className={`${classes.firstContainer} poppins`}
					>
						<Typography className={`${classes.subTitle} roboto-condensed`}>
							Faça 03 estações simuladas
						</Typography>
						<Typography className={`${classes.paragraphStyle} roboto-condensed`}>
							Uma oportunidade única de praticar uma estação real no padrão INEP, com um instrutor
							Hardwork, recebendo feedbacks individualizados seguidos por discussão em grupo.*
						</Typography>
					</Grid>
					<Grid
						item
						container
						lg={7}
						sm={6}
						className={classes.backgroundImageContainer}
					>
						{!matchesMobile && !matchesTablet && !matchesLarge ? (
							<img
								src={require('../assets/img-exclusive-online.png')}
								className={classes.exclusiveBotonContainer}
								alt=""
							/>
						) : null}
					</Grid>
					{matchesMobile || matchesTablet || matchesLarge ? (
						<Grid
							item
							container
							lg={12}
							sm={12}
							className={classes.mobileExclusiveBotonContainer}
						>
							<img
								src={require('../assets/img-exclusive-online.png')}
								className={classes.exclusiveBotonContainer}
								alt=""
							/>
						</Grid>
					) : null}
				</Grid>
				<Grid className={classes.disclaimerContainer}>
					<Typography className={`${classes.disclaimerText} poppins`}>
						*Bônus exclusivo do modelo exclusivamente online. Sujeito a disponibilidade de vagas e horários.
					</Typography>
				</Grid>
			</Grid>
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		marginLeft: 'auto !important',
		marginTop: '4rem !important',
		marginBottom: '4rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
		},
	},
	mainContainer: {
		height: '19rem !important',
		[theme.breakpoints.down('lg')]: {
			flexDirection: 'column !important',
			height: 'auto !important',
			position: 'relative !important',
		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column !important',
			height: 'auto !important',
			position: 'relative !important',
		},
	},
	firstContainer: {
		backgroundColor: '#FFFFFF',
		opacity: 1,
		color: '#000000',
		padding: '2rem !important',
		borderTopLeftRadius: '1.5rem !important',
		borderBottomLeftRadius: '1.5rem !important',
		[theme.breakpoints.down('lg')]: {
			maxWidth: '100% !important',
			padding: '1rem !important',
			borderTopLeftRadius: '1rem !important',
			borderTopRightRadius: '1rem !important',
			borderBottomLeftRadius: '0rem !important',
		},
		[theme.breakpoints.down('md')]: {
			maxWidth: '100% !important',
			padding: '1rem !important',
			borderTopLeftRadius: '1rem !important',
			borderTopRightRadius: '1rem !important',
			borderBottomLeftRadius: '0rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1rem !important',
			borderTopLeftRadius: '1rem !important',
			borderTopRightRadius: '1rem !important',
			borderBottomLeftRadius: '0rem !important',
		},
	},
	backgroundImageContainer: {
		backgroundImage: `url(${require('../assets/bg-bonus-card.png')})`,
		backgroundSize: 'cover',
		borderTopRightRadius: '1.5rem !important',
		borderBottomRightRadius: '1.5rem !important',
		position: 'relative',
		[theme.breakpoints.down('lg')]: {
			maxWidth: '100% !important',
			minHeight: '11rem !important',
			borderRadius: '0rem 1rem 0rem 1rem',
			borderTopRightRadius: '0rem !important',
			position: '',
		},
		[theme.breakpoints.down('md')]: {
			maxWidth: '100% !important',
			minHeight: '11rem !important',
			borderRadius: '0rem 1rem 0rem 1rem',
			borderTopRightRadius: '0rem !important',
			position: '',
		},
		[theme.breakpoints.down('sm')]: {
			minHeight: '8rem !important',
			borderRadius: '0rem 1rem 0rem 1rem',
			borderTopRightRadius: '0rem !important',
			position: '',
		},
	},
	exclusiveBotonContainer: {
		position: 'absolute',
		top: -15,
		right: -25,
		[theme.breakpoints.down('md')]: {
			width: '8rem !important',
			height: 'auto !important',
			position: 'relative',
		},
		[theme.breakpoints.down('sm')]: {
			width: '8rem !important',
			height: 'auto !important',
			position: 'relative',
		},
	},
	mobileExclusiveBotonContainer: {
		position: 'absolute',
		[theme.breakpoints.down('xl')]: {
			top: 0,
			right: -470,
		},
		[theme.breakpoints.down('lg')]: {
			top: 0,
			right: -5,
		},
		[theme.breakpoints.down('md')]: {
			top: 0,
			right: -480,
		},
		[theme.breakpoints.down('sm')]: {
			top: 0,
			right: -240,
		},
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.75rem !important',
		lineHeight: '1 !important',
		marginBottom: '1rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
		},
	},
	subTitle: {
		fontSize: '2.75rem !important',
		fontWeight: 'bold !important',
		marginBottom: '1rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.3rem !important',
		},
	},
	paragraphStyle: {
		width: '90% !important',
		fontSize: '1.5rem !important',
		marginTop: '1.25rem !important',
		textAlign: 'left',
		marginBottom: '1rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '1rem !important',
			marginTop: '0rem !important',
			lineHeight: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
			marginTop: '0rem !important',
			lineHeight: '1rem !important',
		},
	},
	disclaimerContainer: {
		marginTop: '3rem !important',
		[theme.breakpoints.down('lg')]: {
			marginTop: '1rem!important',
		},
		[theme.breakpoints.down('md')]: {
			marginTop: '1rem!important',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '1rem!important',
		},
	},
	disclaimerText: {
		marginTop: '1rem',
		fontSize: '1.1rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
}));
