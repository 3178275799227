import React, { useRef } from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Header } from '../../../../Components/Header';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import ArenaHwmCard from '../../../../Components/Cards/ArenaHwmCard';

export default function LiveDemo() {
	const classes = useStyles();
	const theme = useTheme();
	const arena_card = useRef(null);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="revalida"
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					alignContent={'center'}
					justifyContent={'center'}
					style={matchesMobile ? { marginTop: 100, height: '30vh' } : null}
				>
					<img
						src={require('../../../../Assets/Arena/bg-arena-live-demo.png')}
						alt=""
					/>
					<Grid
						item
						xs={12}
						container
						alignContent={'center'}
						justifyContent={'center'}
						className={`${classes.gridLogoContainer} residencia`}
						rowGap={2}
					>
						<Grid
							item
							xs={10}
							lg={8}
							container
							wrap="nowrap"
							alignContent={'center'}
							justifyContent={'center'}
							columnGap={matchesMobile ? 1 : 2}
						>
							<img
								src={require('../../../../Assets/Arena/logo-live-revalida.svg').default}
								alt=""
								className={classes.logoLiveDemo}
							/>
							<Typography
								color="secondary"
								className={`${classes.title} roboto-condensed`}
							>
								Participe de estações reais no padrão do INEP
							</Typography>
						</Grid>
						<Grid
							item
							xs={10}
							lg={8}
							container
							alignContent={'center'}
							justifyContent={'center'}
						>
							<Typography
								color="secondary"
								className={`${classes.text} poppins`}
							>
								Vamos te mostrar todos os detalhes. Interaja com a gente na estação!
								<br />
								{/* Inscreva-se para saber todos os detalhes do treinamento Hardwork para a segunda fase do
								Revalida INEP! */}
								Dia 05/10 às 17:00h. Inscreva-se gratuitamente.
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Container>
			<Grid container>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					<Grid
						className="section"
						container
						justifyContent={'center'}
						ref={arena_card}
					>
						<ArenaHwmCard
							group="LIVE ABERTURA ARENA HARDWORK 2023.2"
							title="Inscreva-se gratuitamente"
							titleButton={'cadastre-se gratuitamente'}
							urlToRedirect={'/pre-inscricao/abertura-arena-hardwork'}
						/>
					</Grid>
				</Container>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Footer />
			</Container>
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		lineHeight: '1 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down(415)]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down(361)]: {
			fontSize: '1.35rem !important',
		},
	},
	text: {
		fontSize: '1.75rem !important',
		lineHeight: '1.25 !important',
		fontWeight: '500 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	subtitle1: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
			lineHeight: 'unset !important',
		},
	},
	backgroundWaves: {
		backgroundImage: `url(${process.env.PUBLIC_URL + '/Assets/Img/bgWave2.png)'}`,
		width: '100vw !important',
	},
	gradientText: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		marginBottom: '16px !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
		},
	},
	textMultimidiaCard: {
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		alignSelf: 'center',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	imageDiferencialBox: {
		height: 120,
		[theme.breakpoints.down('lg')]: {
			height: 100,
		},
		[theme.breakpoints.down('sm')]: {
			height: 50,
		},
	},
	titleDiferencialBox: {
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	gridDiferencialBox: {
		margin: '50px 0 !important',
		[theme.breakpoints.down('sm')]: {
			margin: '25px 0 !important',
		},
	},
	gridLogoContainer: {
		position: 'absolute',
		height: '100%',
		[theme.breakpoints.down('md')]: {
			marginTop: '80px',
		},
	},
	subscribeBtn: {
		width: 'fit-content',
		padding: '0.5rem 5rem !important',
		alignSelf: 'center',
		fontSize: '1.35rem !important',
		fontWeight: 'bold !important',
		marginTop: '2rem !important',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff !important',
			'& $logoBtn': {
				filter: 'invert(0)',
			},
		},
		[theme.breakpoints.down('xl')]: {
			padding: '0.25rem 2.5rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '0.85rem !important',
			marginTop: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
			padding: '0.25rem 1.25rem !important',
			border: '2px solid transparent !important',
			marginTop: 'unset !important',
		},
	},
	logoBtn: {
		filter: 'invert(1)',
		transition: 'ease-in-out 0.2s',
		[theme.breakpoints.down('xl')]: {
			height: 30,
		},
		[theme.breakpoints.down('sm')]: {
			height: 17,
		},
	},
	logoLiveDemo: {
		width: '30%',
		[theme.breakpoints.down('lg')]: {
			width: '20%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '30%',
		},
	},
}));
