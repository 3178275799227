import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// ICONS
import CellPhone from '../../Assets/Extensivo/R1/2023/icon-cellPhone.svg';
import Sheets from '../../Assets/Extensivo/R1/2023/icon-sheets.svg';
import Computer from '../../Assets/Extensivo/R1/2023/icon-computer.svg';
import CellphoneMessage from '../../Assets/Extensivo/R1/2023/icon-cellphoneMessage.svg';
import TeacherVideo from '../../Assets/Extensivo/R1/2023/icon-teacherVideo.svg';
import Performance from '../../Assets/Extensivo/R1/2023/icon-performance.svg';
import Groups from '../../Assets/Extensivo/R1/2023/icon-groups.svg';
import CommentedQuestions from '../../Assets/Extensivo/R1/2023/icon-commentedQuestions.svg';

// COMPONENTS
import DefaultButton from '../Buttons/DefaultButton';

// TODO Create Horizontal Scroll

export default function DiferencialBox({ imageUrl, title, description, details, type }) {
	const classes = useStyles();

	const [openDetails, setOpenDetails] = useState();

	const showDetails = () => {
		setOpenDetails(!openDetails);
	};

	return (
		<Grid
			container
			className={classes.root}
		>
			<Grid
				item
				xs={2}
				container
				justifyContent="center"
				alignContent="center"
				style={{ maxHeight: 124 }}
			>
				{' '}
				{type === 'purple' || type === 'green' ? (
					<img
						src={imageUrl}
						alt=""
					/>
				) : (
					<img
						src={
							title === 'Banco de lives Hardwork'
								? CellPhone
								: title === 'Banco de questões'
								? Sheets
								: title === 'Acesso aos principais livros'
								? Computer
								: title === 'Fala professor!'
								? CellphoneMessage
								: title === 'Simulados com comentários em vídeo'
								? TeacherVideo
								: title === 'Desempenho por concurso'
								? Performance
								: title === 'Resumos Hardwork'
								? CommentedQuestions
								: title === 'Grupos de discussão'
								? Groups
								: null
						}
						alt=""
					/>
				)}
			</Grid>
			<Grid
				item
				xs={10}
				container
				className={classes.gridContainer}
			>
				<Grid
					item
					container
					alignContent="center"
				>
					<Typography
						className={`${classes.title} poppins`}
						color="secondary"
					>
						{title}
					</Typography>
				</Grid>
				<Grid
					item
					container
					alignContent="flex-start"
					className={classes.descriptionHeight}
				>
					<Typography
						className={`${classes.text} poppins`}
						color="secondary"
					>
						{description}
					</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				className={`${classes.gridButton} ${openDetails ? classes.gridBtnHeight : null} ${
					type === 'purple' ? null : type === 'green' ? 'revalida' : 'residencia'
				}`}
			>
				{details ? (
					<DefaultButton
						variant="outlined"
						color="secondary"
						className={`${classes.btn} btn btn-secondary`}
						onClick={() => showDetails()}
					>
						{openDetails ? '- Detalhes' : '+ Detalhes'}
					</DefaultButton>
				) : null}

				{openDetails ? (
					<Grid
						item
						container
						justifyContent="flex-start"
						style={{ marginTop: 20 }}
					>
						<Typography
							className={`${classes.text} poppins`}
							color="secondary"
							style={{ width: '80%' }}
						>
							{details}
						</Typography>
					</Grid>
				) : null}
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		width: '100%',
		padding: '1.5rem',
		display: 'flex',
		alignContent: 'flex-start',
		marginBottom: 30,
		[theme.breakpoints.down('sm')]: {
			padding: '1rem 0 !important',
			marginBottom: 'unset',
		},
	},
	text: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	gridContainer: {
		paddingLeft: '2em',
		height: 124,
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
			marginBottom: '8px !important',
		},
	},
	gridButton: {
		paddingLeft: '19%',
		[theme.breakpoints.down('lg')]: {
			marginTop: 20,
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: 0,
		},
	},
	title: {
		fontSize: '1.2rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.15rem !important',
		},
	},
	btn: {
		fontWeight: 'bold',
		width: '30%',
		maxHeight: 35,
		[theme.breakpoints.down('lg')]: {
			width: '40%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '50%',
			fontSize: '.8rem !important',
		},
	},
	gridBtnHeight: {
		transition: 'all .2s',
		display: 'flex',
		alignContent: 'flex-start',
	},
	descriptionHeight: {
		height: 70,
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
		},
	},
}));
