import React, { useRef } from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

// COMPONENTS
import Footer from '../../Components/Footer';
import { Header } from '../../Components/Header';
import DiferencialBox from '../../Components/Cards/DiferencialBox';

// TO IMPLEMENT
// import FaqSection from '../../Components/Sections/FaqSection';
// import Newsletter from '../../Components/Utils/Newsletter';

// ASSETS
import BackgroundImage from '../../Assets/Extensivo/R3CM/backgroundExtensivoR3Clinica.png';
import ExtensivoR3ClinicaMedicaLogo from '../../Assets/Extensivo/R3CM/extensivoR3ClinicaMedica.svg';
import ClassesImg from '../../Assets/Extensivo/R3CM/classes.svg';
import CellphoneLives from '../../Assets/Extensivo/R3CM/cellphoneLives.svg';
import Sheets from '../../Assets/Extensivo/R3CM/sheets.svg';
import Library from '../../Assets/Extensivo/R3CM/library.svg';
import CellphoneTeacher from '../../Assets/Extensivo/R3CM/cellphoneTeacher.svg';
import Mocks from '../../Assets/Extensivo/R3CM/mocks.svg';
import Bonfire from '../../Assets/Extensivo/R3CM/bonfire.svg';
import Group from '../../Assets/Extensivo/R3CM/group.svg';
// import MultimidiaHwm from '../../Assets/Extensivo/R3CM/multimidiaHwm.svg';
import BagIcon from '../../Assets/Svg/Icons/bagIcon.svg';

// import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import FirstSection from '../../Components/Sections/FirstSection';
import LearningPath from '../../Components/Sections/LearningPath';
import StudentsFeedbackSection from '../../Components/Sections/StudentsFeedback';
import PriceCardVersion2 from '../../Components/Cards/PriceCardVersion2';

const learningPathTemplate = [
	{
		title: 'Primeira liga',
		description: [
			'A trilha do aprendizado funciona por ligas de evolução. Começaremos por temas mais palpáveis, evoluindo para outros mais peculiares, transitando por todas as especialidades clínicas.',
			<>
				<br />
				<br />
			</>,
			'São 5 ligas no total, cada uma com temas diferentes. Para passar para a próxima, precisaremos dominar por completo a atual.',
		],
		bgColor: '#ffffff',
		titleColor: '#121212',
		descriptionColor: 'primary',
		// url: 'https://player.vimeo.com/video/769930065',
	},
	{
		title: 'Aula Hardwork',
		description: [
			'Tudo começa com a apresentação ao tema, que no Hardwork é feito com rigor em didática.',
			<>
				<br />
				<br />
			</>,
			'São as melhores aulas que você já viu sobre cada tema.',
		],
		bgColor: '#0D0D0D',
		titleColor: 'purple',
		descriptionColor: 'secondary',
		// url: 'https://player.vimeo.com/video/769929590',
	},
	{
		title: 'Estudo por questões',
		description: [
			'Alguns dias após a apresentação ao tema, vamos te conduzir pelo estudo por questões.',
			<>
				<br />
				<br />
			</>,
			'Aqui você entenderá todas as formas em que cada parte do tema é explorado nas provas de especialidades clínicas.',
			<>
				<br />
				<br />
			</>,
			'O professor Hardwork te mostrará tudo em detalhes, aprofundando em cada tema.',
		],
		bgColor: '#0D0D0D',
		titleColor: 'purple',
		descriptionColor: 'secondary',
		// url: 'https://player.vimeo.com/video/769929662',
	},
	{
		title: 'Revisões cíclicas e personalizadas',
		description: [
			'Questões, questões e questões!',
			<br />,
			'É assim que revisamos no Hardwork!',
			<>
				<br />
				<br />
			</>,
			'Toda a dinâmica de revisões é, na verdade, uma continuidade do estudo por questões, explorando com mais ênfase os pontos que você tiver mais dificuldade.',
			<>
				<br />
				<br />
			</>,
			'Todos os temas precisam ser dominados!',
		],
		bgColor: '#0D0D0D',
		titleColor: 'purple',
		descriptionColor: 'secondary',
		// url: 'https://player.vimeo.com/video/769929881',
	},
	{
		title: 'Avaliação de desempenho',
		description: [
			'A regra é dominar temas! A avaliação de desempenho e domínio dos temas será diária, usando métricas do estudo por questões.',
			<>
				<br />
				<br />
			</>,
			'Assim que o grau de suficiência em um tema for atingido, ele estará dominado!',
			<>
				<br />
				<br />
			</>,
			'Domine todos e migre para a próxima liga de aprendizado.',
		],
		bgColor: '#0D0D0D',
		titleColor: 'purple',
		descriptionColor: 'secondary',
		// url: 'https://player.vimeo.com/video/769929824',
	},
	{
		title: 'Próxima liga',
		description: [
			'A evolução é contínua, são 5 ligas no total. Na liga do aprendizado seguinte teremos novos temas, ou fragmentos complementares de temas anteriores.',
			<>
				<br />
				<br />
			</>,
			'Mas não pense que os já vistos serão esquecidos... jamais! As revisões serão contínuas, até o fim!',
		],
		bgColor: '#ffffff',
		titleColor: '#121212',
		descriptionColor: 'primary',
		// url: 'https://player.vimeo.com/video/769929780',
	},
];

export default function ExtensivoR3ClinicaMedica() {
	const classes = useStyles();
	const theme = useTheme();
	const subscribe_card_extensivo_r1_23_24 = useRef(null);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const executeScroll = () =>
		subscribe_card_extensivo_r1_23_24.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="clinica-medica"
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<FirstSection
					backgroundImageUrl={BackgroundImage}
					logoUrl={ExtensivoR3ClinicaMedicaLogo}
					buttonText="Quero me inscrever"
					type="purple"
					executeScroll={executeScroll}
					firstTextPaper={[
						<Typography
							align="left"
							color="secondary"
							className={classes.firstText}
						>
							A revolução do estudo por questões focada na prova de
						</Typography>,
						<Typography
							variant="h1"
							className={`${classes.gradientText} clinica-medica gradient-text roboto-condensed`}
						>
							R3 de Clínica Médica
						</Typography>,
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							Aqui dominamos todos os temas importantes para as provas de especialidades clinicas. Foco
							total no R3!
						</Typography>,
					]}
				/>
			</Container>

			<LearningPath
				title={'Trilha do aprendizado definitivo'}
				cardsInfo={learningPathTemplate}
				background={'background-gradient-purple'}
			/>

			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						container
						style={matchesMobile ? { marginBottom: 16 } : null}
					>
						<Typography
							className={`${classes.subtitle1} roboto-condensed`}
							color="secondary"
						>
							Assim que o extensivo começar você já ganha
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
					>
						<DiferencialBox
							type="purple"
							imageUrl={CellphoneLives}
							title="Banco de lives Hardwork"
							description="Acesso imediato ao banco de lives de Clínica médica do Hardwork Medicina, com discussões dos principais temas voltados para prova de especialidades clinicas."
							details="Acesso ao banco de lives com temas das diversas áreas, numa conversa informal e olho-no-olho, no padrão consagrado do Hardwork Medicina."
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
					>
						<DiferencialBox
							type="purple"
							imageUrl={Sheets}
							title="Banco de questões"
							description="Mais de 100 mil questões de provas dos principais concursos de residência do Brasil."
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
					>
						<DiferencialBox
							type="purple"
							imageUrl={Library}
							title="Acesso aos principais livros"
							description={[
								'Apostila? De jeito nenhum!',
								<>
									<br />
								</>,
								'Tu vais estudar junto com o professor pelas grandes obras!',
							]}
							details="Sim! No extensivo tu terás acesso a milhares de obras consagradas...Vamos estudar pelas principais referências. Tudo JUNTO com o professor! Vamos evoluir frase por frase dentro dos problemas explorados pelas questões. Nada de se restringir a textos resumidos! Vamos dominar as principais fontes! Vamos te mostrar como."
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
					>
						<DiferencialBox
							type="purple"
							imageUrl={CellphoneTeacher}
							title="Fala professor!"
							description={[
								'A base do aprendizado é a relação mestre-aprendiz!',
								<>
									<br />
								</>,
								'No Hardwork o seu contato com o professor será direto!',
							]}
							details="Não existe escola em que o aluno não fale com o professor! Aqui o contato é direto, todos os alunos têm acesso aos professores, tanto nos times, quanto via whatsapp."
						/>
					</Grid>
				</Grid>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						container
						style={matchesMobile ? { marginBottom: 16 } : null}
					>
						<Typography
							className={`${classes.subtitle1} roboto-condensed`}
							color="secondary"
						>
							Receba também
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
					>
						<DiferencialBox
							type="purple"
							imageUrl={ClassesImg}
							title="Simulados com comentários em vídeo"
							description="Simulados mensais a partir de abril, com análise de desempenho e comentários em vídeo."
							details="A partir de abril de 2023 teremos simulados mensais, com questões de provas antigas, com objetivo único de identificar lacunas na preparação e permitir ajustes no estudo enquanto há tempo. Nada de ranking! Aqui fazemos análise de desempenho questão por questão...além disso, todos os simulados trazem consigo questões comentadas em texto e em vídeos pelos professores. Ah, mas se tu quiseres, podes configurar um simulado para fazer a qualquer momento!"
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
					>
						<DiferencialBox
							type="purple"
							imageUrl={Bonfire}
							title="Desempenho por concurso"
							description="Cada prova tem sua alma."
							details="Vamos te mostrar toda a tua evolução de acordo com as provas que farás.. Afinal, cada prova tem seus temas de predileção, sua forma de abordar cada assunto."
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
					>
						<DiferencialBox
							type="purple"
							imageUrl={Mocks}
							title="Resumos Hardwork"
							description="Resumos dos principais temas explorados nas provas de residência médica."
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
					>
						<DiferencialBox
							type="purple"
							imageUrl={Group}
							title="Grupos de discussão"
							description="Grupos com professores, mentores e alunos Hardwork, para discussão de tudo relacionado às provas!
Seguiremos juntos, sempre!"
							details="Tu farás parte de um time com um grupo de hardworkers, capitaneado por um tutor HWM... o objetivo? Aprender com os pares! Troca de informações, de opiniões, auxílio na orientação dos estudos, na resolução daquelas questões aparentemente espinhosas...Ah, no grupo também estão todos os professores do HWM."
						/>
					</Grid>
				</Grid>
			</Container>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
					ref={subscribe_card_extensivo_r1_23_24}
				>
					<PriceCardVersion2
						idTurma={'1175'}
						// imageBackground={'../../Assets/Extensivo/R3CM/cardBackground.png'}
						logo={ExtensivoR3ClinicaMedicaLogo}
						iconBtn={BagIcon}
						parcel={'12'}
						valuePerParcel={'474,17'}
					/>
				</Grid>
			</Container>

			<StudentsFeedbackSection
				type="clinica medica"
				title="Depoimentos de nossos alunos"
			/>

			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{/* <Grid
					item
					xs={12}
					container
					justifyContent="center"
				>
					<Grid
						item
						container
						justifyContent="space-around"
						style={{
							width: 386,
							backgroundColor: '#0D0D0D',
							borderRadius: 10,
							padding: 8,
							height: 148,
						}}
					>
						<img
							src={MultimidiaHwm}
							alt="Multimídia Hardwork"
						/>
						<Typography
							color="secondary"
							className={`${classes.textMultimidiaCard} poppins`}
						>
							Ganhe acesso ao treinamento
							<br />
							multimídia 2022 do HWM
						</Typography>
					</Grid>
				</Grid> */}
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	subtitle1: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
			lineHeight: 'unset !important',
		},
	},
	gradientText: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		lineHeight: '1.5 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.68rem !important',
		},
	},
	text: {
		fontSize: '1.75rem !important',
		lineHeight: '1.25 !important',
		marginTop: '8px !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	firstText: {
		fontSize: '3rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
	},
	textMultimidiaCard: {
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		alignSelf: 'center',
	},
}));
