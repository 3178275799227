import { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function FlipCard({ image, title, description, color, size, noFlip }) {
	const classes = useStyles();
	const [isClicked, setIsClicked] = useState(false);

	const handleClick = () => {
		setIsClicked(!isClicked);
	};

	return (
		<Grid
			item
			container
			alignContent={'center'}
			justifyContent={'center'}
			className={`${noFlip ? classes.noFlip : classes.face} ${isClicked && classes.active}`}
			onClick={noFlip ? null : handleClick}
		>
			<Grid
				item
				container
				alignContent={'center'}
				className={classes.front}
			>
				<Grid
					item
					xs={12}
					container
					justifyContent="center"
					alignContent="center"
				>
					<img
						src={image}
						alt=""
						className={classes.imageDiferencialBox}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					container
					justifyContent="center"
					alignContent="center"
					style={{ marginTop: 24 }}
				>
					<Typography
						color={color}
						align="center"
						className={`${
							window.location.pathname === '/sprint-final'
								? classes.titleDiferencialBoxSF
								: classes.titleDiferencialBox
						} poppins`}
					>
						{title}
					</Typography>
				</Grid>
			</Grid>
			<Grid
				item
				container
				alignContent={'center'}
				className={classes.back}
			>
				<Typography
					color={color}
					align="center"
					className={`${classes.titleDiferencialBox} poppins`}
				>
					{description}
				</Typography>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	face: {
		perspective: '700px',
		// width: '298px !important',
		// height: '230px !important',
		'& $front': {
			height: '100%',
		},
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				'& $front': {
					transform: 'rotateY(180deg)',
					zIndex: 0,
				},
				'& $back': {
					transform: 'rotateY(0)',
					zIndex: 1,
					backgroundColor: window.location.pathname === '/sprint-final' ? '#1c1b1b' : '#0D1A17',
				},
			},
		},
		[theme.breakpoints.down('xl')]: {
			width: '95% !important',
		},
		[theme.breakpoints.down('sm')]: {
			width: '90% !important',
		},
	},
	noFlip: {
		perspective: '700px',
		'& $front': {
			height: '100%',
		},
		[theme.breakpoints.down('xl')]: {
			width: '95% !important',
		},
		[theme.breakpoints.down('sm')]: {
			width: '90% !important',
		},
	},
	active: {
		'& $front': {
			transform: 'rotateY(180deg)',
			zIndex: 0,
		},
		'& $back': {
			transform: 'rotateY(0)',
			zIndex: 1,
			backgroundColor: window.location.pathname === '/sprint-final' ? '#1c1b1b' : '#0D1A17',
		},
	},
	front: {
		width: '100% !important',
		position: 'absolute !important',
		zIndex: 1,
		transition: '700ms',
		padding: '25px !important',
		backgroundColor:
			window.location.pathname === '/sprint-final'
				? '#1c1b1b'
				: window.location.pathname === '/sprint-final-r3'
				? '#12131ccc'
				: '#0D1A17',
		borderRadius: '15px !important',
		[theme.breakpoints.down('sm')]: {
			padding: '15px !important',
		},
	},
	back: {
		height: '100% !important',
		width: '100% !important',
		position: 'absolute !important',
		padding: '25px !important',
		transform: 'rotateY(-180deg)',
		backfaceVisibility: 'hidden',
		transition: '700ms',
		borderRadius: '15px !important',
		[theme.breakpoints.down('sm')]: {
			padding: '15px !important',
		},
	},
	imageDiferencialBox: {
		height: 120,
		[theme.breakpoints.down('lg')]: {
			height: 100,
		},
		[theme.breakpoints.down('sm')]: {
			height: 50,
		},
	},
	titleDiferencialBox: {
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.65rem !important',
		},
	},
	titleDiferencialBoxSF: {
		fontSize: '0.9rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.65rem !important',
		},
	},
}));
