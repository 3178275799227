import React from 'react';
import { Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DefaultButton from '../Buttons/DefaultButton';
import FinalSprintImage from '../Assets/FinalSprintImage.png';
import FinalSprintR3Image from '../../Assets/SprintFinal/sprintFinalR3.svg';
import ProvasSpImage from '../../Assets/SprintFinal/provasSp.svg';
import TrilhaR1Maio from '../../Assets/Extensivo/R1/2023-24/logoR1-may.svg';
import ExtensivoImage from '../../Assets/Extensivo/R1/2023/extensivoR1.svg';
import ExtensivoImage_2023_2034 from '../../Assets/Extensivo/R1/2023-24/extensivoR12324LogoCard.svg';
import ExtensivoImage2034 from '../../Assets/Extensivo/R1/2023-24/logo-extensivo-r1-2024.svg';
import TrilhaR1Express from '../../Assets/Extensivo/R1/Express/logo-r1-express.svg';
import RevalidaHWM from '../../Assets/Extensivo/Revalida/revalidaHWM.svg';
import TrilhaRevalida from '../../Assets/Extensivo/Revalida/logo-card-trilha-revalida.png';
import ArenaHWM from '../../Assets/Arena/arenaHWM2024.svg';
import RevalidaLive from '../../Assets/LDL/revalidaLive.svg';
import ExtensivoR3 from '../../Assets/Extensivo/R3CM/extensivoR3.svg';
import ExtensivoCirurgiaGeral from '../../Assets/Extensivo/R3CG/extensivoCirurgiaGeral.svg';
import LiveDasLives from '../../Assets/LDL/liveDasLives.svg';
import SprintFinalRevalida from '../../Assets/SprintFinal/logo-sf-revalida-23.svg';
import LiveDasLivesRevalida from '../../Assets/LDL/liveDasLivesRevalida.svg';
import SprintFinalUSP_R3 from '../../Assets/Extensivo/R3CM/logo-sf-usp-r3.svg';
import TrilhaR1_Residencia from '../../Assets/Extensivo/R1/2023-24/logo-trilha-r1-residencia.svg';
import TrilhaR3_Cm from '../../Assets/Extensivo/R1/2023-24/logo-trilha-r3-cm.png';
import TrilhaR3_Cg from '../../Pages/LandingPages/Sale/SprintFinal/assets/logo-cg-white.svg';
import TrilhaR1_Residencia_2_anos from '../../Assets/Extensivo/R1/2023-24/LogoR1DoisAnos.png';
import RPlus_lauching from '../../Assets/Extensivo/RPlus/logo-rplus.svg';
import SprintR3ClinicaMedica from '../../Assets/SprintFinal/logo-sprint-r3-cm.svg';
import TrilhaR1Residencia2025 from '../../Assets/Extensivo/R1/2025/logo-trilha-25.svg';
import Trilha2anos from '../../Pages/LandingPages/Assets/LogoR1DoisAnos.png';

import { Link } from 'react-router-dom';

const SaibaMaisCard = ({ type, name, description, url, disabled, form, handleOpenForm }) => {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLaptop = useMediaQuery(theme.breakpoints.down('lg'));

	function getImageByName(name) {
		switch (name) {
			case 'Provas de São Paulo':
				return ProvasSpImage;
			case 'Sprint Final Hardwork':
				return FinalSprintImage;
			case 'Sprint Final Hardwork R3':
				return FinalSprintR3Image;
			case 'Extensivo':
				return ExtensivoImage;
			case 'Extensivo-23-24':
				return ExtensivoImage_2023_2034;
			case 'Extensivo-24':
				return ExtensivoImage2034;
			case 'Trilha-25-26':
				return Trilha2anos;
			case 'Revalida':
				return RevalidaHWM;
			case 'Trilha Revalida':
				return TrilhaRevalida;
			case 'Arena Hardwork':
				return ArenaHWM;
			case 'Revalida Live':
				return RevalidaLive;
			case 'Extensivo R3':
				return ExtensivoR3;
			case 'Extensivo Cirurgia':
				return ExtensivoCirurgiaGeral;
			case 'Lives':
				return LiveDasLives;
			case 'Sprint Final Revalida':
				return SprintFinalRevalida;
			case 'Live Revalida':
				return LiveDasLivesRevalida;
			case 'Sprint Final USP R3':
				return SprintFinalUSP_R3;
			case 'Trilha R1 Residência':
				return TrilhaR1_Residencia;
			case 'Trilha R3 CM':
				return TrilhaR3_Cm;
			case 'Trilha R3 CG':
				return TrilhaR3_Cg;
			case 'Trilha R1 2 ANOS':
				return TrilhaR1_Residencia_2_anos;
			case 'R1Maio':
				return TrilhaR1Maio;
			case 'Trilha R1 Residência Express':
				return TrilhaR1Express;
			case 'RPlus':
				return RPlus_lauching;
			case 'Trilha R3 Clínica Médica':
				return SprintR3ClinicaMedica;
			case 'Trilha R1 Residência 2025':
				return TrilhaR1Residencia2025;
			default:
				return null;
		}
	}

	return (
		<Paper
			className={`${classes.root} box box-primary saiba-mais-card border-hwm`}
			style={
				disabled || (!url && !form)
					? { cursor: 'not-allowed', opacity: 0.5 }
					: form
					? { cursor: 'unset' }
					: null
			}
			elevation={4}
			component={Link}
			to={url || null}
			// target="_blank"
		>
			<Grid
				container
				justifyContent="center"
				alignContent="center"
			>
				<Grid
					item
					xs={12}
					container
					alignContent="center"
					justifyContent="center"
					className="saiba-mais-grid-img"
					style={
						matchesMobile || matchesTablet || matchesLaptop
							? { width: '163px', height: '73.2px' }
							: { width: '285px', height: '128px' }
					}
				>
					{
						<img
							src={getImageByName(name)}
							alt=""
						/>
					}
				</Grid>
				<Grid
					item
					xs={12}
					className="grid-description-saiba-mais"
					style={{ marginTop: 8 }}
				>
					<Typography
						color="secondary"
						align="left"
						className={`${classes.content} description-saiba-mais-card poppins`}
					>
						{description}
					</Typography>
				</Grid>
				{type === 'residencia' ? (
					<Grid
						item
						xs={12}
						container
						justifyContent={matchesMobile ? 'flex-end' : 'flex-start'}
						className={`${classes.gridButton} residencia`}
					>
						<DefaultButton
							variant="outlined"
							color="secondary"
							className={`${classes.btn} saiba-mais-btn btn btn-secondary`}
							onClick={form ? () => handleOpenForm() : ''}
							// component={Link}
							// to={name === 'Extensivo' ? '/extensivo-r1' : null}
						>
							{form ? 'Avise-me' : 'Saiba Mais'}
						</DefaultButton>
					</Grid>
				) : (
					<Grid
						item
						xs={12}
						container
						justifyContent={matchesMobile ? 'flex-end' : 'flex-start'}
						className={`${classes.gridButton} revalida`}
					>
						<DefaultButton
							variant="outlined"
							color="secondary"
							className={`${classes.btn} saiba-mais-btn btn btn-secondary`}
							// style={name === 'Live Revalida' ? { cursor: 'not-allowed' } : null}
						>
							Saiba Mais
						</DefaultButton>
					</Grid>
				)}
			</Grid>
		</Paper>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		color: 'unset !important',
		maxWidth: 325,
		minWidth: '92%',
		height: 354,
		borderRadius: '15px !important',
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'center',
		backgroundColor: '#333333 !important',
		cursor: 'pointer',
		textDecoration: 'none',
		padding: '1.25rem',
		[theme.breakpoints.down('xl')]: {
			height: '310px !important',
			maxWidth: '93%',
		},
		[theme.breakpoints.down('lg')]: {
			height: '260px !important',
			padding: '10px 20px 20px',
		},
		[theme.breakpoints.down('md')]: {
			height: '300px !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0.5rem 0.75rem 1rem',
			height: '280px !important',
			maxWidth: 'unset !important',
		},
	},
	content: {
		lineHeight: '1.2 !important',
		fontSize: '0.95rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '.85rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '.7rem !important',
		},
	},
	gridButton: {
		position: 'fixed',
		bottom: -25,
		left: '10%',
		[theme.breakpoints.down('lg')]: {
			bottom: -17,
		},
		[theme.breakpoints.down('sm')]: {
			left: '-7%',
			bottom: -15,
		},
	},
	btn: {
		fontSize: '1rem !important',
		width: '62%',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.75rem !important',
			minHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.65rem !important',
			width: '65%',
		},
	},
}));

export default SaibaMaisCard;
