import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Container, Grid, Modal, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { styled, Box } from '@mui/system';

import FabricioValois from './Assets/fabricio.png';
import FabioColagrossi from './Assets/fabioColagrossi.png';
import FabioCabar from './Assets/fabioCabar.png';
import ClaudioLima from './Assets/claudioLima.png';
import CynaraCarvalho from './Assets/cynaraCarvalho.png';
import LaisKomatsu from './Assets/laisKomatsu.png';
import EricCosta from './Assets/ericCosta.png';
import DecarthonVitor from './Assets/decarthonVitor.png';
import Sophia from './Assets/sophia.png';
import FabioYuji from './Assets/fabioYuji.png';
import Benito from './Assets/benito.png';
import ArrowBack from '../../../../Assets/Svg/arrowBack.svg';
import ArrowForward from '../../../../Assets/Svg/arrowForward.svg';
import CaraDaProva from './Assets/caraDaProva.png';
import Shots from './Assets/shots.png';
import Imagens from './Assets/imagens.png';
import Apostas from './Assets/apostas.png';
import XoteDosMilagres from './Assets/xote-dos-milagres.png';
import GifApostas from '../LDL/Assets/gif-ldl-apostas.gif';
import GifCaraDaProva from '../LDL/Assets/gif-ldl-caradaprova.gif';
import GifImagens from '../LDL/Assets/gif-ldl-imagens.gif';
import GifShots from '../LDL/Assets/gif-ldl-shots.gif';

// COMPONENTS
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';
import HostCard from '../../../../Components/Cards/HostCard';
import { Helmet } from 'react-helmet';
import LiveInfoCard from './components/LiveInfoCard';
import WhereWhenComponent from './components/WhereWhenComponent';
import Api from '../../../../Services/Api';

import LogoLdLHQ from './Assets/logo-hq-ldl.png';
import CellphoneImg from './Assets/cellphone-img.png';
import IconGoogle from './Assets/google-play.svg';
import IconApple from './Assets/apple-store.svg';
import IconBrowser from './Assets/browser.svg';
import GiftBag from './Assets/gift-bag-mais.png';

import CardDeCompras from '../../../../Components/New/CardDeCompras';
import BoxApp from './components/BoxApp';
import KitHardwork from './components/KitHardwork';
import DefaultButton from '../../../../Components/Buttons/DefaultButton';
import CountDown from './components/CountDown';

const Hosts = [
	{
		ig_username: '@fabricio.valois',
		img: FabricioValois,
	},
	{
		img: FabioColagrossi,
	},
	{
		img: FabioCabar,
	},
	{
		img: ClaudioLima,
	},
	{
		img: CynaraCarvalho,
	},
	{
		img: LaisKomatsu,
	},
	{
		img: DecarthonVitor,
	},
	{
		img: EricCosta,
	},
	{
		img: Sophia,
	},
	{
		img: FabioYuji,
	},
	{
		img: Benito,
	},
];

const ProductsMock = [
	{
		type: 'enare',
		isOpen: true,
		link: '/live-das-lives-enare',
		logo: require(`./Assets/ldl-enare-online.svg`).default,
	},
	{
		type: 'surce',
		isOpen: true,
		link: '/live-das-lives-surce',
		logo: require(`./Assets/ldl-surce-presencial.svg`).default,
	},
	{
		type: 'usp-rp',
		isOpen: true,
		link: '/live-das-lives-usp-rp',
		logo: require(`./Assets/ldl-usp-ribeirao-presencial.svg`).default,
	},
	{
		type: 'sus-ba',
		isOpen: true,
		link: '/live-das-lives-sus-ba',
		logo: require(`./Assets/ldl-sus-ba-presencial.svg`).default,
	},
	{
		type: 'amrigs',
		isOpen: true,
		link: '/live-das-lives-amrigs',
		logo: require(`./Assets/ldl-amrigs-online.svg`).default,
	},
	{
		type: 'psu-mg',
		isOpen: true,
		link: '/live-das-lives-psu-mg',
		logo: require(`./Assets/ldl-psu-mg-presencial.svg`).default,
	},
	{
		type: 'usp',
		isOpen: true,
		link: '/live-das-lives-usp',
		logo: require(`./Assets/ldl-usp-presencial.svg`).default,
	},
	{
		type: 'sus',
		isOpen: true,
		link: '/live-das-lives-sus',
		logo: require(`./Assets/ldl-sus-presencial.svg`).default,
	},
	{
		type: 'unifesp',
		isOpen: true,
		link: '/live-das-lives-unifesp',
		logo: require(`./Assets/ldl-unifesp-online.svg`).default,
	},
	{
		type: 'famerp',
		isOpen: true,
		link: '/live-das-lives-famerp',
		logo: require(`./Assets/ldl-famerp-presencial.svg`).default,
	},
	{
		type: 'iamspe',
		isOpen: true,
		link: '/live-das-lives-iamsp',
		logo: require(`./Assets/logo-ldl-iamspe-presencial.svg`).default,
	},
];

const ScriptCard = ({ img, gif, title, description, classes }) => {
	const [openGif, setOpenGif] = useState(true);

	const handleHover = () => {
		setOpenGif(!openGif);
	};

	return (
		<Paper
			className={`${classes.scriptPaper} rounded-card`}
			color="secondary"
			elevation={4}
			onMouseEnter={handleHover}
			onMouseLeave={handleHover}
		>
			<Grid style={{ backgroundColor: '#000000', borderRadius: 20 }}>
				{openGif ? (
					<img
						src={img}
						alt=""
						style={{ width: '100%' }}
					/>
				) : (
					<img
						src={gif}
						alt=""
						style={{ width: '100%', borderRadius: '20px 20px 0 0' }}
					/>
				)}
				<Typography
					align="left"
					color="secondary"
					className={`${classes.scriptTitle} title-card-home`}
				>
					{title}
				</Typography>
			</Grid>
			<Grid
				container
				alignContent="center"
				className={classes.gridDescriptionScriptCard}
			>
				<Typography
					align="left"
					color="primary"
					className={`${classes.scriptText} poppins`}
				>
					{description}
				</Typography>
			</Grid>
		</Paper>
	);
};

export default function LiveDasLivesResidencia2024() {
	const classes = useStyles();
	const theme = useTheme();
	const carousel = useRef(null);
	const scriptCarousel = useRef(null);
	const purchase_card = useRef(null);
	const feedbackCarousel = useRef(null);
	const [currentContest, setCurrentContest] = useState('');

	const [contests] = useState([
		// ENARE
		{
			id: '1409,1410',
			name: 'enare',
			logo: require(`./Assets/ldl-enare-online.svg`).default,
			modality: 'online',
			shortName: 'enare',
			testBench: 'FGV',
			address: '',
			date: '28/10',
			locationCity: 'Fortaleza',
			// opening_date_sprint_final: '25/09/23',
			// close_date_sprint_final: '26/09/23',
			// opening_date_general_public: '29/09/23',
			logoPriceCard: require('./Assets/logo-ldl-enare-online.svg').default,
			firstDescription: [
				'A Live das Lives ENARE acontecerá nas modalidades',
				<strong> PRESENCIAL E ONLINE</strong>,
				', este na plataforma do Hardwork Medicina. O link de acesso será disponibilizado momentos antes do início da live através de e-mail e pela dashboard do aluno.',
			],
			secondDescription:
				'Dia 18/10/2024, a partir das 15h30. Os vídeos ficarão disponíveis na plataforma até a hora da prova. ',
			thirdDescription: '',
			feedbacks: [
				require('./Assets/img-feedback-enare-01.png'),
				require('./Assets/img-feedback-enare-02.png'),
				require('./Assets/img-feedback-enare-03.png'),
				require('./Assets/img-feedback-enare-04.png'),
				require('./Assets/img-feedback-enare-05.png'),
				require('./Assets/img-feedback-enare-06.png'),
				require('./Assets/img-ldl-feedback-gerais-01.png'),
				require('./Assets/img-ldl-feedback-gerais-02.png'),
				require('./Assets/img-ldl-feedback-gerais-03.png'),
				require('./Assets/img-ldl-feedback-gerais-04.png'),
				require('./Assets/img-ldl-feedback-gerais-05.png'),
				require('./Assets/img-ldl-feedback-gerais-06.png'),
				require('./Assets/img-ldl-feedback-gerais-07.png'),
				require('./Assets/img-ldl-feedback-gerais-08.png'),
				require('./Assets/img-ldl-feedback-gerais-09.png'),
				require('./Assets/img-ldl-feedback-gerais-10.png'),
			],
		},
		// SES/ PSU-GO
		{
			id: 1425,
			name: 'SES-GO e PSU-GO',
			logo: require(`./Assets/ldl-sus-ses-psu-go-presencial.svg`).default,
			modality: 'presencial',
			shortName: 'ses/psu-go',
			testBench: 'SES/PSU-GO',
			// address: 'FIESTA BAHIA HOTEL, Salvador - BA',
			date: '09/11',
			locationCity: 'Goiânia',
			// opening_date_sprint_final: '04/10/23',
			// close_date_sprint_final: '04/10/23',
			// opening_date_general_public: '07/10/23',
			logoPriceCard: require('./Assets/ldl-sus-ses-psu-go-presencial.svg').default,
			firstDescription: [
				'A Live das Lives SES-GO/PSU-GO acontecerá em ',
				<strong style={{ color: '#ffffff' }}>Goiânia</strong>,
				// ', no ',
				// <strong style={{ color: '#ffffff' }}>FIESTA BAHIA HOTEL</strong>,
				', com a presença dos professores Hardwork no palco.',
				// 'Para ver o mapa da localização, ',
				// <a
				// 	href="https://maps.app.goo.gl/wV7JhP6LAErHG6BT7"
				// 	target="_blank"
				// 	rel="noreferrer"
				// 	style={{ color: '#ffffff' }}
				// >
				// 	clique aqui.
				// </a>,
			],
			secondDescription: [
				'Dia ',
				<strong style={{ color: '#ffffff' }}>09/11/2024</strong>,
				', a partir das ',
				<strong style={{ color: '#ffffff' }}>10h.</strong>,
				// '. Previsão de término às 16h.',
			],
			thirdDescription:
				// 'O Fiesta é um espaço amplo e moderno projetado para abrigar uma variedade de eventos, localizado em uma área estratégica de Salvador, o Fiesta possui estacionamento e opções de alimentação.',
				'Em breve.',
			feedbacks: [
				require('./Assets/img-feedback-sus-ba-01.png'),
				require('./Assets/img-feedback-sus-ba-02.png'),
				require('./Assets/img-feedback-sus-ba-03.png'),
				require('./Assets/img-feedback-sus-ba-04.png'),
				require('./Assets/img-feedback-sus-ba-05.png'),
				require('./Assets/img-feedback-sus-ba-06.png'),
				require('./Assets/img-ldl-feedback-gerais-01.png'),
				require('./Assets/img-ldl-feedback-gerais-02.png'),
				require('./Assets/img-ldl-feedback-gerais-03.png'),
				require('./Assets/img-ldl-feedback-gerais-04.png'),
				require('./Assets/img-ldl-feedback-gerais-05.png'),
				require('./Assets/img-ldl-feedback-gerais-06.png'),
				require('./Assets/img-ldl-feedback-gerais-07.png'),
				require('./Assets/img-ldl-feedback-gerais-08.png'),
				require('./Assets/img-ldl-feedback-gerais-09.png'),
				require('./Assets/img-ldl-feedback-gerais-10.png'),
			],
		},
		// SUS-BA
		{
			id: 1411,
			name: 'sus-ba',
			logo: require(`./Assets/ldl-sus-ba-presencial.svg`).default,
			modality: 'presencial',
			shortName: 'sus-ba',
			testBench: 'SUS-BA',
			address: 'Teatro Diplomata, Salvador - BA',
			date: '14/11',
			locationCity: 'Salvador ',
			// opening_date_sprint_final: '04/10/23',
			// close_date_sprint_final: '04/10/23',
			// opening_date_general_public: '07/10/23',
			logoPriceCard: require('./Assets/logo-ldl-sus-ba-presencial.svg').default,
			firstDescription: [
				'A Live das Lives SUS-BA acontecerá em ',
				<strong style={{ color: '#ffffff' }}>Salvador</strong>,
				', no ',
				<strong style={{ color: '#ffffff' }}>Teatro Diplomata</strong>,
				', com a presença dos professores Hardwork no palco.',
				// 'Para ver o mapa da localização, ',
				// <a
				// 	href="https://maps.app.goo.gl/wV7JhP6LAErHG6BT7"
				// 	target="_blank"
				// 	rel="noreferrer"
				// 	style={{ color: '#ffffff' }}
				// >
				// 	clique aqui.
				// </a>,
			],
			secondDescription: [
				'Dia ',
				<strong style={{ color: '#ffffff' }}>14/11/2024</strong>,
				', a partir das ',
				<strong style={{ color: '#ffffff' }}>10h.</strong>,
				// '. Previsão de término às 16h.',
			],
			thirdDescription:
				// 'O Fiesta é um espaço amplo e moderno projetado para abrigar uma variedade de eventos, localizado em uma área estratégica de Salvador, o Fiesta possui estacionamento e opções de alimentação.',
				'Em breve.',
			feedbacks: [
				require('./Assets/img-feedback-sus-ba-01.png'),
				require('./Assets/img-feedback-sus-ba-02.png'),
				require('./Assets/img-feedback-sus-ba-03.png'),
				require('./Assets/img-feedback-sus-ba-04.png'),
				require('./Assets/img-feedback-sus-ba-05.png'),
				require('./Assets/img-feedback-sus-ba-06.png'),
				require('./Assets/img-ldl-feedback-gerais-01.png'),
				require('./Assets/img-ldl-feedback-gerais-02.png'),
				require('./Assets/img-ldl-feedback-gerais-03.png'),
				require('./Assets/img-ldl-feedback-gerais-04.png'),
				require('./Assets/img-ldl-feedback-gerais-05.png'),
				require('./Assets/img-ldl-feedback-gerais-06.png'),
				require('./Assets/img-ldl-feedback-gerais-07.png'),
				require('./Assets/img-ldl-feedback-gerais-08.png'),
				require('./Assets/img-ldl-feedback-gerais-09.png'),
				require('./Assets/img-ldl-feedback-gerais-10.png'),
			],
		},
		// SUS-BA Vitória da Conquista
		{
			id: 1430,
			name: 'sus-ba',
			logo: require(`./Assets/ldl-sus-ba-vitoria-conquista-presencial.svg`).default,
			modality: 'presencial',
			shortName: 'sus-ba',
			testBench: 'SUS-BA',
			// address: 'Teatro Diplomata, Salvador - BA',
			date: '14/11',
			locationCity: 'Vitória da Conquista',
			// opening_date_sprint_final: '04/10/23',
			// close_date_sprint_final: '04/10/23',
			// opening_date_general_public: '07/10/23',
			logoPriceCard: require('./Assets/logo-ldl-sus-ba-presencial.svg').default,
			firstDescription: [
				'A Live das Lives SUS-BA acontecerá em ',
				<strong style={{ color: '#ffffff' }}>Vitória da Conquista.</strong>,
				// ', no ',
				// <strong style={{ color: '#ffffff' }}>Teatro Diplomata</strong>,
				// ', com a presença dos professores Hardwork no palco.',
				// 'Para ver o mapa da localização, ',
				// <a
				// 	href="https://maps.app.goo.gl/wV7JhP6LAErHG6BT7"
				// 	target="_blank"
				// 	rel="noreferrer"
				// 	style={{ color: '#ffffff' }}
				// >
				// 	clique aqui.
				// </a>,
			],
			secondDescription: [
				'Dia ',
				<strong style={{ color: '#ffffff' }}>14/11/2024</strong>,
				', a partir das ',
				<strong style={{ color: '#ffffff' }}>10h.</strong>,
				// '. Previsão de término às 16h.',
			],
			thirdDescription:
				// 'O Fiesta é um espaço amplo e moderno projetado para abrigar uma variedade de eventos, localizado em uma área estratégica de Salvador, o Fiesta possui estacionamento e opções de alimentação.',
				'Em breve.',
			feedbacks: [
				require('./Assets/img-feedback-sus-ba-01.png'),
				require('./Assets/img-feedback-sus-ba-02.png'),
				require('./Assets/img-feedback-sus-ba-03.png'),
				require('./Assets/img-feedback-sus-ba-04.png'),
				require('./Assets/img-feedback-sus-ba-05.png'),
				require('./Assets/img-feedback-sus-ba-06.png'),
				require('./Assets/img-ldl-feedback-gerais-01.png'),
				require('./Assets/img-ldl-feedback-gerais-02.png'),
				require('./Assets/img-ldl-feedback-gerais-03.png'),
				require('./Assets/img-ldl-feedback-gerais-04.png'),
				require('./Assets/img-ldl-feedback-gerais-05.png'),
				require('./Assets/img-ldl-feedback-gerais-06.png'),
				require('./Assets/img-ldl-feedback-gerais-07.png'),
				require('./Assets/img-ldl-feedback-gerais-08.png'),
				require('./Assets/img-ldl-feedback-gerais-09.png'),
				require('./Assets/img-ldl-feedback-gerais-10.png'),
			],
		},
		// SUS-BA Juazeiro e Petrolina
		{
			id: 1431,
			name: 'sus-ba',
			logo: require(`./Assets/ldl-sus-ba-juazeiro-petrolina-presencial.svg`).default,
			modality: 'presencial',
			shortName: 'sus-ba',
			testBench: 'SUS-BA',
			// address: 'Teatro Diplomata, Salvador - BA',
			date: '14/11',
			locationCity: 'Juazeiro',
			// opening_date_sprint_final: '04/10/23',
			// close_date_sprint_final: '04/10/23',
			// opening_date_general_public: '07/10/23',
			logoPriceCard: require('./Assets/logo-ldl-sus-ba-presencial.svg').default,
			firstDescription: [
				'A Live das Lives SUS-BA acontecerá em ',
				<strong style={{ color: '#ffffff' }}>Juazeiro.</strong>,
				// ', no ',
				// <strong style={{ color: '#ffffff' }}>Teatro Diplomata</strong>,
				// ', com a presença dos professores Hardwork no palco.',
				// 'Para ver o mapa da localização, ',
				// <a
				// 	href="https://maps.app.goo.gl/wV7JhP6LAErHG6BT7"
				// 	target="_blank"
				// 	rel="noreferrer"
				// 	style={{ color: '#ffffff' }}
				// >
				// 	clique aqui.
				// </a>,
			],
			secondDescription: [
				'Dia ',
				<strong style={{ color: '#ffffff' }}>14/11/2024</strong>,
				', a partir das ',
				<strong style={{ color: '#ffffff' }}>10h.</strong>,
				// '. Previsão de término às 16h.',
			],
			thirdDescription:
				// 'O Fiesta é um espaço amplo e moderno projetado para abrigar uma variedade de eventos, localizado em uma área estratégica de Salvador, o Fiesta possui estacionamento e opções de alimentação.',
				'Em breve.',
			feedbacks: [
				require('./Assets/img-feedback-sus-ba-01.png'),
				require('./Assets/img-feedback-sus-ba-02.png'),
				require('./Assets/img-feedback-sus-ba-03.png'),
				require('./Assets/img-feedback-sus-ba-04.png'),
				require('./Assets/img-feedback-sus-ba-05.png'),
				require('./Assets/img-feedback-sus-ba-06.png'),
				require('./Assets/img-ldl-feedback-gerais-01.png'),
				require('./Assets/img-ldl-feedback-gerais-02.png'),
				require('./Assets/img-ldl-feedback-gerais-03.png'),
				require('./Assets/img-ldl-feedback-gerais-04.png'),
				require('./Assets/img-ldl-feedback-gerais-05.png'),
				require('./Assets/img-ldl-feedback-gerais-06.png'),
				require('./Assets/img-ldl-feedback-gerais-07.png'),
				require('./Assets/img-ldl-feedback-gerais-08.png'),
				require('./Assets/img-ldl-feedback-gerais-09.png'),
				require('./Assets/img-ldl-feedback-gerais-10.png'),
			],
		},
		// AMRIGS
		{
			id: 1417,
			name: 'amrigs',
			logo: require(`./Assets/ldl-amrigs-online.svg`).default,
			modality: 'online',
			shortName: 'amrigs',
			testBench: 'AMRIGS',
			address: '',
			date: '10/11',
			locationCity: ' ',
			// opening_date_sprint_final: '16/10/23',
			// close_date_sprint_final: '17/10/23',
			// opening_date_general_public: '20/10/23',
			logoPriceCard: require('./Assets/logo-ldl-amrigs-online.svg').default,
			firstDescription: [
				'A Live das Lives AMRIGS acontecerá',
				<strong> EXCLUSIVAMENTE ONLINE</strong>,
				', na plataforma do Hardwork Medicina. O link de acesso será disponibilizado momentos antes do início da live através de e-mail e pela dashboard do aluno.',
			],
			secondDescription: [
				'Dia ',
				<strong style={{ color: '#ffffff' }}>10/11/2024</strong>,
				', a partir das ',
				<strong style={{ color: '#ffffff' }}>10h.</strong>,
			],
			//  ', às 10h. Os vídeos ficarão disponíveis na plataforma até a hora da prova. ',
			thirdDescription: '',
			feedbacks: [
				require('./Assets/img-feedback-amrigs-01.png'),
				require('./Assets/img-feedback-amrigs-02.png'),
				require('./Assets/img-feedback-amrigs-03.png'),
				require('./Assets/img-feedback-amrigs-04.png'),
				require('./Assets/img-feedback-amrigs-05.png'),
				require('./Assets/img-ldl-feedback-gerais-01.png'),
				require('./Assets/img-ldl-feedback-gerais-02.png'),
				require('./Assets/img-ldl-feedback-gerais-03.png'),
				require('./Assets/img-ldl-feedback-gerais-04.png'),
				require('./Assets/img-ldl-feedback-gerais-05.png'),
				require('./Assets/img-ldl-feedback-gerais-06.png'),
				require('./Assets/img-ldl-feedback-gerais-07.png'),
				require('./Assets/img-ldl-feedback-gerais-08.png'),
				require('./Assets/img-ldl-feedback-gerais-09.png'),
				require('./Assets/img-ldl-feedback-gerais-10.png'),
			],
		},
		// UNICAMP
		{
			id: 1419,
			name: 'unicamp',
			logo: require(`./Assets/ldl-unicamp-presencial.svg`).default,
			modality: 'presencial',
			shortName: 'unicamp',
			testBench: 'UNICAMP',
			address: '',
			date: '16/11',
			locationCity: 'Campinas',
			// opening_date_sprint_final: '16/10/23',
			// close_date_sprint_final: '17/10/23',
			// opening_date_general_public: '20/10/23',
			logoPriceCard: require('./Assets/logo-ldl-amrigs-online.svg').default,
			firstDescription: [
				'A Live das Lives UNICAMP acontecerá em ',
				<strong style={{ color: '#ffffff' }}>Campinas</strong>,
				// ', no ',
				// <strong style={{ color: '#ffffff' }}>FIESTA BAHIA HOTEL</strong>,
				', com a presença dos professores Hardwork no palco.',
				// 'Para ver o mapa da localização, ',
				// <a
				// 	href="https://maps.app.goo.gl/wV7JhP6LAErHG6BT7"
				// 	target="_blank"
				// 	rel="noreferrer"
				// 	style={{ color: '#ffffff' }}
				// >
				// 	clique aqui.
				// </a>,
			],
			secondDescription: [
				'Dia ',
				<strong style={{ color: '#ffffff' }}>16/11/2024</strong>,
				', a partir das ',
				<strong style={{ color: '#ffffff' }}>10h.</strong>,
				// '. Previsão de término às 16h.',
			],
			thirdDescription:
				// 'O Fiesta é um espaço amplo e moderno projetado para abrigar uma variedade de eventos, localizado em uma área estratégica de Salvador, o Fiesta possui estacionamento e opções de alimentação.',
				'Em breve.',
			feedbacks: [
				require('./Assets/img-feedback-amrigs-01.png'),
				require('./Assets/img-feedback-amrigs-02.png'),
				require('./Assets/img-feedback-amrigs-03.png'),
				require('./Assets/img-feedback-amrigs-04.png'),
				require('./Assets/img-feedback-amrigs-05.png'),
				require('./Assets/img-ldl-feedback-gerais-01.png'),
				require('./Assets/img-ldl-feedback-gerais-02.png'),
				require('./Assets/img-ldl-feedback-gerais-03.png'),
				require('./Assets/img-ldl-feedback-gerais-04.png'),
				require('./Assets/img-ldl-feedback-gerais-05.png'),
				require('./Assets/img-ldl-feedback-gerais-06.png'),
				require('./Assets/img-ldl-feedback-gerais-07.png'),
				require('./Assets/img-ldl-feedback-gerais-08.png'),
				require('./Assets/img-ldl-feedback-gerais-09.png'),
				require('./Assets/img-ldl-feedback-gerais-10.png'),
			],
		},
		// USP-RP
		{
			id: 1412,
			name: 'usp-rp',
			logo: require(`./Assets/ldl-usp-ribeirao-presencial.svg`).default,
			modality: 'presencial',
			shortName: 'usp-rp',
			testBench: 'USP-RIBEIRÃO',
			address: 'TEATRO MINAZ, Ribeirão Preto - SP',
			date: '19/11',
			locationCity: 'Ribeirão Preto',
			// opening_date_sprint_final: '04/10/23',
			// close_date_sprint_final: '04/10/23',
			// opening_date_general_public: '07/10/23',
			logoPriceCard: require('./Assets/logo-ldl-usp-ribeirao-presencial.svg').default,
			firstDescription: [
				'A Live das Lives USP-RP acontecerá em ',
				<strong style={{ color: '#ffffff' }}>Ribeirão Preto</strong>,
				// ', no ',
				// <strong style={{ color: '#ffffff' }}>FIESTA BAHIA HOTEL</strong>,
				', com a presença dos professores Hardwork no palco.',
				// <a
				// 	href="https://maps.app.goo.gl/GGkzuFzsX36Mjbvf8"
				// 	target="_blank"
				// 	rel="noreferrer"
				// 	style={{ color: '#ffffff' }}
				// >
				// 	clique aqui.
				// </a>,
			],
			secondDescription: [
				'Dia ',
				<strong style={{ color: '#ffffff' }}>19/11/2024</strong>,
				', a partir das ',
				<strong style={{ color: '#ffffff' }}>10h.</strong>,
				// '. Previsão de término às 16h.',
			],
			thirdDescription:
				// 'O Teatro Minaz é um renomado teatro localizado em Ribeirão Preto, localizado a 150 metros da Av. Dr Francisco Junqueira, uma das principais avenidas da cidade. Estacionamento localizado a 200m do Teatro.',
				'Em breve.',
			feedbacks: [
				require('./Assets/img-feedback-usp-rp-01.png'),
				require('./Assets/img-feedback-usp-rp-02.png'),
				require('./Assets/img-feedback-usp-rp-03.png'),
				require('./Assets/img-feedback-usp-rp-04.png'),
				require('./Assets/img-feedback-usp-rp-05.png'),
				require('./Assets/img-feedback-usp-rp-06.png'),
				require('./Assets/img-feedback-usp-rp-07.png'),
				require('./Assets/img-feedback-usp-rp-08.png'),
				require('./Assets/img-feedback-usp-rp-09.png'),
				require('./Assets/img-feedback-usp-rp-10.png'),
				require('./Assets/img-feedback-usp-rp-11.png'),
				require('./Assets/img-feedback-usp-rp-12.png'),
				require('./Assets/img-feedback-usp-rp-13.png'),
				require('./Assets/img-feedback-usp-rp-14.png'),
				require('./Assets/img-feedback-usp-rp-15.png'),
				require('./Assets/img-feedback-usp-rp-16.png'),
				require('./Assets/img-feedback-usp-rp-17.png'),
				require('./Assets/img-ldl-feedback-gerais-01.png'),
				require('./Assets/img-ldl-feedback-gerais-02.png'),
				require('./Assets/img-ldl-feedback-gerais-03.png'),
				require('./Assets/img-ldl-feedback-gerais-04.png'),
				require('./Assets/img-ldl-feedback-gerais-05.png'),
				require('./Assets/img-ldl-feedback-gerais-06.png'),
				require('./Assets/img-ldl-feedback-gerais-07.png'),
				require('./Assets/img-ldl-feedback-gerais-08.png'),
				require('./Assets/img-ldl-feedback-gerais-09.png'),
				require('./Assets/img-ldl-feedback-gerais-10.png'),
			],
		},
		// UFRJ
		{
			id: 1420,
			name: 'ufrj',
			logo: require(`./Assets/ldl-usp-ribeirao-presencial.svg`).default,
			modality: 'presencial',
			shortName: 'ufrj',
			testBench: 'UFRJ',
			// address: 'TEATRO MINAZ, Ribeirão Preto - SP',
			date: '22/11',
			locationCity: 'Rio de Janeiro',
			// opening_date_sprint_final: '04/10/23',
			// close_date_sprint_final: '04/10/23',
			// opening_date_general_public: '07/10/23',
			logoPriceCard: require('./Assets/logo-ldl-usp-ribeirao-presencial.svg').default,
			firstDescription: [
				'A Live das Lives UFRJ acontecerá no ',
				<strong style={{ color: '#ffffff' }}>Rio de Janeiro</strong>,
				', na ',
				<strong style={{ color: '#ffffff' }}>Universidade Estácio de Sá - Campus Città</strong>,
				', com a presença dos professores Hardwork no palco.',
				// <a
				// 	href="https://maps.app.goo.gl/GGkzuFzsX36Mjbvf8"
				// 	target="_blank"
				// 	rel="noreferrer"
				// 	style={{ color: '#ffffff' }}
				// >
				// 	clique aqui.
				// </a>,
			],
			secondDescription: [
				'Dia ',
				<strong style={{ color: '#ffffff' }}>22/11/2024</strong>,
				', a partir das ',
				<strong style={{ color: '#ffffff' }}>10h.</strong>,
				// '. Previsão de término às 16h.',
			],
			thirdDescription:
				// 'O Teatro Minaz é um renomado teatro localizado em Ribeirão Preto, localizado a 150 metros da Av. Dr Francisco Junqueira, uma das principais avenidas da cidade. Estacionamento localizado a 200m do Teatro.',
				'Em breve.',
			feedbacks: [
				require('./Assets/img-feedback-usp-rp-01.png'),
				require('./Assets/img-feedback-usp-rp-02.png'),
				require('./Assets/img-feedback-usp-rp-03.png'),
				require('./Assets/img-feedback-usp-rp-04.png'),
				require('./Assets/img-feedback-usp-rp-05.png'),
				require('./Assets/img-feedback-usp-rp-06.png'),
				require('./Assets/img-feedback-usp-rp-07.png'),
				require('./Assets/img-feedback-usp-rp-08.png'),
				require('./Assets/img-feedback-usp-rp-09.png'),
				require('./Assets/img-feedback-usp-rp-10.png'),
				require('./Assets/img-feedback-usp-rp-11.png'),
				require('./Assets/img-feedback-usp-rp-12.png'),
				require('./Assets/img-feedback-usp-rp-13.png'),
				require('./Assets/img-feedback-usp-rp-14.png'),
				require('./Assets/img-feedback-usp-rp-15.png'),
				require('./Assets/img-feedback-usp-rp-16.png'),
				require('./Assets/img-feedback-usp-rp-17.png'),
				require('./Assets/img-ldl-feedback-gerais-01.png'),
				require('./Assets/img-ldl-feedback-gerais-02.png'),
				require('./Assets/img-ldl-feedback-gerais-03.png'),
				require('./Assets/img-ldl-feedback-gerais-04.png'),
				require('./Assets/img-ldl-feedback-gerais-05.png'),
				require('./Assets/img-ldl-feedback-gerais-06.png'),
				require('./Assets/img-ldl-feedback-gerais-07.png'),
				require('./Assets/img-ldl-feedback-gerais-08.png'),
				require('./Assets/img-ldl-feedback-gerais-09.png'),
				require('./Assets/img-ldl-feedback-gerais-10.png'),
			],
		},
		// FAMERP
		{
			id: 1421,
			name: 'famerp',
			logo: require(`./Assets/ldl-famerp-presencial.svg`).default,
			modality: 'presencial',
			shortName: 'famerp',
			testBench: 'FAMERP',
			address: 'Villa Conte, São José do Rio Preto - SP',
			date: '25/11',
			locationCity: 'São José do Rio Preto',
			opening_date_sprint_final: '',
			close_date_sprint_final: '',
			opening_date_general_public: '',
			logoPriceCard: require('./Assets/logo-ldl-famerp-presencial.svg').default,
			firstDescription: [
				'A Live das Lives FAMERP acontecerá em ',
				<strong style={{ color: '#ffffff' }}>São José do Rio Preto</strong>,
				// ', no ',
				// <strong style={{ color: '#ffffff' }}>FIESTA BAHIA HOTEL</strong>,
				', com a presença dos professores Hardwork no palco.',
				// <a
				// 	href="https://maps.app.goo.gl/Cd6dqWWk6GosuHW29"
				// 	target="_blank"
				// 	rel="noreferrer"
				// 	style={{ color: '#ffffff' }}
				// >
				// 	clique aqui.
				// </a>,
			],
			secondDescription: [
				'Dia ',
				<strong style={{ color: '#ffffff' }}>25/11/2024</strong>,
				', a partir das ',
				<strong style={{ color: '#ffffff' }}>10h.</strong>,
				// '. Previsão de término às 21h30.',
			],
			thirdDescription: '',
			feedbacks: [
				require('./Assets/img-ldl-feedback-gerais-01.png'),
				require('./Assets/img-ldl-feedback-gerais-02.png'),
				require('./Assets/img-ldl-feedback-gerais-03.png'),
				require('./Assets/img-ldl-feedback-gerais-04.png'),
				require('./Assets/img-ldl-feedback-gerais-05.png'),
				require('./Assets/img-ldl-feedback-gerais-06.png'),
				require('./Assets/img-ldl-feedback-gerais-07.png'),
				require('./Assets/img-ldl-feedback-gerais-08.png'),
				require('./Assets/img-ldl-feedback-gerais-09.png'),
				require('./Assets/img-ldl-feedback-gerais-10.png'),
			],
		},
		// PSU-MG ONLINE
		{
			id: 1446,
			name: 'psu-mg-online',
			logo: require(`./Assets/ldl-sus-ba-presencial.svg`).default,
			modality: 'online',
			shortName: 'psu-mg',
			testBench: 'PSU-MG',
			address: 'Hotel Ouro Minas, Belo Horizonte - MG',
			date: '28/11',
			locationCity: 'Belo Horizonte',
			// opening_date_sprint_final: '16/10/23',
			// close_date_sprint_final: '17/10/23',
			// opening_date_general_public: '20/10/23',
			logoPriceCard: require('./Assets/logo-ldl-psu-mg-presencial.svg').default,
			firstDescription: [
				'A Live das Lives PSU-MG acontecerá',
				<strong> EXCLUSIVAMENTE ONLINE</strong>,
				', na plataforma do Hardwork Medicina. O link de acesso será disponibilizado momentos antes do início da live através de e-mail e pela dashboard do aluno.',
			],
			secondDescription: [
				'Dia ',
				<strong style={{ color: '#ffffff' }}>28/11/2024</strong>,
				// ', a partir das ',
				// <strong style={{ color: '#ffffff' }}>10h.</strong>,
				// '. Previsão de término às 21h.',
			],

			feedbacks: [
				require('./Assets/img-feedback-psu-mg-01.png'),
				require('./Assets/img-feedback-psu-mg-02.png'),
				require('./Assets/img-feedback-psu-mg-03.png'),
				require('./Assets/img-feedback-psu-mg-04.png'),
				require('./Assets/img-feedback-psu-mg-05.png'),
				require('./Assets/img-ldl-feedback-gerais-01.png'),
				require('./Assets/img-ldl-feedback-gerais-02.png'),
				require('./Assets/img-ldl-feedback-gerais-03.png'),
				require('./Assets/img-ldl-feedback-gerais-04.png'),
				require('./Assets/img-ldl-feedback-gerais-05.png'),
				require('./Assets/img-ldl-feedback-gerais-06.png'),
				require('./Assets/img-ldl-feedback-gerais-07.png'),
				require('./Assets/img-ldl-feedback-gerais-08.png'),
				require('./Assets/img-ldl-feedback-gerais-09.png'),
				require('./Assets/img-ldl-feedback-gerais-10.png'),
			],
		},
		// PSU-MG
		{
			id: 1413,
			name: 'psu-mg',
			logo: require(`./Assets/ldl-psu-mg-presencial.svg`).default,
			modality: 'presencial',
			shortName: 'psu-mg',
			testBench: 'PSU-MG',
			address: 'Hotel Ouro Minas, Belo Horizonte - MG',
			date: '28/11',
			locationCity: 'Belo Horizonte',
			// opening_date_sprint_final: '16/10/23',
			// close_date_sprint_final: '17/10/23',
			// opening_date_general_public: '20/10/23',
			logoPriceCard: require('./Assets/logo-ldl-psu-mg-presencial.svg').default,
			firstDescription: [
				'A Live das Lives PSU-MG acontecerá em ',
				<strong style={{ color: '#ffffff' }}>Belo Horizonte.</strong>,
				', no ',
				<strong style={{ color: '#ffffff' }}>Hotel Ouro Minas</strong>,
				', com a presença dos professores Hardwork no palco.',
				// <a
				// 	href="https://maps.app.goo.gl/SZjhLPYfRHKroH4j7"
				// 	target="_blank"
				// 	rel="noreferrer"
				// 	style={{ color: '#ffffff' }}
				// >
				// 	clique aqui.
				// </a>,
			],
			secondDescription: [
				'Dia ',
				<strong style={{ color: '#ffffff' }}>28/11/2024</strong>,
				', a partir das ',
				<strong style={{ color: '#ffffff' }}>10h.</strong>,
				// '. Previsão de término às 21h.',
			],
			thirdDescription:
				// 'O local está situado no prédio da Câmara de Dirigentes Lojistas de Belo Horizonte (CDL/BH), na região centro-sul da capital mineira. Conforto, modernidade, localização privilegiada e requinte em um auditório aconchegante. Localizado a 200 metros da Praça da Liberdade. Estacionamento e diversas opções de alimentação nas imediações do local.',
				'Em breve.',
			feedbacks: [
				require('./Assets/img-feedback-psu-mg-01.png'),
				require('./Assets/img-feedback-psu-mg-02.png'),
				require('./Assets/img-feedback-psu-mg-03.png'),
				require('./Assets/img-feedback-psu-mg-04.png'),
				require('./Assets/img-feedback-psu-mg-05.png'),
				require('./Assets/img-ldl-feedback-gerais-01.png'),
				require('./Assets/img-ldl-feedback-gerais-02.png'),
				require('./Assets/img-ldl-feedback-gerais-03.png'),
				require('./Assets/img-ldl-feedback-gerais-04.png'),
				require('./Assets/img-ldl-feedback-gerais-05.png'),
				require('./Assets/img-ldl-feedback-gerais-06.png'),
				require('./Assets/img-ldl-feedback-gerais-07.png'),
				require('./Assets/img-ldl-feedback-gerais-08.png'),
				require('./Assets/img-ldl-feedback-gerais-09.png'),
				require('./Assets/img-ldl-feedback-gerais-10.png'),
			],
		},

		// USP
		{
			id: 1414,
			name: 'usp',
			logo: require(`./Assets/ldl-usp-presencial.svg`).default,
			modality: 'presencial',
			shortName: 'usp',
			testBench: 'USP',
			address: 'WTC Events Center, São Paulo - SP',
			date: '29/11',
			locationCity: 'São Paulo',
			// opening_date_sprint_final: '16/10/23',
			// close_date_sprint_final: '17/10/23',
			// opening_date_general_public: '20/10/23',
			logoPriceCard: require('./Assets/logo-ldl-usp-presencial.svg').default,
			firstDescription: [
				'A Live das Lives USP acontecerá em ',
				<strong style={{ color: '#ffffff' }}>São Paulo, no Clube Hebraica SP</strong>,
				// ', no ',
				// <strong style={{ color: '#ffffff' }}>FIESTA BAHIA HOTEL</strong>,
				', com a presença dos professores Hardwork no palco.',
				// <a
				// 	href="https://maps.app.goo.gl/ySc83oRr5WjFjtuV7"
				// 	target="_blank"
				// 	rel="noreferrer"
				// 	style={{ color: '#ffffff' }}
				// >
				// 	clique aqui.
				// </a>,
			],
			secondDescription: [
				'Dia ',
				<strong style={{ color: '#ffffff' }}>29/11/2024</strong>,
				', a partir das ',
				<strong style={{ color: '#ffffff' }}>10h.</strong>,
				// '. Previsão de término às 21h.',
			],
			thirdDescription:
				// 'O WTC Events Center é o mais completo centro de eventos da América do Sul. Fácil acesso, estacionamento no local e diversas opções de alimentação.',
				'Em breve.',
			feedbacks: [
				require('./Assets/img-feedback-usp-01.png'),
				require('./Assets/img-feedback-usp-02.png'),
				require('./Assets/img-feedback-usp-03.png'),
				require('./Assets/img-feedback-usp-04.png'),
				require('./Assets/img-feedback-usp-05.png'),
				require('./Assets/img-feedback-usp-06.png'),
				require('./Assets/img-feedback-usp-07.png'),
				require('./Assets/img-feedback-usp-08.png'),
				require('./Assets/img-feedback-usp-09.png'),
				require('./Assets/img-ldl-feedback-gerais-01.png'),
				require('./Assets/img-ldl-feedback-gerais-02.png'),
				require('./Assets/img-ldl-feedback-gerais-03.png'),
				require('./Assets/img-ldl-feedback-gerais-04.png'),
				require('./Assets/img-ldl-feedback-gerais-05.png'),
				require('./Assets/img-ldl-feedback-gerais-06.png'),
				require('./Assets/img-ldl-feedback-gerais-07.png'),
				require('./Assets/img-ldl-feedback-gerais-08.png'),
				require('./Assets/img-ldl-feedback-gerais-09.png'),
				require('./Assets/img-ldl-feedback-gerais-10.png'),
			],
		},
		// USP - ONLINE
		{
			id: 1448,
			name: 'usp-online',
			logo: require(`./Assets/ldl-usp-presencial.svg`).default,
			modality: 'online',
			shortName: 'usp',
			testBench: 'USP',
			address: 'WTC Events Center, São Paulo - SP',
			date: '29/11',
			locationCity: 'São Paulo',
			// opening_date_sprint_final: '16/10/23',
			// close_date_sprint_final: '17/10/23',
			// opening_date_general_public: '20/10/23',
			logoPriceCard: require('./Assets/logo-ldl-usp-presencial.svg').default,
			firstDescription: [
				'A Live das Lives USP acontecerá',
				<strong> EXCLUSIVAMENTE ONLINE</strong>,
				', na plataforma do Hardwork Medicina. O link de acesso será disponibilizado momentos antes do início da live através de e-mail e pela dashboard do aluno.',
			],
			secondDescription: [
				'Dia ',
				<strong style={{ color: '#ffffff' }}>29/11/2024</strong>,
				// ', a partir das ',
				// <strong style={{ color: '#ffffff' }}>10h.</strong>,
				// '. Previsão de término às 21h.',
			],
			feedbacks: [
				require('./Assets/img-feedback-usp-01.png'),
				require('./Assets/img-feedback-usp-02.png'),
				require('./Assets/img-feedback-usp-03.png'),
				require('./Assets/img-feedback-usp-04.png'),
				require('./Assets/img-feedback-usp-05.png'),
				require('./Assets/img-feedback-usp-06.png'),
				require('./Assets/img-feedback-usp-07.png'),
				require('./Assets/img-feedback-usp-08.png'),
				require('./Assets/img-feedback-usp-09.png'),
				require('./Assets/img-ldl-feedback-gerais-01.png'),
				require('./Assets/img-ldl-feedback-gerais-02.png'),
				require('./Assets/img-ldl-feedback-gerais-03.png'),
				require('./Assets/img-ldl-feedback-gerais-04.png'),
				require('./Assets/img-ldl-feedback-gerais-05.png'),
				require('./Assets/img-ldl-feedback-gerais-06.png'),
				require('./Assets/img-ldl-feedback-gerais-07.png'),
				require('./Assets/img-ldl-feedback-gerais-08.png'),
				require('./Assets/img-ldl-feedback-gerais-09.png'),
				require('./Assets/img-ldl-feedback-gerais-10.png'),
			],
		},
		// UNIFESP
		{
			id: 1415,
			name: 'unifesp',
			logo: require(`./Assets/ldl-unifesp-online.svg`).default,
			modality: 'online',
			shortName: 'unifesp',
			testBench: 'UNIFESP',
			address: '',
			date: '07/12',
			locationCity: '',
			opening_date_sprint_final: '',
			close_date_sprint_final: '',
			opening_date_general_public: '',
			logoPriceCard: require('./Assets/logo-ldl-unifesp-online.svg').default,
			firstDescription: [
				'A Live das Lives UNIFESP acontecerá',
				<strong> EXCLUSIVAMENTE ONLINE</strong>,
				', na plataforma do Hardwork Medicina. O link de acesso será disponibilizado momentos antes do início da live através de e-mail e pela dashboard do aluno.',
			],
			secondDescription: [
				'Dia ',
				<strong style={{ color: '#ffffff' }}>07/12/2024</strong>,
				', a partir das ',
				<strong style={{ color: '#ffffff' }}>10h.</strong>,
			],
			// ', às 15h. Os vídeos ficarão disponíveis na plataforma até a hora da prova. ',
			thirdDescription: '',
			feedbacks: [
				require('./Assets/img-ldl-feedback-gerais-01.png'),
				require('./Assets/img-ldl-feedback-gerais-02.png'),
				require('./Assets/img-ldl-feedback-gerais-03.png'),
				require('./Assets/img-ldl-feedback-gerais-04.png'),
				require('./Assets/img-ldl-feedback-gerais-05.png'),
				require('./Assets/img-ldl-feedback-gerais-06.png'),
				require('./Assets/img-ldl-feedback-gerais-07.png'),
				require('./Assets/img-ldl-feedback-gerais-08.png'),
				require('./Assets/img-ldl-feedback-gerais-09.png'),
				require('./Assets/img-ldl-feedback-gerais-10.png'),
			],
		},
		// SUS
		{
			id: 1422,
			name: 'sus-sp',
			logo: require(`./Assets/ldl-sus-presencial.svg`).default,
			modality: 'presencial',
			shortName: 'sus-sp',
			testBench: 'SUS-SP',
			address: 'WTC Events Center, São Paulo - SP',
			date: '13/12',
			locationCity: 'São Paulo',
			// opening_date_sprint_final: '16/10/23',
			// close_date_sprint_final: '17/10/23',
			// opening_date_general_public: '20/10/23',
			logoPriceCard: require('./Assets/logo-ldl-sus-presencial.svg').default,
			firstDescription: [
				'A Live das Lives SUS-SP acontecerá em ',
				<strong style={{ color: '#ffffff' }}>São Paulo</strong>,
				', no ',
				<strong style={{ color: '#ffffff' }}>Espaço Immensita</strong>,
				', com a presença dos professores Hardwork no palco.',
				// 'Para ver o mapa da localização, ',
				// <a
				// 	href="https://maps.app.goo.gl/wV7JhP6LAErHG6BT7"
				// 	target="_blank"
				// 	rel="noreferrer"
				// 	style={{ color: '#ffffff' }}
				// >
				// 	clique aqui.
				// </a>,
			],
			secondDescription: [
				'Dia ',
				<strong style={{ color: '#ffffff' }}>13/12/2024</strong>,
				', a partir das ',
				<strong style={{ color: '#ffffff' }}>10h.</strong>,
				// '. Previsão de término às 21h.',
			],
			thirdDescription:
				// 'O WTC Events Center é o mais completo centro de eventos da América do Sul. Fácil acesso, estacionamento no local e diversas opções de alimentação.',
				'Em breve.',
			feedbacks: [
				// require('./Assets/img-feedback-psu-mg-01.png'),
				// require('./Assets/img-feedback-psu-mg-02.png'),
				// require('./Assets/img-feedback-psu-mg-03.png'),
				// require('./Assets/img-feedback-psu-mg-04.png'),
				// require('./Assets/img-feedback-psu-mg-05.png'),
				require('./Assets/img-ldl-feedback-gerais-01.png'),
				require('./Assets/img-ldl-feedback-gerais-02.png'),
				require('./Assets/img-ldl-feedback-gerais-03.png'),
				require('./Assets/img-ldl-feedback-gerais-04.png'),
				require('./Assets/img-ldl-feedback-gerais-05.png'),
				require('./Assets/img-ldl-feedback-gerais-06.png'),
				require('./Assets/img-ldl-feedback-gerais-07.png'),
				require('./Assets/img-ldl-feedback-gerais-08.png'),
				require('./Assets/img-ldl-feedback-gerais-09.png'),
				require('./Assets/img-ldl-feedback-gerais-10.png'),
			],
		},
	]);
	const [currentLotTurma1, setCurrentLotTurma1] = useState();
	const [currentLotTurma2, setCurrentLotTurma2] = useState();
	const [turma1, setTurma1] = useState([]);
	const [turma2, setTurma2] = useState([]);
	const [open, setOpen] = useState(false);
	const [numParticipants, setNumParticipants] = useState();
	const [selectedVideo, setSelectedVideo] = useState('');
	const [scriptMock, setScriptMock] = useState([]);
	const [sold, setSold] = useState();
	const [isActive, setIsActive] = useState(false);
	const [isLotOpened, setIsLotOpened] = useState(false);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const path = window.location.pathname;
	const currentDate = new Date();

	const isVipList =
		window.location.pathname === '/live-das-lives-sus-ba-vitoria-da-conquista' ||
		window.location.pathname === '/live-das-lives-sus-ba-juazeiro-petrolina'
			? true
			: false;

	useEffect(() => {
		let lastPart;

		if (
			path.includes('enare') ||
			path.includes('surce') ||
			path.includes('amrigs') ||
			path.includes('unifesp') ||
			path.includes('famerp') ||
			path.includes('iamspe') ||
			path.includes('unicamp') ||
			path.includes('ufrj')
		) {
			// Se o caminho contiver 'enare', 'surce' ou 'amrigs', você pode dividir apenas no último '-'
			const parts = path.split('-');
			lastPart = parts[parts.length - 1];
		} else if (path.includes('ses-psu-go')) {
			lastPart = 'SES-GO e PSU-GO';
		} else {
			const parts = path.split('-');
			if (path === '/live-das-lives-usp') {
				lastPart = parts[parts.length - 1];
			} else if (path === '/live-das-lives-santa-casa-sp') {
				lastPart = 'santa casa sp';
			} else if (
				path === '/live-das-lives-sus-ba-vitoria-da-conquista' ||
				path === '/live-das-lives-sus-ba-juazeiro-petrolina'
			) {
				lastPart = 'sus-ba';
			} else if (path === '/live-das-lives-sus') {
				lastPart = 'sus-sp';
			} else if (path === '/live-das-lives-psu-mg-online') {
				lastPart = 'psu-mg-online';
			} else {
				lastPart = parts[parts.length - 2] + '-' + parts[parts.length - 1];
			}
		}

		// Encontra o objeto com o 'name' igual a 'lastPart'
		const foundContest =
			path === '/live-das-lives-sus-ba-vitoria-da-conquista'
				? contests[3]
				: path === '/live-das-lives-sus-ba-juazeiro-petrolina'
				? contests[4]
				: contests.find((contest) => contest.name === lastPart);

		// Define o objeto encontrado como o valor de 'currentContest'
		if (foundContest) {
			setCurrentContest(foundContest);
		}
	}, [contests]);

	useEffect(() => {
		if (currentContest) {
			Api.get(`turmasabertas/${currentContest?.id}`).then((res) => {
				// console.log('res: ', res);
				const currentDate = new Date();
				setTurma1(res[0]);
				setTurma2(res[1]);
				// Encontre o lote ativo com base na data atual e na data limite
				const activeLotTurma1 = res[0]?.lotes?.find((lot, index) => {
					return lot?.id === res[0]?.id_lote_vigente;
				});
				const activeLotTurma2 = res[1]?.lotes?.find((lot, index) => {
					return lot?.id === res[1]?.id_lote_vigente;
				});
				// Turma 1
				if (activeLotTurma1) {
					setCurrentLotTurma1([activeLotTurma1]);
					setNumParticipants(res[0]?.qtd_inscritos);
				} else {
					setNumParticipants(res[0]?.qtd_inscritos);
					const nextLot = res[0]?.lotes?.find((lot, index) => {
						const startDate = new Date(lot.data_inicio);
						return currentDate < startDate;
					});
					// Caso contrário, não há lote ativo, define 'lots' como vazio
					const valueLimitLot4 = res[0]?.lotes?.find((lot) => lot.nome === '4')?.qtd_limite;
					if (valueLimitLot4 <= res[0]?.qtd_inscritos) {
						setSold(true);
					}
					setCurrentLotTurma1([nextLot]);
				}
				// Turma 2
				if (activeLotTurma2) {
					setCurrentLotTurma2([activeLotTurma2]);
					setNumParticipants(res[1]?.qtd_inscritos);
				} else {
					setNumParticipants(res[1]?.qtd_inscritos);
					const nextLot = res[1]?.lotes?.find((lot, index) => {
						const startDate = new Date(lot.data_inicio);

						return currentDate < startDate;
					});
					// Caso contrário, não há lote ativo, define 'lots' como vazio
					const valueLimitLot4 = res[1]?.lotes?.find((lot) => lot.nome === '4')?.qtd_limite;
					if (valueLimitLot4 <= res[1]?.qtd_inscritos) {
						setSold(true);
					} else {
						// console.log('Não chegou no limite: false');
					}
					setCurrentLotTurma2([nextLot]);
				}
			});
		}

		const scriptMockArray = generateScriptMock(
			currentContest?.name?.includes('SES-GO')
				? 'SES-GO e do PSU-GO'
				: currentContest?.name?.includes('psu-mg')
				? 'PSU-MG'
				: currentContest?.name?.includes('usp')
				? 'USP'
				: currentContest?.name?.toUpperCase(),
			currentContest?.testBench?.toUpperCase()
		);

		setScriptMock(scriptMockArray);
	}, [currentContest]);

	useEffect(() => {
		if (currentContest && currentLotTurma1) {
			const isLotOpen = currentDate >= new Date(currentLotTurma1[0]?.data_inicio);
			setIsLotOpened(isLotOpen);
		}
	}, [currentLotTurma1, currentContest]);

	function generateScriptMock(exam, testBench) {
		return [
			{
				id: 1,
				img: CaraDaProva,
				gif: GifCaraDaProva,
				//source: require('./Assets/caraDaProva.mp4'),
				source: process.env.PUBLIC_URL + '/Assets/ResidenciaTemplate/caraDaProva.mp4',
				title: 'A cara da prova',
				description: `Um choque de realidade! A gente já começa mostrando os temas que mais diferenciam a prova ${adjustArticle(
					exam
				)} ${exam} das demais... os professores de todas as áreas participam, selecionando cerca de 15 temas que são A CARA DA PROVA!`,
			},
			{
				id: 2,
				img: Shots,
				gif: GifShots,
				//source: require('./Assets/shots.mp4'),
				source: process.env.PUBLIC_URL + '/Assets/ResidenciaTemplate/shots.mp4',
				title: 'Shots',
				description: `Hora do Stand Up Hardwork. O bloco queridinho da Live das Lives, com professores de diversas áreas no palco. Quando a luz se acende o professor relembra um tema de forma enfática, no padrão ${
					testBench === 'SES/PSU-GO' ? 'da SES-GO e do PSU-GO' : testBench
				}; a luz se apaga e já trocamos de área, tema, professor... assim seguimos por cerca de 20 temas numa dinâmica avassaladora.`,
			},
			{
				id: 3,
				img: Imagens,
				gif: GifImagens,
				//source: require('./Assets/imagens.mp4'),
				source: process.env.PUBLIC_URL + '/Assets/ResidenciaTemplate/imagens.mp4',
				title: 'Imagens',
				description: `Imagens, MUITAS imagens! ${
					exam?.includes('SES-GO')
						? 'Assim é o bloco de imagens'
						: `Assim é a prova ${adjustArticle(exam)} ${exam}, assim é o bloco de imagens`
				}. Hora de desmistificar uma série de temas com imagens muito relevantes em diversas áreas.`,
			},
			{
				id: 4,
				img: XoteDosMilagres,
				gif: GifShots,
				//source: require('./Assets/shots.mp4'),
				source: process.env.PUBLIC_URL + '/Assets/ResidenciaTemplate/shots.mp4',
				title: 'Xote dos milagres',
				description:
					'Calma... não é música... mas até que poderia ser... É mais um bloco de shots, repleto de temas importantes, só que agora diferente. São temas um tanto milagrosos, inspirados por insights profundos dos professores Hardwork. ',
			},
			{
				id: 5,
				img: Apostas,
				gif: GifApostas,
				//source: require('./Assets/apostas.mp4'),
				source: process.env.PUBLIC_URL + '/Assets/ResidenciaTemplate/apostas.mp4',
				title: 'Apostas',
				description: `Por fim, o bloco mais esperado da Live das Lives... os professores retornam ao palco para trazer, em cada área, as apostas Hardwork para a prova ${adjustArticle(
					exam
				)} ${exam}!`,
			},
		];
	}

	const executeScroll = () => purchase_card.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	const handleLeftClick = (e) => {
		e.preventDefault();

		carousel.current.scrollLeft -= carousel.current.offsetWidth;
	};

	const handleRightClick = (e) => {
		e.preventDefault();

		carousel.current.scrollLeft += 380;
	};

	const handleScriptLeftClick = (e) => {
		e.preventDefault();

		scriptCarousel.current.scrollLeft -= scriptCarousel.current.offsetWidth;
	};

	const handleScriptRightClick = (e) => {
		e.preventDefault();

		scriptCarousel.current.scrollLeft += scriptCarousel.current.offsetWidth;
	};

	const handleFeedbackLeftClick = (e) => {
		e.preventDefault();

		feedbackCarousel.current.scrollLeft -= feedbackCarousel.current.offsetWidth;
	};

	const handleFeedbackRightClick = (e) => {
		e.preventDefault();

		feedbackCarousel.current.scrollLeft += feedbackCarousel.current.offsetWidth;
	};

	function adjustArticle(contestName) {
		if (
			contestName === 'enare' ||
			contestName === 'sus-sp' ||
			contestName === 'SUS-SP' ||
			contestName === 'sus-sp' ||
			contestName === 'SUS-BA' ||
			contestName === 'sus-ba' ||
			contestName === 'PSU-MG' ||
			contestName === 'psu-mg'
		) {
			return 'do';
		} else {
			return 'da';
		}
	}

	const handleOpen = (source) => {
		setSelectedVideo(source);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setSelectedVideo('');
	};

	useEffect(() => {
		if (currentContest) {
			const ativo = currentDate > new Date(currentLotTurma1[0]?.data_inicio);
			setIsActive(ativo);
		}
	}, [currentLotTurma1]);

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{currentContest && (
				<Helmet>
					<title>{currentContest?.name?.toUpperCase()} Live das Lives 2024</title>
					<meta
						name="description"
						content={''}
					/>
				</Helmet>
			)}
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="residencia"
			>
				{matchesMobile || matchesTablet ? null : <Header />}
			</Container>
			<Grid
				container
				alignItems={'center'}
				style={matchesMobile ? { marginTop: 80 } : null}
			>
				<video
					src={process.env.PUBLIC_URL + '/Assets/video-ldl-background-24-2.mp4'}
					autoPlay
					loop
					muted
					playsInline
					className={classes.videoBackground}
				/>
				<Grid
					item
					xs={12}
					container
					direction={'column'}
					alignContent={'center'}
					justifyContent={'center'}
					className={`${classes.gridLogoContainer} residencia`}
				>
					{/* <img
						src={
							currentContest.modality === 'online'
								? require('./Assets/img-online-tag.svg').default
								: require('./Assets/img-presencial-tag.svg').default
						}
						alt=""
						className={classes.ldlLogo}
						style={{ marginBottom: matchesMobile ? '4rem' : matchesDownXl ? '7rem' : '6rem' }}
					/> */}
					<img
						src={
							path === '/live-das-lives-sus-ba-vitoria-da-conquista'
								? require('./Assets/ldl-sus-ba-vitoria-conquista-presencial.svg').default
								: path === '/live-das-lives-sus-ba-juazeiro-petrolina'
								? require('./Assets/ldl-sus-ba-juazeiro-petrolina-presencial.svg').default
								: turma1?.logo
						}
						alt=""
						className={classes.ldlLogo}
						style={matchesMobile ? { margin: '1rem 0', width: '72%' } : { width: '65%' }}
					/>
					{(currentLotTurma1 || currentLotTurma2) && (
						<DefaultButton
							variant="outlined"
							color={'primary'}
							className={`${classes.subscribeBtn} white-btn-secondary poppins`}
							onClick={() => executeScroll()}
						>
							{path.includes('enare') && (
								<img
									src={require('../../../../Assets/Svg/Icons/bagIcon.svg').default}
									alt=""
									className={classes.logoBtn}
								/>
							)}
							&nbsp;&nbsp;
							{isVipList ? 'Entrar na Lista VIP' : isActive ? 'Garantir vaga' : 'Avise-me'}
						</DefaultButton>
					)}
				</Grid>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="residencia"
			>
				<Grid
					container
					className="section"
				>
					<Grid
						item
						xs={12}
						container
						justifyContent={'space-between'}
					>
						<Grid
							item
							xs={12}
							md={6}
							container
							alignContent={'center'}
							style={{ top: matchesMobile ? null : matchesTablet ? 15 : 30 }}
						>
							<img
								src={require('./Assets/revisao-de-vespera.svg').default}
								alt=""
							/>
							<Typography
								color="secondary"
								className={'Poppins'}
								style={{
									fontSize: matchesTablet ? '1.1rem' : '2.05rem',
									fontWeight: 'bold',
									marginTop: '0.5rem',
									lineHeight: 'unset',
								}}
							>
								A estratégia perfeita para entrar na alma da banca.
							</Typography>
							<img
								src={
									matchesMobile
										? require('./Assets/img-ldl-experience-01-mobile-rev1.png')
										: require('./Assets/img-ldl-experience-01-rev1.png')
								}
								alt=""
							/>
							<Typography
								color={'#C1C1C1'}
								className={`${classes.firstText} poppins`}
							>
								Foco total na {currentContest?.name?.includes('SES-GO') ? 'provas' : 'prova'}{' '}
								{currentContest?.name?.includes('SES-GO') ? 'da' : adjustArticle(currentContest?.name)}{' '}
								<br />
								{currentContest?.name?.includes('SES-GO')
									? 'SES-GO e do PSU-GO'
									: currentContest?.name?.includes('psu-mg')
									? 'PSU-MG'
									: currentContest?.name?.includes('usp')
									? 'USP'
									: currentContest?.name?.toUpperCase()}
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							md={5.5}
							container
						>
							<img
								src={
									matchesMobile
										? require('./Assets/img-ldl-experience-02-mobile-rev1.png')
										: require('./Assets/img-ldl-experience-02-rev1.png')
								}
								alt=""
								className={classes.SecondImageLDL}
							/>
							<Typography
								color={'#C1C1C1'}
								className={`${classes.secondText} poppins`}
							>
								Imersão total do
								<br />
								começo ao fim!
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						container
					>
						<Grid
							item
							xs={12}
							md={7}
							container
							alignContent={'center'}
						>
							<img
								src={
									matchesMobile
										? require('./Assets/img-ldl-experience-03-mobile-rev1.png')
										: require('./Assets/img-ldl-experience-03-rev1.png')
								}
								alt=""
							/>
							<Typography
								color={'#C1C1C1'}
								className={`${classes.thirdText} poppins`}
							>
								Um show de didática com
								<br />
								os melhores professores
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							md={5}
							container
							alignContent={'center'}
						>
							<img
								src={
									matchesMobile
										? require('./Assets/img-ldl-experience-04-mobile-rev1.png')
										: require('./Assets/img-ldl-experience-04-rev1.png')
								}
								alt=""
							/>
							<Typography
								color={'#C1C1C1'}
								className={`${classes.fourthText} poppins`}
							>
								"Eu ouvi as vozes dos professores na hora da prova"
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Container>
			<Grid
				container
				rowGap={2}
				style={{ margin: matchesMobile ? '2rem 0' : '6rem 0' }}
			>
				<LiveInfoCard
					title={'Um show!'}
					description={
						matchesMobile
							? [
									`A Live das Lives ${
										currentContest?.name?.includes('SES-GO')
											? 'com foco nas provas da SES-GO e do PSU-GO'
											: currentContest?.name?.includes('psu-mg')
											? 'PSU-MG'
											: currentContest?.name?.includes('usp')
											? 'USP'
											: currentContest?.name?.toUpperCase()
									} é uma experiência! Não pode ser diferente! A véspera da prova não permite desperdício de energia. Tudo na Live das Lives é desenhado para que a revisão seja leve, prazerosa e intensa!`,
									<br />,
									<br />,
									'Temas explorados de formas variadas, numa dinâmica que manterá nossa conexão até o fim!',
							  ]
							: [
									`A Live das Lives ${
										currentContest?.name?.includes('SES-GO')
											? 'com foco nas provas da SES-GO e do PSU-GO'
											: currentContest?.name?.includes('psu-mg')
											? 'PSU-MG'
											: currentContest?.name?.includes('usp')
											? 'USP'
											: currentContest?.name?.toUpperCase()
									} é uma experiência! Não pode ser diferente! A véspera da prova não permite desperdício de energia. Tudo na Live das Lives é desenhado para que a revisão seja leve, prazerosa e intensa!`,
									<br />,
									<br />,
									'Temas explorados de formas variadas, numa dinâmica que manterá nossa conexão até o fim!',
							  ]
					}
				/>
				<LiveInfoCard
					title={'Véspera da prova!'}
					description={
						matchesMobile
							? [
									'A véspera da prova é sempre um momento complexo. Uma mistura de sentimentos que nos deixa sem saber o que fazer… Descansar? Assistir a aulas? Ler resumos?',
									<br />,
									<br />,
									`A Live das Lives surgiu para acabar com essa angústia! Tu só precisas ${
										currentContest?.modality === 'presencial' ? 'vir' : 'te conectar'
									}… deixe que a gente faz o resto!`,
							  ]
							: [
									'A véspera da prova é sempre um momento complexo. Uma mistura de sentimentos que nos deixa sem saber o que fazer… Descansar? Assistir a aulas? Ler resumos?',
									<br />,
									<br />,
									`A Live das Lives surgiu para acabar com essa angústia! Tu só precisas ${
										currentContest?.modality === 'presencial' ? 'vir' : 'te conectar'
									}… deixe que a gente faz o resto!`,
							  ]
					}
				/>
				<LiveInfoCard
					title={'Foco total!'}
					description={
						matchesMobile
							? [
									'A proposta da Live das Lives é simples: cerca de 7 horas de imersão total na prova! Nada passa em branco! Além das mensagens mais importantes dos temas de predileção de cada banca, a live também traz todas as apostas',
									<br />,
									<br />,
									'do Hardwork para a prova, resultado do estudo profundo dos últimos anos do concurso. Nós dominamos tudo!',
							  ]
							: [
									'A proposta da Live das Lives é simples: cerca de 7 horas de imersão total na prova! Nada passa em branco!',
									<br />,
									<br />,
									'Além das mensagens mais importantes dos temas de predileção de cada banca, a live também traz todas as apostas do Hardwork para a prova, resultado do estudo profundo dos últimos anos do concurso. Nós dominamos tudo!',
							  ]
					}
				/>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					alignContent="space-around"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						className="residencia"
					>
						<Typography
							align="left"
							className={`${classes.title} gradient-text title-card-home`}
						>
							Como funciona a live?
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						style={{ margin: matchesMobile ? '0.5rem 0 1.5rem' : '1.5em 0 2.5em' }}
					>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							Uma revisão de véspera explorando todos os temas importantes das bancas.
							<br />A Live das Lives{' '}
							{currentContest?.name?.includes('psu-mg')
								? 'PSU-MG'
								: currentContest?.name?.includes('usp')
								? 'USP'
								: currentContest?.name?.toUpperCase()}{' '}
							é um {''}
							{currentContest?.name === 'enare' ? (
								<strong>evento nas modalidades ONLINE E PRESENCIAL. {''}</strong>
							) : (
								<strong>
									evento EXCLUSIVAMENTE {''}
									{currentContest?.modality}. {''}
								</strong>
							)}
							{currentContest?.modality === 'presencial' ? 'Não há modelo online.' : null}
							<br />
							<br />A prova {adjustArticle(currentContest?.name)}{' '}
							{currentContest?.name?.includes('SES-GO')
								? 'SES-GO/PSU-GO'
								: currentContest?.name?.includes('psu-mg')
								? 'PSU-MG'
								: currentContest?.name?.includes('usp')
								? 'USP'
								: currentContest?.name?.toUpperCase()}
							{currentContest?.name === 'enare'
								? `, e mais específicamente a banca ${currentContest?.testBench},`
								: null}{' '}
							exige uma revisão muito especial. Para a conexão ser intensa do início ao fim, discutir e
							sedimentar muitos temas, a live é dividida em blocos diferentes, envolvendo vários
							professores simultaneamente:
						</Typography>
					</Grid>

					<Grid
						item
						xs={12}
						container
						justifyContent={matchesMobile ? 'space-between' : 'center'}
					>
						<Grid
							item
							className={classes.gridCarouselContainer}
							ref={scriptCarousel}
						>
							{currentContest &&
								scriptMock?.map((item, index) => {
									return (
										<Grid
											item
											xs={12}
											md={6}
											key={index}
											className={classes.gridScriptCard}
											style={index > 0 ? { marginLeft: '1.5em' } : null}
											onClick={() => handleOpen(item.source)}
										>
											<ScriptCard
												img={item.img}
												gif={item.gif}
												title={item.title}
												description={item.description}
												key={index}
												classes={classes}
											/>
										</Grid>
									);
								})}
						</Grid>
						<StyledModal
							aria-labelledby="unstyled-modal-title"
							aria-describedby="unstyled-modal-description"
							open={open}
							onClose={handleClose}
							slots={{ backdrop: StyledBackdrop }}
						>
							<Box
								display={'flex'}
								justifyContent={'center'}
								direction={''}
								sx={containerBox}
								style={matchesMobile ? { width: '330px', height: '190px' } : null}
							>
								<video
									sx={videoModal}
									src={selectedVideo}
									autoPlay
									loop
									style={matchesMobile ? { width: '25rem', borderRadius: 15 } : { borderRadius: 15 }}
								/>
							</Box>
						</StyledModal>

						<Button
							onClick={handleScriptLeftClick}
							className={`${classes.arrowBtn} ${classes.leftArrowBtn}`}
						>
							<img
								src={ArrowBack}
								alt=""
								className={classes.arrowIcon}
							/>
						</Button>
						<Button
							onClick={handleScriptRightClick}
							className={`${classes.arrowBtn} ${classes.arrowBtnRight}`}
						>
							<img
								src={ArrowForward}
								alt=""
								className={classes.arrowIcon}
							/>
						</Button>
					</Grid>
				</Grid>
				<Grid
					container
					alignContent="space-around"
					justifyContent="center"
					className="section"
					style={matchesMobile ? null : { margin: matchesTablet ? '4rem auto' : '3rem auto' }}
				>
					<Grid
						item
						xs={12}
						className="residencia"
					>
						<Typography
							align="left"
							className={`${classes.title} gradient-text title-card-home`}
						>
							Quando e onde a Live das Lives{' '}
							{currentContest?.name?.includes('psu-mg')
								? 'PSU-MG'
								: currentContest?.name?.includes('usp')
								? 'USP'
								: currentContest?.name?.toUpperCase()}{' '}
							irá acontecer?
						</Typography>
					</Grid>
					<WhereWhenComponent contest={currentContest} />
					<BoxApp
						CellphoneImg={CellphoneImg}
						IconApple={IconApple}
						IconBrowser={IconBrowser}
						IconGoogle={IconGoogle}
						LogoLdLHQ={LogoLdLHQ}
					/>
					{/* Kit Hardwork */}
					{currentContest?.modality?.includes('presencial') && (
						<div className="section">
							<KitHardwork
								img2={GiftBag}
								title={`E para alunos que comparecerem à Live das Lives presencial em ${currentContest?.locationCity}...`}
								text={
									'Receba o kit de prova exclusivo do Hardwork, com caderno, caneta, capa de chuva, cordão de crachá e	mais…'
								}
							/>
						</div>
					)}
				</Grid>
			</Container>
			<div ref={purchase_card}>
				{(turma1 || turma2) && (
					<CardDeCompras
						modalidade={currentContest.modality}
						turma1={turma1}
						turma2={turma2}
						currentLotTurma1={currentLotTurma1}
						currentLotTurma2={currentLotTurma2}
						numParticipantes={numParticipants}
						city={currentContest?.locationCity}
					/>
				)}
			</div>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					alignContent="space-around"
					justifyContent="center"
					className="section"
					// style={matchesMobile ? null : { marginTop: 0 }}
				>
					<Grid
						item
						xs={12}
						className="residencia"
						style={{ marginBottom: '2rem' }}
					>
						<Typography
							align="left"
							className={`${classes.title} gradient-text title-card-home`}
						>
							Depoimentos de nossos alunos
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						container
						justifyContent={matchesMobile ? 'space-between' : 'center'}
						style={matchesMobile ? { marginBottom: '1rem' } : null}
					>
						<Grid
							item
							container
							className={classes.gridCarouselContainer}
							ref={feedbackCarousel}
							style={matchesMobile ? { marginBottom: '1rem' } : null}
						>
							{currentContest?.feedbacks &&
								currentContest?.feedbacks.map((img, index) => {
									return (
										<Grid
											key={index}
											item
											xs={12}
											md={3}
											container
											className={classes.gridFeedbackCards}
											style={index > 0 ? { marginLeft: '1.5em' } : null}
										>
											<Grid
												item
												xs={12}
											>
												<img
													src={img}
													alt=""
													style={{
														width: '100%',
														borderBottomLeftRadius: 15,
														borderBottomRightRadius: 15,
													}}
												/>
											</Grid>
										</Grid>
									);
								})}
						</Grid>
						<Button
							onClick={handleFeedbackLeftClick}
							className={`${classes.arrowBtn} ${classes.leftArrowBtnFeedbackSection}`}
						>
							<img
								src={ArrowBack}
								alt=""
								className={classes.arrowIcon}
							/>
						</Button>
						<Button
							onClick={handleFeedbackRightClick}
							className={`${classes.arrowBtn} ${classes.arrowBtnRightFeedbackSection}`}
						>
							{/* <img
								src={ArrowForward}
								alt=""
								className={classes.arrowIcon}
							/> */}
						</Button>
					</Grid>
				</Grid>
			</Container>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					alignContent="space-around"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						className="residencia"
					>
						<Typography
							align="left"
							className={`${classes.title} gradient-text title-card-home`}
						>
							{matchesMobile
								? ['Olha quem ', <br />, ' vai conduzir o show: *']
								: 'Olha quem vai conduzir o show: *'}
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						container
						justifyContent={matchesMobile ? 'space-between' : 'center'}
					>
						<Grid
							item
							className={classes.gridCarouselContainer}
							ref={carousel}
							style={matchesMobile ? { left: 0 } : null}
						>
							{Hosts.map((item, index) => (
								<Grid
									item
									key={index}
									style={
										matchesMobile && index === 0
											? { marginRight: 35, right: 0 }
											: index === Hosts?.length - 1
											? { marginRight: 10 }
											: { marginRight: 35 }
									}
								>
									<HostCard
										ig_username={item.ig_username}
										img={item.img}
										specialty={item.specialty}
										key={index}
									/>
								</Grid>
							))}
						</Grid>
						<Grid
							item
							xs={12}
							style={matchesMobile ? { margin: '1em 0' } : { margin: '1em 0 1.5em' }}
						>
							<Typography
								align="left"
								color="secondary"
								className={`${classes.text} poppins`}
							>
								*Sujeito a alterações
							</Typography>
						</Grid>
						<Button
							onClick={handleLeftClick}
							className={`${classes.arrowBtn} ${classes.leftArrowBtn}`}
							style={{ top: 200 }}
						>
							<img
								src={ArrowBack}
								alt=""
								className={classes.arrowIcon}
							/>
						</Button>
						<Button
							onClick={handleRightClick}
							className={`${classes.arrowBtn} ${classes.arrowBtnRight}`}
							style={{ top: 200 }}
						>
							<img
								src={ArrowForward}
								alt=""
								className={classes.arrowIcon}
							/>
						</Button>
					</Grid>
				</Grid>
			</Container>
			{/* <OtherProductsComponent
				objectOfContests={ProductsMock}
				contest={currentContest.name}
			/> */}
			{currentLotTurma1 && (
				<CountDown
					scrollToPayment={executeScroll}
					turma={turma1}
					currentLot={currentLotTurma1}
					text={[
						<p
							style={{
								fontWeight: 800,
								fontFamily: 'Manrope',
								fontSize: matchesMobile ? '1.25rem' : '1.5rem',
								lineHeight: 'unset',
								display: 'inline',
							}}
						>
							{currentLotTurma1[0]?.nome}
						</p>,
						<br />,
						<p
							style={{
								fontFamily: 'Manrope',
								fontWeight: 500,
								fontSize: matchesMobile ? '0.865rem' : '1.125rem',
								color: '#CCCCCC',
							}}
						>
							{isLotOpened ? 'Encerramento do lote em:' : 'Abertura do lote em:'}
						</p>,
					]}
					date={
						isLotOpened
							? new Date(currentLotTurma1[0]?.data_limite)
							: new Date(currentLotTurma1[0]?.data_inicio)
					}
					isLotOpened={isLotOpened}
					colorStartButton={'#FF5F45'}
					colorEndButton={'#FF357D'}
				/>
			)}
			<Footer />
		</Container>
	);
}
//Quando abre o modal deixa o background escuro
const Backdrop = React.forwardRef((props, ref) => {
	const { open, className, ...other } = props;
	return (
		<div
			className={clsx({ 'MuiBackdrop-open': open }, className)}
			ref={ref}
			{...other}
		/>
	);
});

Backdrop.propTypes = {
	className: PropTypes.string.isRequired,
	open: PropTypes.bool,
};

const useStyles = makeStyles((theme, linkActive) => ({
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.75rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.9rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '2.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
		},
	},
	paper: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
	},
	scriptPaper: {
		height: 403,
		width: 684,
		cursor: 'pointer',
		[theme.breakpoints.down('xl')]: {
			width: 585,
			height: 349,
		},
		[theme.breakpoints.down('lg')]: {
			height: 'unset',
			width: 435,
		},
		[theme.breakpoints.down('md')]: {
			height: 393,
			width: 585,
		},
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
			width: 'unset',
		},
	},
	text: {
		fontSize: '1.3125rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			lineHeight: '1.5 !important',
		},
	},
	arrowIcon: {
		width: '47%',
	},
	arrowBtn: {
		position: 'absolute !important',
		zIndex: 1000,
		borderRadius: '50% !important',
		width: 80,
		height: 80,
		backgroundColor: '#2D2D2D !important',
		opacity: 0.75,
		'&:hover': { opacity: 1, transition: '0.2s' },
		[theme.breakpoints.down('sm')]: {
			position: 'unset !important',
			zIndex: 'unset',
			width: 60,
			height: 60,
		},
	},
	leftArrowBtn: {
		left: -90,
		top: 150,
		[theme.breakpoints.down('xl')]: {
			left: -90,
		},
		[theme.breakpoints.down(1389)]: {
			left: -50,
		},
		[theme.breakpoints.down('md')]: {
			left: -90,
		},
	},
	arrowBtnRight: {
		right: -80,
		top: 150,
		[theme.breakpoints.down('xl')]: {
			right: -35,
		},
		[theme.breakpoints.down('lg')]: {
			right: -55,
		},
	},
	gridCarouselContainer: {
		display: 'flex !important',
		overflowX: 'hidden',
		scrollBehavior: 'smooth',
		flexWrap: 'nowrap !important',
		overflowY: 'hidden',
		[theme.breakpoints.down('sm')]: {
			overflowX: 'auto',
		},
	},
	scriptTitle: {
		fontSize: '3.125rem !important',
		fontWeight: 'bold !important',
		position: 'absolute',
		bottom: 10,
		left: 35,
		textTransform: 'none',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
			left: 17,
		},
	},
	scriptText: {
		fontSize: '1.125rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	gridScriptCard: {
		[theme.breakpoints.down('sm')]: {
			minWidth: '100%',
		},
	},
	gridDescriptionScriptCard: {
		backgroundColor: '#FFFFFF !important',
		padding: '1em 2em',
		borderRadius: 16,
		textTransform: 'none',
		[theme.breakpoints.down('sm')]: {
			padding: '1em',
		},
	},
	defaultBtn: {
		fontSize: '1.05rem !important',
		fontWeight: '700 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	logoBtn: {
		filter: 'invert(1)',
		transition: 'ease-in-out 0.2s',
		[theme.breakpoints.down('xl')]: {
			height: 30,
		},
		[theme.breakpoints.down('sm')]: {
			height: 17,
		},
	},
	subscribeBtn: {
		width: 'fit-content',
		padding: '0.5rem 5rem !important',
		alignSelf: 'center',
		fontSize: '1.35rem !important',
		fontWeight: 'bold !important',
		marginTop: '2rem !important',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff !important',
			'& $logoBtn': {
				filter: 'invert(0)',
			},
		},
		[theme.breakpoints.down('xl')]: {
			padding: '0.25rem 2.5rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '0.85rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
			padding: '0.25rem 1.25rem !important',
			border: '2px solid transparent !important',
			marginTop: 'unset !important',
		},
	},
	gridLogoContainer: {
		position: 'absolute',
	},
	ldlLogo: {
		alignSelf: 'center',
		[theme.breakpoints.down(1509)]: {
			width: '60%',
		},
		[theme.breakpoints.down('sm')]: {
			alignSelf: 'center',
			width: '80%',
		},
	},
	videoBackground: {
		objectFit: 'cover',
		height: 676,
		width: '100%',
		[theme.breakpoints.down('xl')]: {
			height: 480,
		},
		[theme.breakpoints.down('md')]: {
			height: 332,
		},
		[theme.breakpoints.down('sm')]: {
			height: 283,
		},
	},
	backgroundWaves: {
		backgroundImage: `url(${process.env.PUBLIC_URL + '/Assets/Img/bgWave2.png)'})`,
		width: '100vw !important',
		backgroundSize: 'cover',
	},
	gridFeedbackCards: {
		backgroundColor: '#313131',
		paddingTop: '1rem !important',
		borderRadius: '15px',
		minWidth: '25%',
		[theme.breakpoints.down('md')]: {
			minWidth: '50%',
		},
		[theme.breakpoints.down('sm')]: {
			minWidth: '70%',
		},
	},
	leftArrowBtnFeedbackSection: {
		left: -90,
		top: 245,
		[theme.breakpoints.down('xl')]: {
			left: -90,
		},
		[theme.breakpoints.down(1389)]: {
			left: -50,
		},
		[theme.breakpoints.down('md')]: {
			left: -90,
		},
	},
	arrowBtnRightFeedbackSection: {
		right: -80,
		top: 245,
		[theme.breakpoints.down('xl')]: {
			right: -35,
		},
		[theme.breakpoints.down('lg')]: {
			right: -55,
		},
	},
	firstText: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		letterSpacing: '-2px !important',
		position: 'absolute',
		bottom: '10px',
		left: '70px',
		textShadow: '2px 2px 4px #000000',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.5rem !important',
			bottom: '0px',
			left: '50px',
			color: 'secondary !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem !important',
			left: '30px',
		},
		[theme.breakpoints.down('sm')]: {
			bottom: '5px',
			fontSize: '1.5rem !important',
		},
	},
	secondText: {
		letterSpacing: '-2px !important',
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		position: 'absolute',
		bottom: '5px',
		left: '70px',
		textShadow: '2px 2px 4px #000000',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.5rem !important',
			left: '50px',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem !important',
			left: '30px',
		},
		[theme.breakpoints.down('sm')]: {
			bottom: '0px',
			fontSize: '1.5rem !important',
		},
	},
	thirdText: {
		fontSize: '3rem !important',
		letterSpacing: '-2px !important',
		fontWeight: 'bold !important',
		position: 'absolute',
		bottom: '155px',
		left: '70px',
		lineHeight: 'unset !important',
		textShadow: '2px 2px 4px #000000',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.5rem !important',
			bottom: '90px',
			left: '50px',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem !important',
			bottom: '60px',
		},
		[theme.breakpoints.down('sm')]: {
			bottom: '-8px',
			fontSize: '1.5rem !important',
			left: '30px',
		},
	},
	fourthText: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		letterSpacing: '-2px !important',
		position: 'absolute',
		bottom: '-8px',
		left: '70px',
		textShadow: '2px 2px 4px #000000',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.5rem !important',
			left: '50px',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem !important',
			left: '30px',
		},
		[theme.breakpoints.down('sm')]: {
			bottom: '-2px',
			fontSize: '1.5rem !important',
		},
	},
}));

const StyledModal = styled(Modal)`
	position: fixed;
	z-index: 1300;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
	z-index: -1;
	position: fixed;
	inset: 0;
	background-color: rgb(0 0 0 / 0.5);
	-webkit-tap-highlight-color: transparent;
`;

const containerBox = {
	width: 710,
	height: 400,
	borderRadius: '12px',
	boxShadow: '0px 2px 24px',
	padding: '0.5rem',
	backgroundColor: '#343a40',
};

const videoModal = {};
