import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import DefaultButton from '../Buttons/DefaultButton';

const InfoCard = ({ type }) => {
	const classes = useStyles();

	return (
		<Paper
			color="primary"
			className={`${classes.root} box box-primary`}
			elevation={4}
		>
			<Grid
				container
				className={classes.gridContainer}
			>
				<Grid
					item
					container
				>
					<Grid
						item
						xs={12}
						container
						alignContent="center"
						className={`grid-title-infocard`}
					>
						{type === 'residencia' ? (
							<div className="residencia">
								<Typography
									align="left"
									className={`${classes.title} gradient-text title-card-home`}
								>
									Residência Médica
								</Typography>
							</div>
						) : (
							<div className="revalida">
								<Typography
									align="left"
									className={`${classes.title} gradient-text title-card-home`}
								>
									Revalida INEP
								</Typography>
							</div>
						)}
					</Grid>
					<Grid
						item
						xs={12}
						className={`${classes.gridText} grid-content-infocard`}
					>
						{type === 'residencia' ? (
							<Typography
								color="#DCDCDC"
								align="left"
								className={`${classes.content} text-content-infocard`}
							>
								Tudo que tu precisas para conquistar a vaga de R1 ou R3 na residência dos teus sonhos tu
								encontras aqui.
							</Typography>
						) : (
							<Typography
								color="#DCDCDC"
								align="left"
								className={`${classes.content} text-content-infocard`}
							>
								Vamos contigo até o fim na preparação para o Revalida INEP. Dominamos tudo, prova
								teórica e prova prática!
							</Typography>
						)}
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					container
					alignContent="center"
				>
					<Grid
						item
						xs={12}
					>
						{type === 'residencia' ? (
							<div className={`${classes.gridBtn} residencia`}>
								<DefaultButton
									variant="outlined"
									color="secondary"
									className={`${classes.btn} btn btn-primary button-info-card`}
									component={Link}
									to="/residencia-medica"
								>
									Conheça tudo
								</DefaultButton>
							</div>
						) : (
							<div className={`${classes.gridBtn} revalida`}>
								<DefaultButton
									variant="outlined"
									color="secondary"
									className={`${classes.btn} btn btn-primary button-info-card`}
									component={Link}
									to="/revalida-inep"
								>
									Conheça tudo
								</DefaultButton>
							</div>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default InfoCard;

const useStyles = makeStyles((theme) => ({
	root: {
		color: 'unset !important',
		width: '95%',
		borderRadius: '15px !important',
		padding: '1.5rem',
		paddingTop: 20,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: 270,
		maxHeight: 280,
		[theme.breakpoints.down('xl')]: {
			height: 240,
		},
		[theme.breakpoints.down('lg')]: {
			height: 185,
		},
		[theme.breakpoints.down('md')]: {
			width: '100% !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1rem',
			height: 205,
		},
	},
	gridContainer: {
		justifyContent: 'center',
		minHeight: '100% !important',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.9rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '2.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.188rem !important',
		},
	},
	content: {
		fontSize: '1.315rem !important',
		lineHeight: '1.2 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.95rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '.875rem !important',
		},
	},
	btn: {
		fontSize: '1.15rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.75rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '0.85rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
			width: '80%',
		},
	},
	gridBtn: {
		display: 'flex',
		justifyContent: 'center',
	},
	gridText: {
		minHeight: 101,
		[theme.breakpoints.down('lg')]: {
			minHeight: 'unset !important',
		},
	},
}));
