import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DefaultButton from '../Buttons/DefaultButton';
import PlayIconWhite from '../../Assets/Svg/playIconWhiteCard.svg';
import PlayIconBlackCard from '../../Assets/Svg/playIconBlackCard.svg';
import DoubleArrow from '../../Assets/Svg/DoubleArrowSprint.svg';

const LearningPathCard = ({ title, description, type, url, color, bgColor, titleColor, descriptionColor }) => {
	const classes = useStyles();
	const theme = useTheme();

	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Paper
			className={`${classes.root} saiba-mais-card`}
			elevation={bgColor === 'transparent' ? 0 : 4}
			style={
				bgColor === 'transparent'
					? { width: '220px', backgroundColor: `${bgColor}`, padding: 0 }
					: { backgroundColor: `${bgColor}` }
			}
		>
			{bgColor === 'transparent' ? (
				<Grid
					container
					justifyContent="center"
					alignContent="center"
				>
					<img src={DoubleArrow} />
				</Grid>
			) : (
				<Grid
					container
					justifyContent="center"
					alignContent="flex-start"
				>
					<Grid
						item
						xs={12}
						container
						className={`${
							titleColor === 'purple'
								? 'clinica-medica'
								: titleColor === 'cirurgia'
								? 'cirurgia-geral'
								: null
						} saiba-mais-grid-img`}
					>
						<Typography
							color="secondary"
							align="left"
							className={`${classes.titleText} description-saiba-mais-card roboto-condensed ${
								titleColor === 'purple'
									? 'clinica-medica gradient-text'
									: titleColor === 'cirurgia'
									? 'cirurgia-geral gradient-text'
									: null
							}`}
							style={{ color: `${titleColor}` }}
						>
							{title}
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						className="grid-description-saiba-mais"
						style={{ marginTop: 15 }}
					>
						<Typography
							color={`${descriptionColor}`}
							align="left"
							className={`${classes.content} description-saiba-mais-card poppins`}
							style={url || matchesMobile ? { overflowY: 'auto' } : { overflowY: 'auto' }}
						>
							{description}
						</Typography>
					</Grid>
				</Grid>
			)}
			<Grid
				item
				xs={8}
				container
				justifyContent={matchesMobile ? 'flex-end' : 'flex-start'}
				className={`${classes.gridButton} residencia`}
			>
				{url && (
					<DefaultButton
						variant="outlined"
						color="secondary"
						className={`${bgColor === '#ffffff' ? classes.btnWhite : classes.btn} btn `}
						onClick={handleClickOpen}
					>
						<img
							src={bgColor === '#ffffff' ? PlayIconWhite : PlayIconBlackCard}
							alt=""
						/>
						&nbsp;Exemplo
					</DefaultButton>
				)}
			</Grid>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className={classes.popup}
				maxWidth="xl"
				PaperProps={{
					style: {
						borderRadius: 20,
					},
				}}
			>
				<DialogContent className={classes.dialogContent}>
					<iframe
						title={title}
						src={url}
						style={{ border: 0, width: '100%', aspectRatio: 16 / 9, minWidth: '45vw', borderRadius: 8 }}
					></iframe>
				</DialogContent>
				<DialogActions style={{ width: '50%', alignSelf: 'center', padding: 16 }}>
					<DefaultButton
						variant="outlined"
						color="secondary"
						className={`${classes.popupBtn} btn`}
						onClick={handleClose}
						autoFocus
					>
						Fechar
					</DefaultButton>
				</DialogActions>
			</Dialog>
		</Paper>
	);
};

const useStyles = makeStyles((theme, bgColor) => ({
	root: {
		color: 'unset !important',
		height: '388px',
		width: '332px',
		borderRadius: '15px !important',
		padding: '1.5rem 1.5rem 3rem 1.5rem',
		display: 'flex',
		marginBottom: 30,
		[theme.breakpoints.down('sm')]: {
			height: '358px !important',
			width: '300px !important',
		},
	},
	titleText: {
		fontSize: '2.25em !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
		},
	},
	content: {
		lineHeight: '1.35 !important',
		maxHeight: 230,
		minHeight: 200,
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
			maxHeight: 200,
		},
	},
	gridButton: {
		position: 'absolute',
		bottom: '-4%',
		left: 35,
		[theme.breakpoints.down('sm')]: {
			bottom: '-18px !important',
			left: 70,
		},
	},
	btn: {
		textTransform: 'unset !important',
		width: '70%',
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		color: '#fff !important',
		border: '3px solid #fff !important',
		backgroundColor: '#0d0d0d !important',
		'&:hover': {
			scale: 1.1,
			backgroundColor: '#383333 !important',
		},
		[theme.breakpoints.down('sm')]: {
			minHeight: 'unset !important',
			maxHeight: '3.5em !important',
			width: '150px',
			fontSize: '0.8rem !important',
		},
	},
	btnWhite: {
		textTransform: 'unset !important',
		width: '70%',
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		color: 'black !important',
		border: '3px solid black !important',
		backgroundColor: '#fff !important',
		'&:hover': {
			scale: 1.1,
			backgroundColor: '#c3b9b9 !important',
		},
		[theme.breakpoints.down('sm')]: {
			minHeight: 'unset !important',
			maxHeight: '3.5em !important',
			width: '150px',
			fontSize: '0.8rem !important',
		},
	},
	popup: {
		maxWidth: 'unset !important',
		minWidth: '90%',
	},
	dialogContent: {
		paddingBottom: '0 !important',
	},
	popupBtn: {
		backgroundColor: 'black !important',
	},
}));

export default LearningPathCard;
