import { Button, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Api from '../../../../../Services/Api';
import { Link } from 'react-router-dom';

const icons = {
	check: require('../Assets/icon-check-green.svg').default,
	x: require('../Assets/icon-x-grey.svg').default,
};

const renderContent = (value) => {
	if (icons[value]) {
		return (
			<img
				src={icons[value]}
				alt={value}
				style={{ height: 32, width: 32 }}
			/>
		);
	}

	return (
		<Typography
			color="#FCFCFC"
			fontFamily="Manrope"
			fontWeight={600}
		>
			{value}
		</Typography>
	);
};

const TableRow = ({ title, basic, premium, isOdd, textWithCheck, imgTitle }) => {
	return (
		<Grid
			item
			xs={12}
			container
			style={{
				padding: '1.5rem 2rem',
				backgroundColor: isOdd ? '#040404' : '#1D1D1D',
			}}
		>
			<Grid
				item
				xs={3}
				container
				alignItems="center"
			>
				{imgTitle ? (
					<img
						src={imgTitle}
						alt=""
					/>
				) : (
					<Typography
						color="#FCFCFC"
						fontFamily="Manrope"
						fontWeight={500}
						fontSize={'1.125rem'}
					>
						{title}
					</Typography>
				)}
			</Grid>
			<Grid
				item
				xs={4.5}
				container
				justifyContent="center"
				alignItems="center"
			>
				{renderContent(basic)}
			</Grid>
			<Grid
				item
				xs={4.5}
				container
				direction={'column'}
				justifyContent="center"
				alignItems="center"
				rowGap={1}
			>
				{renderContent(premium)}
				<Typography
					fontFamily={'Manrope'}
					fontSize={'0.875rem'}
					color={'#EBEBEB'}
				>
					{textWithCheck}
				</Typography>
			</Grid>
		</Grid>
	);
};

const TableTextRow = ({ title, isOdd }) => {
	return (
		<Grid
			item
			xs={12}
			container
			justifyContent="center"
			alignItems="center"
			style={{
				padding: '1.5rem 2rem',
				backgroundColor: isOdd ? '#040404' : '#1D1D1D',
			}}
		>
			<Grid
				item
				xs={7}
				container
				justifyContent="center"
				style={{
					backgroundColor: '#303030',
					border: '1px solid #FCFCFC',
					padding: '0.5rem ',
					borderRadius: 4,
				}}
			>
				<Typography
					color="#FCFCFC"
					fontFamily="Manrope"
					fontWeight={600}
					style={{
						textTransform: 'uppercase',
					}}
				>
					{title}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default function PriceCardAndTable({ idTurma1, idTurma2, tableData }) {
	const [turma1, setTurma1] = useState();
	const [lot1, setLot1] = useState();
	const [turma2, setTurma2] = useState();
	const [lot2, setLot2] = useState();
	const [btnText1, setBtnText1] = useState('');
	const [btnText2, setBtnText2] = useState('');

	const currentDate = new Date();

	useEffect(() => {
		Api.get(`turmasabertas/${idTurma1}`).then((res) => {
			if (res) {
				setTurma1(res[0]);
			}
			const activeLot = res[0]?.lotes?.find((lot, index) => {
				return lot?.id === res[0]?.id_lote_vigente;
			});

			if (activeLot) {
				setLot1([activeLot]);
				setBtnText1('Inscreva-se');
			} else {
				const nextLot = res[0]?.lotes?.find((lot, index) => {
					const startDate = new Date(lot.data_inicio);

					return currentDate < startDate;
				});
				setLot1([nextLot]); //
				setBtnText1('Avise-me!');
			}
		});
		Api.get(`turmasabertas/${idTurma2}`).then((res) => {
			if (res) {
				setTurma2(res[0]);
			}
			const activeLot = res[0]?.lotes?.find((lot, index) => {
				return lot?.id === res[0]?.id_lote_vigente;
			});

			if (activeLot) {
				setLot2([activeLot]);
				setBtnText2('Inscreva-se');
			} else {
				const nextLot = res[0]?.lotes?.find((lot, index) => {
					const startDate = new Date(lot.data_inicio);

					return currentDate < startDate;
				});
				setLot2([nextLot]); //
				setBtnText2('Avise-me!');
			}
		});
	}, [idTurma1, idTurma2]);

	return (
		<Grid
			item
			xs={12}
			container
			style={{ borderRadius: 16 }}
		>
			<Grid
				item
				xs={12}
				container
				columnGap={5}
				style={{
					padding: '2rem',
					position: 'sticky',
					top: 0,
					zIndex: 2,
					backgroundColor: '#040404',
					borderTopLeftRadius: 16,
					borderTopRightRadius: 16,
				}}
			>
				<Grid
					item
					xs={3}
					container
					direction={'column'}
					justifyContent={'flex-end'}
				>
					<Typography
						color="#FCFCFC"
						fontFamily="Manrope"
						fontWeight={700}
						fontSize={'1.5rem'}
					>
						Diferenciais
					</Typography>
				</Grid>
				{/* BASIC */}
				<Grid
					item
					container
					alignItems={'center'}
					rowGap={2}
					style={{ flex: 2 }}
				>
					<Grid item>
						<img
							src={require('../Assets/logo-r1-25-basic.svg').default}
							alt=""
						/>
					</Grid>
				</Grid>
				{/* PREMIUM */}
				<Grid
					item
					// xs={4.5}
					container
					alignItems={'center'}
					rowGap={2}
					style={{ flex: 2 }}
				>
					<Grid item>
						<img
							src={require('../Assets/logo-r1-25-premium.svg').default}
							alt=""
						/>
					</Grid>
				</Grid>
			</Grid>
			{tableData.map((row, index) => {
				if (row.isTextOnly) {
					return (
						<TableTextRow
							key={index}
							title={row.title}
							isOdd={index % 2 === 1}
						/>
					);
				}

				return (
					<TableRow
						key={index}
						title={row.title}
						basic={row.basic}
						premium={row.premium}
						isOdd={index % 2 === 1}
						imgTitle={row.imgTitle}
						textWithCheck={row.aditionalText}
					/>
				);
			})}
			<Grid
				item
				xs={12}
				container
				justifyContent={'center'}
				style={{ backgroundColor: '#101010', padding: '1rem 0' }}
			>
				<Grid
					item
					container
					columnGap={2}
					alignItems={'center'}
					style={{ width: 'fit-content' }}
				>
					<img
						src={require('../../LDL/Assets/gift-duotone-solid.png')}
						alt=""
						style={{ width: 64, height: 64 }}
					/>
					<Grid
						item
						container
						direction={'column'}
						rowGap={0.5}
						style={{
							width: 'fit-content',
						}}
					>
						<Typography
							fontFamily={'Manrope'}
							color={'#FCFCFC'}
							fontSize={18}
						>
							Ganhe um Kit de flashcards exclusivo se inscrevendo em qualquer turma por tempo limitado!
						</Typography>
						{/* <Grid
							item
							container
							alignItems={'center'}
							columnGap={1}
							style={{
								width: 'fit-content',
							}}
						>
							<Grid
								item
								container
								justifyContent={'center'}
								alignItems={'center'}
								style={{
									backgroundColor: '#CCCCCC',
									width: 'fit-content',
									padding: '0 1rem',
									borderRadius: 8,
								}}
							>
								<Typography
									fontFamily={'Manrope'}
									color={'#101010'}
									fontWeight={700}
								>
									Kit de flashcards
								</Typography>
							</Grid>
							<Typography
								fontFamily={'Manrope'}
								color={'#FF8251'}
								fontWeight={700}
								fontSize={24}
							>
								+
							</Typography>
							<Grid
								item
								container
								justifyContent={'center'}
								alignItems={'center'}
								style={{
									backgroundColor: '#CCCCCC',
									width: 'fit-content',
									padding: '0 1rem',
									borderRadius: 8,
								}}
							>
								<Typography
									fontFamily={'Manrope'}
									color={'#101010'}
									fontWeight={700}
								>
									Echo Pop Alexa
								</Typography>
							</Grid>
							<Typography
								fontFamily={'Manrope'}
								color={'#959595'}
								fontWeight={500}
								style={{ lineHeight: 'unset' }}
							>
								ou
							</Typography>
							<Grid
								item
								container
								justifyContent={'center'}
								alignItems={'center'}
								style={{
									backgroundColor: '#CCCCCC',
									width: 'fit-content',
									padding: '0 1rem',
									borderRadius: 8,
								}}
							>
								<Typography
									fontFamily={'Manrope'}
									color={'#101010'}
									fontWeight={700}
								>
									Novo Kindle
								</Typography>
							</Grid>
						</Grid> */}
					</Grid>
				</Grid>
			</Grid>
			<Grid
				item
				xs={12}
				container
				justifyContent={'flex-end'}
				columnGap={5}
				style={{
					padding: '1.5rem 2rem',
					backgroundColor: '#040404',
					borderBottomLeftRadius: 16,
					borderBottomRightRadius: 16,
				}}
			>
				<Grid
					item
					xs={4.25}
					container
					rowGap={2}
					style={{ flex: 2 }}
				>
					<Grid item>
						<Typography
							color={'#656565'}
							fontWeight={500}
							fontFamily={'Manrope'}
							style={{
								textDecoration: 'line-through',
							}}
						>
							De: R${' '}
							{turma1?.valor?.toLocaleString('pt-BR', {
								minimumFractionDigits: 2,
							})}
						</Typography>
						{lot1 && (
							<Typography
								fontSize={'1.125rem'}
								fontWeight={600}
								fontFamily={'Manrope'}
								color={'#B3B3B3'}
							>
								por até{' '}
								<strong
									style={{
										fontFamily: 'Outfit',
										display: 'inline',
										color: '#FCFCFC',
									}}
								>
									{turma1?.parcelas?.length}x
								</strong>{' '}
								de
							</Typography>
						)}
						{lot1 && (
							<Typography
								color={'#B3B3B3'}
								fontFamily={'Manrope'}
								fontWeight={700}
								fontSize={'1.125rem'}
							>
								<strong
									style={{
										fontSize: '1.875rem',
										fontWeight: 'bold',
										fontFamily: 'Outfit',
										display: 'inline',
									}}
									className="gradient-text-trilha-r1-residencia"
								>
									R${' '}
									{turma1?.parcelas[turma1?.parcelas.length - 1].valor?.toLocaleString('pt-BR', {
										minimumFractionDigits: 2,
									})}
								</strong>{' '}
							</Typography>
						)}
						<Grid
							item
							container
							alignItems={'center'}
							columnGap={1}
						>
							{turma1 && (
								<Typography
									fontSize={'1.125rem'}
									fontWeight={600}
									fontFamily={'Manrope'}
									color={'#B3B3B3'}
								>
									ou{' '}
									<strong style={{ fontFamily: 'Outfit', color: '#FCFCFC' }}>
										R${' '}
										{lot1[0]?.valor?.toLocaleString('pt-BR', {
											minimumFractionDigits: 2,
										})}
									</strong>{' '}
									à vista
								</Typography>
							)}
							<Grid
								item
								style={{
									backgroundColor: '#00AD5899',
									padding: 6,
									borderRadius: 4,
								}}
							>
								<Typography
									fontFamily={'Manrope'}
									color={'#FCFCFC'}
									fontSize={'0.75rem'}
									style={{
										lineHeight: 'unset',
									}}
								>
									<strong>5% </strong>
									OFF
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Button
						component={Link}
						to={`//hardworkmedicina.com.br/inscricao/${turma1?.id}`}
						style={{
							backgroundColor: '#33C96F',
							borderRadius: 8,
							color: '#ffffff',
							width: '100%',
							fontWeight: 'bold',
							fontFamily: 'Manrope',
							textTransform: 'unset',
							fontSize: 20,
						}}
					>
						{btnText1 === 'Inscreva-se' && (
							<img
								src={require('../../../../../Assets/Svg/Icons/bagIcon.svg').default}
								alt=""
								style={{
									width: 16,
									marginRight: 8,
								}}
							/>
						)}
						{btnText1}
					</Button>
				</Grid>
				<Grid
					item
					xs={4.25}
					container
					rowGap={2}
					style={{ flex: 2 }}
				>
					<Grid item>
						<Typography
							color={'#656565'}
							fontWeight={500}
							fontFamily={'Manrope'}
							style={{
								textDecoration: 'line-through',
							}}
						>
							De: R${' '}
							{turma2?.valor?.toLocaleString('pt-BR', {
								minimumFractionDigits: 2,
							})}
						</Typography>
						{lot2 && (
							<Typography
								fontSize={'1.125rem'}
								fontWeight={600}
								fontFamily={'Manrope'}
								color={'#B3B3B3'}
							>
								por até{' '}
								<strong
									style={{
										fontFamily: 'Outfit',
										display: 'inline',
										color: '#FCFCFC',
									}}
								>
									{turma2?.parcelas?.length}x
								</strong>{' '}
								de
							</Typography>
						)}
						{lot2 && (
							<Typography
								color={'#B3B3B3'}
								fontFamily={'Manrope'}
								fontWeight={700}
								fontSize={'1.125rem'}
							>
								<strong
									style={{
										fontSize: '1.875rem',
										fontWeight: 'bold',
										fontFamily: 'Outfit',
										display: 'inline',
									}}
									className="gradient-text-trilha-r1-residencia"
								>
									R${' '}
									{turma2?.parcelas[turma2?.parcelas.length - 1].valor?.toLocaleString('pt-BR', {
										minimumFractionDigits: 2,
									})}
								</strong>{' '}
							</Typography>
						)}
						<Grid
							item
							container
							alignItems={'center'}
							columnGap={1}
						>
							{turma2 && (
								<Typography
									fontSize={'1.125rem'}
									fontWeight={600}
									fontFamily={'Manrope'}
									color={'#B3B3B3'}
								>
									ou{' '}
									<strong style={{ fontFamily: 'Outfit', color: '#FCFCFC' }}>
										R${' '}
										{lot2[0]?.valor?.toLocaleString('pt-BR', {
											minimumFractionDigits: 2,
										})}
									</strong>{' '}
									à vista
								</Typography>
							)}
							<Grid
								item
								style={{
									backgroundColor: '#00AD5899',
									padding: 6,
									borderRadius: 4,
								}}
							>
								<Typography
									fontFamily={'Manrope'}
									color={'#FCFCFC'}
									fontSize={'0.75rem'}
									style={{
										lineHeight: 'unset',
									}}
								>
									<strong>5% </strong>
									OFF
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Button
						component={Link}
						to={`//hardworkmedicina.com.br/inscricao/${turma2?.id}`}
						style={{
							backgroundColor: '#33C96F',
							borderRadius: 8,
							color: '#ffffff',
							width: '100%',
							fontWeight: 'bold',
							fontFamily: 'Manrope',
							textTransform: 'unset',
							fontSize: 20,
						}}
					>
						{btnText2 === 'Inscreva-se' && (
							<img
								src={require('../../../../../Assets/Svg/Icons/bagIcon.svg').default}
								alt=""
								style={{
									width: 16,
									marginRight: 8,
								}}
							/>
						)}
						{btnText2}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
}
