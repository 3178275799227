import React, { useRef } from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Header } from '../../../../Components/Header';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import FirstSection from '../../../../Components/Sections/FirstSection';
import Form from '../../../../Components/Utils/Forms/PreRegistration';

export default function LiveProvaDiscursivaRevalida() {
	const classes = useStyles();
	const theme = useTheme();
	const arena_card = useRef(null);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const executeScroll = () => arena_card.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="revalida"
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<FirstSection
					backgroundImageUrl={require('../../../../Assets/Extensivo/Revalida/bg-live-revalida.png')}
					logoUrl={require('../../../../Assets/Arena/logo-live-revalida.svg').default}
					type="green"
					buttonText="Realizar pré-inscrição"
					executeScroll={executeScroll}
					firstTextPaper={[
						<Typography
							variant="h1"
							className={`${classes.gradientText} revalida gradient-text roboto-condensed`}
						>
							A Maior revolução Hardwork Medicina: Vamos te treinar para a discursiva!
						</Typography>,
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							Quer saber como dominar a prova DISCURSIVA do Inep? Vou te contar tudo sobre a mais nova
							revolução do Hardwork!
							<br />
							<br />
						</Typography>,
					]}
					buttonProps={{ bottom: '7.5em', left: '5em', type: 'white-btn' }}
					imageProps={{ width: '20%', left: '15em', top: '11.75em' }}
				/>
			</Container>

			<Grid container>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					<Grid
						className="section"
						container
						xs={12}
						lg={5}
						justifyContent={'center'}
						ref={arena_card}
						style={{ margin: '0 auto' }}
					>
						<Form
							group="LIVE TREINAMENTO DISCURSIVA REVALIDA"
							title={['Cadastre e receba as novidades em primeira mão']}
							titleButton="Confirmar Pré-inscrição"
							type="revalida"
							urlToRedirect="/pre-inscricao/live-treinamento-discursiva-revalida"
							confirmationMessage={
								'Sua inscrição está confirmada! Te avisaremos antes do início da live.'
							}
						/>
					</Grid>
				</Container>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Footer />
			</Container>
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	text: {
		fontSize: '1.75rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	subtitle1: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
			lineHeight: 'unset !important',
		},
	},
	backgroundWaves: {
		backgroundImage: process.env.PUBLIC_URL + '/Assets/Img/bgWave2.png',
		width: '100vw !important',
	},
	gradientText: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		marginBottom: '16px !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
		},
	},
	textMultimidiaCard: {
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		alignSelf: 'center',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	imageDiferencialBox: {
		height: 120,
		[theme.breakpoints.down('lg')]: {
			height: 100,
		},
		[theme.breakpoints.down('sm')]: {
			height: 50,
		},
	},
	titleDiferencialBox: {
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	gridDiferencialBox: {
		margin: '50px 0 !important',
		[theme.breakpoints.down('sm')]: {
			margin: '25px 0 !important',
		},
	},
}));
