import React, { useEffect, useState } from 'react';
import { useMediaQuery, useTheme, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function CountDown({
	scrollToPayment,
	date,
	text,
	textMobile,
	colorStartButton,
	colorEndButton,
	btnText = 'Garanta sua vaga!',
	turma,
	currentLot,
	isLotOpened,
}) {
	const theme = useTheme();
	const classes = useStyles();
	const [isOpen, setIsOpen] = useState(true);

	const [timeLeft, setTimeLeft] = useState();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesDownXl = useMediaQuery(theme.breakpoints.down('xl'));

	useEffect(() => {
		if (date) {
			const timer = setInterval(() => {
				setTimeLeft(calculateTimeLeft());
			}, 1000);
			return () => clearInterval(timer);
		}
	}, [date]);

	function calculateTimeLeft() {
		const targetDate = new Date(date);
		const now = new Date();
		const difference = targetDate - now;

		let timeLeft = {};

		if (difference > 0) {
			timeLeft = {
				days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60),
			};
		} else {
			timeLeft = {
				days: 0,
				hours: 0,
				minutes: 0,
				seconds: 0,
			};
		}

		return timeLeft;
	}

	const isCountdownZero =
		timeLeft?.days === 0 && timeLeft?.hours === 0 && timeLeft?.minutes === 0 && timeLeft?.seconds === 0;

	if (isCountdownZero) {
		return null;
	}

	return (
		<Grid
			container
			direction={matchesMobile ? 'column' : 'row'}
			justifyContent="center"
			alignItems="center"
			className={classes.container}
			style={{
				maxWidth: '100vw',
			}}
		>
			<Grid
				item
				xs={12}
				container
				justifyContent={'center'}
			>
				<Grid
					container
					wrap={matchesTablet ? 'wrap' : 'nowrap'}
					justifyContent={'space-between'}
					alignItems="center"
					style={{
						width: matchesTablet ? null : 1410,
					}}
				>
					<Grid
						item
						container
						style={{
							marginBottom: 30 >= currentLot[0]?.qtd_limite - turma?.qtd_inscritos ? null : '1rem',
						}}
					>
						<Grid
							item
							xs={6}
							lg={12}
							container
						>
							<Typography
								fontFamily="Manrope"
								fontWeight={500}
								textAlign={'left'}
								style={{ whiteSpace: 'normal' }}
							>
								{text}
							</Typography>
						</Grid>
						{matchesTablet && (
							<Grid
								item
								xs={6}
								container
								direction={'column'}
								fontSize={matchesMobile ? 6 : 12}
								fontFamily="Outfit"
								justifyContent="center"
								className={classes.countdownItem}
							>
								<Grid
									item
									container
									justifyContent={'center'}
								>
									{timeLeft?.days !== 0 && (
										<>
											<Grid
												item
												container
												alignItems={'flex-end'}
												className={classes.countdownNumber}
												fontSize={20}
												width={'fit-content'}
											>
												{timeLeft?.days?.toString()?.padStart(2, '0')}
											</Grid>
											<Grid
												item
												container
												marginBottom={matchesMobile ? 0 : 1.6}
												marginRight={matchesMobile ? 2 : 0.8}
												marginLeft={matchesMobile ? 0.75 : 1}
												alignItems={'flex-end'}
												className={classes.countdownText}
												width={matchesMobile ? 25 : 50}
											>
												{timeLeft?.days <= 1 ? 'Dia' : 'Dias'}
											</Grid>
										</>
									)}
									<Grid
										item
										container
										alignItems={matchesMobile ? 'flex-end' : 'center'}
										className={classes.countdownNumber}
										fontSize={20}
										paddingTop={matchesMobile ? 1.5 : 0}
										width={'fit-content'}
									>
										{timeLeft?.hours?.toString()?.padStart(2, '0')}
									</Grid>
									<Grid
										item
										container
										alignItems={matchesMobile ? 'flex-end' : 'center'}
										className={classes.countdownText}
										margin={matchesMobile ? '0 0.25rem' : 0.4}
										marginBottom={matchesMobile ? 0 : 1.5}
										width={'fit-content'}
										fontWeight={800}
										fontSize={20}
									>
										:
									</Grid>
									<Grid
										item
										container
										alignItems={matchesMobile ? 'flex-end' : 'center'}
										className={classes.countdownNumber}
										fontSize={20}
										width={'fit-content'}
										paddingTop={matchesMobile ? 1.5 : 0}
									>
										{timeLeft?.minutes?.toString()?.padStart(2, '0')}
									</Grid>
									<Grid
										item
										container
										alignItems={matchesMobile ? 'flex-end' : 'center'}
										className={classes.countdownText}
										margin={matchesMobile ? '0 0.25rem' : 0.4}
										marginBottom={matchesMobile ? 0 : 1.5}
										width={'fit-content'}
										fontWeight={800}
										fontSize={20}
									>
										:
									</Grid>
									<Grid
										item
										className={classes.countdownNumber}
										fontSize={20}
										width={'fit-content'}
										paddingTop={matchesMobile ? 1.5 : 0}
									>
										{timeLeft?.seconds?.toString()?.padStart(2, '0')}
									</Grid>
								</Grid>
							</Grid>
						)}
					</Grid>
					{30 >= currentLot[0]?.qtd_limite - turma?.qtd_inscritos && isLotOpened && (
						<Grid
							item
							container
							justifyContent={'center'}
							style={{ marginBottom: matchesTablet ? '0.75rem' : null }}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
									width: matchesTablet ? '100%' : '80%',
									marginTop: '1rem',
									color: 'white',
									gap: '0.3rem',
								}}
							>
								{currentLot[0]?.qtd_limite - turma?.qtd_inscritos === 0 ? (
									<div style={{ display: 'flex', alignItems: 'center', columnGap: 8 }}>
										<p style={{ fontWeight: '600', fontSize: 12, color: '#CCCCCC' }}>
											{/* {lote?.qtd_limite - turma?.qtd_inscritos} vagas restantes */}
											Vagas esgotadas
										</p>
									</div>
								) : (
									<div style={{ display: 'flex', alignItems: 'center', columnGap: 8 }}>
										<p style={{ fontWeight: '800', fontSize: 14, color: '#F1F1F1' }}>
											{currentLot[0]?.qtd_limite - turma?.qtd_inscritos}
										</p>
										<p style={{ fontWeight: '600', fontSize: 12, color: '#CCCCCC' }}>
											{/* {lote?.qtd_limite - turma?.qtd_inscritos} vagas restantes */}
											Vagas restantes
										</p>
									</div>
								)}
								<div
									style={{
										width: '100%',
										height: '12px',
										backgroundColor: '#656565',
										borderRadius: '18px',
										position: 'relative',
									}}
								>
									<div
										style={{
											width: `${
												((30 - (currentLot[0]?.qtd_limite - turma?.qtd_inscritos)) / 30) * 100
											}%`,
											// width: `${((30 - (lote?.qtd_limite - turma?.qtd_inscritos)) / 30) * 100}%`,
											position: 'absolute',
											height: '12px',
											background: 'linear-gradient(90deg, #FF5F45 0.82%, #FF357D 100.05%)',
											borderRadius: '18px',
										}}
									/>
								</div>
							</div>
						</Grid>
					)}
					{!matchesTablet && (
						<Grid
							item
							container
							direction={'column'}
							fontSize={matchesMobile ? 6 : 12}
							fontFamily="Outfit"
							justifyContent="center"
							className={classes.countdownItem}
						>
							<Grid
								item
								container
								justifyContent={'center'}
							>
								{timeLeft?.days !== 0 && (
									<>
										<Grid
											item
											container
											alignItems={'flex-end'}
											className={classes.countdownNumber}
											fontSize={matchesMobile ? 25 : 45}
											width={'fit-content'}
										>
											{timeLeft?.days?.toString()?.padStart(2, '0')}
										</Grid>
										<Grid
											item
											container
											marginBottom={matchesMobile ? 0 : 1.6}
											marginRight={matchesMobile ? 2 : 0.8}
											marginLeft={matchesMobile ? 0.75 : 1}
											alignItems={'flex-end'}
											className={classes.countdownText}
											width={matchesMobile ? 25 : 50}
										>
											{timeLeft?.days <= 1 ? 'Dia' : 'Dias'}
										</Grid>
									</>
								)}
								<Grid
									item
									container
									alignItems={matchesMobile ? 'flex-end' : 'center'}
									className={classes.countdownNumber}
									fontSize={matchesMobile ? 25 : 45}
									paddingTop={matchesMobile ? 1.5 : 0}
									width={'fit-content'}
								>
									{timeLeft?.hours?.toString()?.padStart(2, '0')}
								</Grid>
								<Grid
									item
									container
									alignItems={matchesMobile ? 'flex-end' : 'center'}
									className={classes.countdownText}
									margin={matchesMobile ? '0 0.25rem' : 0.4}
									marginBottom={matchesMobile ? 0 : 1.5}
									width={'fit-content'}
									fontWeight={800}
									fontSize={32}
								>
									:
								</Grid>
								<Grid
									item
									container
									alignItems={matchesMobile ? 'flex-end' : 'center'}
									className={classes.countdownNumber}
									fontSize={matchesMobile ? 25 : 45}
									width={'fit-content'}
									paddingTop={matchesMobile ? 1.5 : 0}
								>
									{timeLeft?.minutes?.toString()?.padStart(2, '0')}
								</Grid>
								<Grid
									item
									container
									alignItems={matchesMobile ? 'flex-end' : 'center'}
									className={classes.countdownText}
									margin={matchesMobile ? '0 0.25rem' : 0.4}
									marginBottom={matchesMobile ? 0 : 1.5}
									width={'fit-content'}
									fontWeight={800}
									fontSize={32}
								>
									:
								</Grid>
								<Grid
									item
									className={classes.countdownNumber}
									fontSize={matchesMobile ? 25 : 45}
									width={'fit-content'}
									paddingTop={matchesMobile ? 1.5 : 0}
								>
									{timeLeft?.seconds?.toString()?.padStart(2, '0')}
								</Grid>
							</Grid>
						</Grid>
					)}
					<Grid
						item
						container
						justifyContent={matchesTablet ? 'center' : 'flex-end'}
					>
						<button
							onClick={() => scrollToPayment()}
							className={classes.optionButton}
							color="secondary"
							style={{
								background: `linear-gradient(180deg, ${colorStartButton} 0%, ${colorEndButton} 100%)`,
								color: 'white',
								fontWeight: matchesTablet ? 600 : 700,
								padding: '10px 25px',
							}}
						>
							{btnText}
						</button>
					</Grid>
				</Grid>
				{/* <div
					style={{
						position: 'absolute',
						right: matchesTablet ? -5 : 5,
						top: -8,
						width: 16,
						height: 16,
						cursor: 'pointer',
					}}
					onClick={() => setIsOpen(false)}
				>
					<img
						src={require('../../R3/assets/icon-close-grey.png')}
						alt=""
					/>
				</div> */}
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'fixed',
		bottom: 0,
		background: `linear-gradient(180deg, #565656 0%, #1F1F1F 100%)`,
		color: '#fff',
		padding: '1rem 0.5rem',
		zIndex: 1000,
		fontFamily: 'Manrope',
		backdropFilter: 'blur(20px)',
		[theme.breakpoints.down('xl')]: {
			padding: '1rem 1.5rem',
		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column !important',
			alignItems: 'center !important',
		},
	},
	countdownItem: {
		[theme.breakpoints.down('sm')]: {
			marginRight: '0px !important',
			alignItems: 'center !important',
		},
	},
	countdownNumber: {
		fontSize: '37px',
		margin: theme.spacing(0.5),
		fontWeight: 800,
	},
	countdownText: {
		fontSize: '14px',
		margin: theme.spacing(0.5),
		textAlign: 'center',
	},
	optionButton: {
		cursor: 'pointer',
		borderRadius: '24px !important',
		width: 'fit-content',
		fontFamily: 'Manrope',
		fontSize: '20px !important',
		[theme.breakpoints.down('sm')]: {
			width: '80%',
			fontSize: '16px !important',
			padding: '8px 60px 8px 60px !important',
		},
	},
}));
