import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function PreInscricaoRevalida() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<Grid
			container
			height={matchesMobile ? 'auto' : '80vh'}
			style={{ overflowY: 'auto', maxHeight: '80vh' }}
		>
			<Grid
				item
				xs={12}
				container
				rowGap={0}
				justifyContent={'start'}
				alignContent={'center'}
				alignSelf={'center'}
				marginLeft={matchesMobile ? 5 : 0}
			>
				<img
					src={require('../Revalida/assets/img-teachers-revalida.png')}
					alt=""
				/>

				<img
					src={require('../Revalida/assets/img-live-revalida.png')}
					alt=""
				/>
				<Typography
					color="secondary"
					className={`${classes.text}`}
					fontFamily={'Manrope'}
					fontWeight={'700 !important'}
					marginTop={'8px'}
				>
					Correção com comentários de todas as estações da prova prática do Revalida INEP.
				</Typography>
			</Grid>

			<Grid
				item
				xs={12}
				container
				alignItems={'center'}
				//columnGap={matchesTablet ? 1 : 1.5}
				className="residencia"
				justifyContent={'center'}
				borderRadius={5}
				padding={0}
			>
				<Grid
					item
					container
					direction={'column'}
					justifyContent={matchesMobile ? 'center' : 'flex-start'}
					style={{
						height: 'fit-content',
					}}
				>
					<Typography
						color="secondary"
						className={`${classes.impactText}`}
						fontFamily={'Manrope'}
						marginTop={0}
					>
						20 DEZ • A partir das 20H
					</Typography>
					<Typography
						color="secondary"
						className={`${classes.impactText}`}
						fontFamily={'Manrope'}
						marginTop={0}
					>
						Evento online e gratuito
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: '800 !important',
		fontSize: '40px !important',
		fontFamily: 'Manrope !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '32px !important',
		},
	},
	subtitle: {
		fontSize: '1.25rem !important',
		lineHeight: '1.25 !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	text: {
		fontWeight: '400 !important',
		fontSize: '20px !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '16px !important',
		},
	},
	impactText: {
		fontSize: '32px !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '28px !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '24px !important',
		},
	},
}));
