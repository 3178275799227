import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function ScriptCardArena({ img, title, description }) {
	const classes = useStyles();

	return (
		<Paper
			className={`${classes.scriptPaper} rounded-card`}
			color="secondary"
			elevation={4}
		>
			<Grid
				container
				className={classes.gridImage}
			>
				<img
					src={img}
					alt=""
					style={{ width: '100%', opacity: 0.6 }}
				/>
				<Typography
					align="left"
					color="secondary"
					className={`${classes.scriptTitle} title-card-home`}
				>
					{title}
				</Typography>
			</Grid>
			<Grid
				container
				justifyContent={'center'}
				alignContent="center"
				className={classes.gridDescriptionScriptCard}
			>
				<Typography
					align="left"
					color="primary"
					className={`${classes.scriptText} poppins`}
				>
					{description}
				</Typography>
			</Grid>
		</Paper>
	);
}

const useStyles = makeStyles((theme) => ({
	scriptPaper: {
		width: '90%',
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('xl')]: {
			minHeight: 'unset',
		},
		[theme.breakpoints.down('lg')]: {
			height: 'unset',
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
			width: 'unset',
			minHeight: 'unset',
			margin: '16px 0px',
		},
	},
	scriptTitle: {
		fontSize: '3.125rem !important',
		fontWeight: 'bold !important',
		position: 'absolute',
		bottom: 10,
		left: 25,
		textShadow: '0 0 0.2em #121213, 0 0 0.2em #0c0c0d',
		[theme.breakpoints.down('lg')]: {
			left: 25,
			fontSize: '2.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
			left: 17,
		},
	},
	gridDescriptionScriptCard: {
		backgroundColor: '#FFFFFF !important',
		padding: '1.5em 1.25rem',
		borderRadius: 16,
		[theme.breakpoints.down('lg')]: {
			padding: '1em 1.5em',
		},
		[theme.breakpoints.down('md')]: {
			height: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1em',
			borderTopRightRadius: 0,
			borderTopLeftRadius: 0,
		},
	},
	scriptText: {
		fontSize: '1.125rem !important',
		maxHeight: 200,
		minHeight: 200,
		overflowY: 'auto',
		paddingRight: 20,
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			maxHeight: 175,
			minHeight: 'unset !important',
		},
	},
	gridImage: {
		backgroundColor: '#000000 !important',
		borderTopRightRadius: 15,
		borderTopLeftRadius: 15,
		[theme.breakpoints.down('sm')]: {
			height: 125,
		},
	},
}));
