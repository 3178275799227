import React, { useEffect, useRef, useState } from 'react';
import { Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Header } from '../../../../Components/Header';
import StudentsFeedbackSection from '../../../../Components/Sections/StudentsFeedback';
import Footer from '../../../../Components/Footer';
import FirstSection from '../../../../Components/Sections/FirstSection';
import Faq from '../../../../Components/Sections/Faq';

import BackgroundSF from './assets/bg-sprint-final-revalida.png';
import SprintFinalRevalida from '../../Subscription/Revalida/assets/logo-sprint-final-revalida.svg';
import BagIcon from '../../../../Assets/Svg/Icons/bagIcon.svg';
import FlipCard from '../Arena/components/FlipCard';
import ProductCard from './components/ProductCard';
import DefaultButton from '../../../../Components/Buttons/DefaultButton';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PriceCardVersion3 from '../../../../Components/Cards/PriceCardVersion3';
import CounterDown from '../R1/components/CountDown';

// const SprintBenefitsBox = ({ classes, title, description, imageUrl }) => {
// 	return (
// 		<Grid
// 			container
// 			className={classes.root}
// 		>
// 			<Grid
// 				item
// 				xs={2}
// 				container
// 				justifyContent="center"
// 				alignContent="center"
// 				style={{ maxHeight: 124 }}
// 			>
// 				<img
// 					src={imageUrl}
// 					alt=""
// 				/>
// 			</Grid>
// 			<Grid
// 				item
// 				xs={10}
// 				container
// 				alignContent={'center'}
// 				className={classes.gridContainer}
// 			>
// 				<Grid
// 					item
// 					container
// 					alignContent="center"
// 				>
// 					<Typography
// 						className={`${classes.title} poppins`}
// 						color="secondary"
// 					>
// 						{title}
// 					</Typography>
// 				</Grid>
// 				<Grid
// 					item
// 					container
// 					alignContent="flex-start"
// 				>
// 					<Typography
// 						className={`${classes.descriptionText} poppins`}
// 						color="secondary"
// 					>
// 						{description}
// 					</Typography>
// 				</Grid>
// 			</Grid>
// 		</Grid>
// 	);
// };

const sprintBenefits = [
	{
		id: 1,
		img: require('./assets/icon-global.svg').default,
		title: ['100% Online'],
		description: [
			'Dois dias de programação',
			<strong
				style={{ margin: '0 auto' }}
				className="gradient-text"
			>
				intensa e muito dinâmica
			</strong>,
			'para entrar de vez na alma da prova prática do INEP.',
		],
	},
	{
		id: 2,
		img: require('./assets/icon-cyclic-revisions-white.svg').default,
		title: ['Revisões cíclicas automáticas'],
		description: [
			'Mais de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				20 estações para treinamento intenso
			</strong>,
			'; todas as estações simulam em detalhes a prova prática do INEP.',
		],
	},
	{
		id: 3,
		img: require('./assets/icon-dot-white.svg').default,
		title: ['Temas dos últimos anos da prova'],
		description: [
			'No presencial temos uma oportunidade adicional de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				aprender estações e aprender checklists
			</strong>,
			'.',
		],
	},
	{
		id: 4,
		img: require('./assets/icon-bell-white.svg').default,
		title: ['Alerta de necessidade de ', <br />, 'revisão '],
		description: [
			'Treinamento de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				habilidades específicas
			</strong>,
			' em pequenos grupos.',
		],
	},
	{
		id: 5,
		img: require('./assets/icon-teacher-orientation-white.svg').default,
		title: ['Orientações em vídeo do professor'],
		description: [
			'Orientações personalizadas do time Hardwork, com ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				professores e instrutores que já dominaram
			</strong>,
			' a prova prática do INEP.',
		],
	},
	{
		id: 6,
		img: require('./assets/icon-learning-at-different-stations-white.svg').default,
		title: ['Questões comentadas de todas', <br />, 'as provas do Revalida INEP'],
		description: [
			'O jogo da prova prática exige raciocínio rápido. ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				Uma informação pode mudar tudo...
			</strong>,
			'são os "gatilhos de estação". No PRESENCIAL, treinamos brincando!',
		],
	},
	{
		id: 7,
		img: require('./assets/icon-mock-test.svg').default,
		title: ['Crie seu simulado'],
		description: [
			'Momentos de integração com teus colegas, professores e instrutores. ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				Trocas riquíssimas
			</strong>,
			' durante os dois dias.',
		],
	},
	{
		id: 8,
		img: require('./assets/icon-direct-contact.svg').default,
		title: ['Contato direto com os professores'],
		description: [
			'Momentos de integração com teus colegas, professores e instrutores. ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				Trocas riquíssimas
			</strong>,
			' durante os dois dias.',
		],
	},
	// {
	// 	id: 9,
	// 	img: require('./assets/icon-discussion-group.svg').default,
	// 	title: ['Grupo de discussão com', <br />, 'professores e hardworkers'],
	// 	description: [
	// 		'Momentos de integração com teus colegas, professores e instrutores. ',
	// 		<strong
	// 			style={{ margin: '0 auto', display: 'inline' }}
	// 			className="gradient-text"
	// 		>
	// 			Trocas riquíssimas
	// 		</strong>,
	// 		' durante os dois dias.',
	// 	],
	// },
	{
		id: 10,
		img: require('./assets/icon-brain-engine-white.svg').default,
		title: ['Treinamento personalizado'],
		description: [
			'Momentos de integração com teus colegas, professores e instrutores. ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				Trocas riquíssimas
			</strong>,
			' durante os dois dias.',
		],
	},
	{
		id: 11,
		img: require('./assets/icon-head.svg').default,
		title: ['Orientação para recursos da prova'],
		description: [
			'Momentos de integração com teus colegas, professores e instrutores. ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				Trocas riquíssimas
			</strong>,
			' durante os dois dias.',
		],
	},
];

export default function SprintFinal2023() {
	const classes = useStyles();
	const theme = useTheme();
	const subscribe_card = useRef(null);

	const [countdownText, setCountdownText] = useState('');
	const [promotionalDate, setPromotionalDate] = useState();
	const [buttonText, setButtonText] = useState('');
	const [waitListVisibility, setWaitListVisibility] = useState(false);
	const handleOpenWaitList = () => {
		setWaitListVisibility(true);
	};
	const handleCloseWaitList = () => {
		setWaitListVisibility(false);
	};

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesDownLg = useMediaQuery(theme.breakpoints.down('lg'));

	const executeScroll = () => subscribe_card.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			<Helmet>
				<title>Sprint Final Revalida | Revisão Total para Prova INEP</title>
				<meta
					name="description"
					content="Sprint Final Revalida INEP, A revisão com foco total na prova do Revalida! A revolução em revisão para dominar a prova do INEP!"
				/>
			</Helmet>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="revalida"
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<FirstSection
					backgroundImageUrl={BackgroundSF}
					logoUrl={SprintFinalRevalida}
					type="green"
					buttonText={window.location.pathname === '/sprint-final-revalida' ? buttonText : 'Em breve'}
					executeScroll={executeScroll}
					firstTextPaper={[
						<Typography
							align="left"
							color="secondary"
							className={`${classes.firstText}`}
						>
							Revisão com foco total na
						</Typography>,
						<Typography
							variant="h1"
							className={`${classes.gradientText} revalida gradient-text roboto-condensed`}
						>
							prova do Revalida INEP 2025.1
						</Typography>,
						<Typography
							align="left"
							color="secondary"
							className={`${classes.firstSectionText} poppins`}
						>
							A revolução em revisão para
							<br />
							dominar a prova do INEP!
						</Typography>,
					]}
					buttonProps={{
						color: 'secondary',
						buttonLogoUrl:
							buttonText === 'Avise-me!'
								? null
								: require('../../../../Assets/Svg/Icons/bagIcon.svg').default,
					}}
				/>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						container
						className={classes.container}
						gap={matchesTablet ? 1 : 2}
					>
						<Grid
							item
							xs={12}
							container
							style={matchesMobile ? null : { padding: '2em 0' }}
						>
							<Grid
								item
								container
								alignContent={'center'}
								className={classes.firstGridText}
								style={matchesDownLg ? { order: 2 } : null}
							>
								<Grid
									item
									container
									justifyContent={matchesMobile ? 'flex-start' : 'flex-end'}
									alignContent="center"
									className="revalida"
								>
									<Typography
										align={matchesDownLg ? 'center' : 'right'}
										color="secondary"
										className={`${classes.subtitle} gradient-text roboto-condensed`}
									>
										Entramos na alma da banca.
									</Typography>
								</Grid>
								<Grid
									item
									container
									justifyContent={'flex-end'}
									alignContent="flex-start"
								>
									{matchesMobile ? (
										<Typography
											align="left"
											color="secondary"
											className={`${classes.text} poppins`}
										>
											A inteligência Hardwork te faz concentrar os esforços no que é importante
											para a prova do INEP.
											<br />
											<br />
											A gente faz isso explorando todas as provas do INEP nos mínimos detalhes:
											temas principais, alternativas erradas, questões anuladas...
											<br />
											<br />
											Enfim, o Sprint retrata a mente da banca!
										</Typography>
									) : (
										<Typography
											align={'right'}
											color="secondary"
											className={`${classes.text} poppins`}
										>
											{matchesDownLg ? (
												<>
													A inteligência Hardwork te faz concentrar os esforços
													<br />
													no que é importante para a prova do INEP.
													<br />
													<br />
													A gente faz isso explorando todas as provas do INEP
													<br />
													nos mínimos detalhes: temas principais,
													<br />
													alternativas erradas, questões anuladas...
													<br />
													<br />
													Enfim, o Sprint retrata a mente da banca!
												</>
											) : (
												<>
													A inteligência Hardwork te faz concentrar os esforços
													<br />
													no que é importante para a prova do INEP.
													<br />
													<br />
													A gente faz isso explorando todas as provas do INEP nos mínimos
													detalhes:
													<br />
													temas principais, alternativas erradas, questões anuladas...
													<br />
													<br />
													Enfim, o Sprint retrata a mente da banca!
												</>
											)}
										</Typography>
									)}
								</Grid>
							</Grid>
							<Grid
								item
								container
								justifyContent={'flex-end'}
								style={matchesDownLg ? { order: 1 } : { paddingRight: '2rem' }}
							>
								<img
									src={require('./assets/foco.png')}
									alt=""
								/>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							container
							alignContent={'center'}
							style={{ padding: '2em 0', marginBottom: matchesTablet ? 0 : '2rem' }}
						>
							<Grid
								item
								container
								justifyContent={matchesMobile ? 'center' : 'flex-start'}
								style={{
									paddingLeft: matchesDownLg ? 0 : '1rem',
								}}
							>
								<img
									src={require('./assets/estrategia.png')}
									alt=""
								/>
							</Grid>
							<Grid
								item
								container
								direction={'column'}
								className={classes.secondGridText}
							>
								<Grid
									item
									className="revalida"
								>
									<Typography
										align="left"
										color="secondary"
										className={`${classes.subtitle} gradient-text roboto-condensed`}
									>
										A revisão precisa ser intensa
										<br />e personalizada.
									</Typography>
								</Grid>
								<Grid item>
									<Typography
										align="left"
										color="secondary"
										className={`${classes.text} poppins`}
									>
										{matchesMobile ? (
											<>
												No Sprint a revisão é intensa e dinâmica, e sempre com o professor
												Hardwork mostrando como dominar os temas como a prova do INEP gosta.
												<br />
												<br />
												As mensagens importantes para a prova são apresentadas num ritmo
												progressivo, para que a gente chegue voando no dia da prova.
												<br />
												<br />E tudo isso num algoritmo personalizado, que identifica o que cada
												um mais precisa revisar.
											</>
										) : (
											<>
												No Sprint a revisão é intensa e dinâmica, e sempre com o professor
												Hardwork
												<br />
												mostrando como dominar os temas como a prova do INEP gosta.
												<br />
												<br />
												As mensagens importantes para a prova são apresentadas
												<br /> num ritmo progressivo, para que a gente chegue voando no dia da
												prova.
												<br />
												<br />E tudo isso num algoritmo personalizado,
												<br />
												que identifica o que cada um mais precisa revisar.
											</>
										)}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent={matchesTablet ? 'flex-start' : 'flex-end'}
							alignContent="center"
							style={{ padding: '2em 0' }}
						>
							<Grid
								item
								container
								justifyContent="flex-end"
								className={classes.thirdGridText}
								gap={1}
								style={matchesDownLg ? { order: 2 } : null}
							>
								<Grid
									item
									container
									justifyContent={'flex-end'}
									alignContent="flex-start"
									className="revalida"
								>
									{matchesMobile ? (
										<Typography
											align={matchesTablet ? 'left' : 'right'}
											color="secondary"
											className={`${classes.subtitle} gradient-text roboto-condensed`}
										>
											É hora de se viciar em estudar.
											<br />
											Vamos aprender com prazer.
										</Typography>
									) : (
										<Typography
											align="right"
											color="secondary"
											className={`${classes.subtitle} gradient-text roboto-condensed`}
										>
											É hora de se viciar em estudar.
											<br />
											Vamos aprender com prazer.
										</Typography>
									)}
								</Grid>
								<Grid
									item
									container
									justifyContent={'flex-end'}
									alignContent="flex-end"
								>
									{matchesMobile ? (
										<Typography
											align="left"
											color="secondary"
											className={`${classes.text} poppins`}
										>
											Para conquistar a tão sonhada revalidação de diploma precisamos de muita
											dedicação, muito hardwork.
											<br />
											<br />O papel do Sprint é facilitar toda a nossa caminhada, para que cada
											gota de suor seja muito produtiva.
										</Typography>
									) : (
										<Typography
											align="right"
											color="secondary"
											className={`${classes.text} poppins`}
										>
											Para conquistar a tão sonhada revalidação de diploma
											<br />
											precisamos de muita dedicação, muito hardwork.
											<br />
											<br />O papel do Sprint é facilitar toda a nossa caminhada,
											<br />
											para que cada gota de suor seja muito produtiva.
										</Typography>
									)}
								</Grid>
							</Grid>
							<Grid
								item
								container
								justifyContent="flex-end"
								style={matchesDownLg ? { order: 1 } : null}
							>
								<img
									src={require('./assets/suor.png')}
									alt=""
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container>
			<Grid
				container
				className="background-revalida"
			>
				<Grid
					container
					className={classes.backgroundWaves}
				>
					<Container
						fixed
						maxWidth="xl"
						disableGutters={true}
					>
						<Grid
							container
							alignContent="center"
							className="section"
						>
							<Grid
								item
								xs={12}
								container
								style={{ marginBottom: 16 }}
							>
								<Typography
									className={`${classes.subtitle} roboto-condensed`}
									color="secondary"
								>
									O que você terá no Sprint
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								container
								justifyContent={'center'}
								style={{ marginTop: matchesMobile ? 0 : '2rem' }}
							>
								{sprintBenefits.map((item, index) => {
									return (
										<Grid
											key={item.id}
											item
											xs={6}
											md={/*activeButtonId === 1 ? 4 : */ 3}
											container
											justifyContent={'center'}
											className={`${classes.gridFlipCard} revalida`}
										>
											<FlipCard
												key={item.id}
												image={item.img}
												title={item.title}
												description={item.description}
												color="secondary"
												mobile={matchesMobile}
												size={/*activeButtonId === 1 ? 4 : */ 3}
												classes={classes}
												noFlip
											/>
										</Grid>
									);
								})}
								<Grid
									item
									xs={12}
									container
									justifyContent={'center'}
								>
									<Divider
										variant="middle"
										className={classes.divider}
									/>
								</Grid>
							</Grid>
							<Grid
								container
								justifyContent={matchesTablet ? 'center' : 'space-evenly'}
								alignContent={'center'}
								flexWrap={matchesTablet ? 'wrap' : 'nowrap'}
								gap={2}
								style={{ margin: '2rem auto', padding: matchesTablet ? 0 : '0 2rem' }}
							>
								<ProductCard
									imgUrl={require('./assets/logo-banco-lives-revalida.svg').default}
									description={'Ganhe o pacote de lives com os temas mais relevantes do Revalida.'}
									classes={classes}
								/>
								<ProductCard
									imgUrl={require('./assets/icon-proof.svg').default}
									description={
										'A maior revolução Hardwork: Vamos te treinar para a prova DISCURSIVA!'
									}
									classes={classes}
								/>
								<ProductCard
									imgUrl={require('./assets/logo-multimidia.svg').default}
									description={'Ganhe acesso ao treinamento multimídia HWM'}
									classes={classes}
								/>
							</Grid>
						</Grid>
					</Container>
				</Grid>
			</Grid>
			<Grid
				container
				className="white-background"
			>
				<Grid
					container
					style={{ padding: '2vh 5%' }}
					className={classes.backgroundWaves}
				>
					<Container
						fixed
						maxWidth="xl"
						disableGutters={true}
					>
						<Grid
							container
							alignContent="center"
							justifyContent="center"
							className={`${matchesTablet ? null : 'section'} revalida-live-card`}
							style={{
								backgroundColor: 'black',
								borderRadius: 15,
								margin: matchesTablet ? '40px auto' : '80px auto',
							}}
							ref={subscribe_card}
						>
							<PriceCardVersion3
								group={'SPRINT FINAL REVALIDA 2025.1 - LISTA DE ESPERA'}
								idTurma={'1423'}
								logo={
									require('../../Subscription/Revalida/assets/logo-sprint-final-revalida.svg').default
								}
								iconBtn={BagIcon}
								bgImageUrl={require('./assets/bg-sf-revalida-price-card-dark.jpg')}
								color
								handleCloseWaitList={handleCloseWaitList}
								handleOpenWaitList={handleOpenWaitList}
								waitListVisibility={waitListVisibility}
								setPromotionalDate={setPromotionalDate}
								setButtonText={setButtonText}
								setCountdownText={setCountdownText}
							/>
						</Grid>
					</Container>
				</Grid>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					item
					xs={12}
					container
					direction={'column'}
					className={`revalida`}
					style={{ maxWidth: 1410, margin: '60px auto', padding: matchesMobile ? '0 1.5rem' : null }}
				>
					<Typography
						color="secondary"
						className={`${classes.subtitle2} gradient-text roboto-condensed`}
						style={{ marginBottom: 16 }}
					>
						Acesse o edital da edição anterior do Revalida (2024.2)
					</Typography>
					<DefaultButton
						variant="outlined"
						color="primary"
						className={`${classes.btn} btn white-btn-secondary`}
						component={Link}
						to={'/materiais/edital-revalida-2024-2'}
					>
						Acessar edital
					</DefaultButton>
				</Grid>
			</Container>
			<Grid
				container
				style={{
					backgroundColor: '#2A2A2A',
				}}
			>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					<Grid
						item
						xs={12}
						container
						// className="section"
						style={{ maxWidth: 1410, margin: '60px auto' }}
					>
						<Grid
							item
							xs={3}
							container
							justifyContent={'center'}
						>
							<img
								src={require('./assets/icon-gift-revalida.svg').default}
								alt=""
								style={matchesMobile ? { width: '80%' } : null}
							/>
						</Grid>
						<Grid
							item
							xs={9}
							container
							direction={'column'}
							justifyContent={'center'}
							className="revalida"
							style={matchesMobile ? { paddingLeft: '0.5rem' } : null}
						>
							<Typography
								className={`${classes.subtitleGift} gradient-text roboto-condensed`}
								color="secondary"
							>
								Alunos Trilha Revalida
							</Typography>
							<Typography
								align="left"
								color="secondary"
								className={`${classes.text} poppins`}
							>
								Alunos do Trilha Revalida 25.1 possuem acesso gratuito e antecipado ao Sprint Final
								Revalida 25.1
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</Grid>
			<StudentsFeedbackSection type="revalida" />
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Faq
					type="revalida"
					title="Como podemos te ajudar?"
				/>
			</Container>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					{matchesTablet ? null : <Footer />}
				</Container>
			</Container>
			{matchesTablet ? <Footer /> : null}
			{/* <CounterDown
				scrollToPayment={executeScroll}
				text={countdownText}
				date={promotionalDate}
				colorStartButton={'#0091a6'}
				colorEndButton={'#00c26e'}
				btnText={buttonText}
			/> */}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	firstText: {
		fontSize: '2.5rem !important',
		lineHeight: 'unset !important',
		fontWeight: '600 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
		},
	},
	gradientText: {
		fontSize: '4rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		margin: '0.5rem 0 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.5rem !important',
		},
	},
	firstSectionText: {
		fontSize: '1.9rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.05rem !important',
		},
	},
	text: {
		fontSize: '1.15rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	subtitle: {
		fontSize: '2.85rem !important',
		fontWeight: 'bold !important',
		marginBottom: '1rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.7rem !important',
			marginBottom: '0.5rem !important',
		},
	},
	subtitle2: {
		fontWeight: 'bold !important',
		fontSize: '3.75rem !important',
		lineHeight: '1 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
		},
	},
	gridContainer: {
		paddingLeft: '2.5em',
		height: 124,
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
			paddingLeft: '1.25em',
			marginBottom: '8px !important',
		},
	},
	title: {
		fontSize: '1.2rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.15rem !important',
		},
	},
	descriptionHeight: {
		height: 70,
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
		},
	},
	root: {
		height: '100%',
		width: '100%',
		padding: '1.5rem',
		display: 'flex',
		alignContent: 'flex-start',
		marginBottom: 30,
		[theme.breakpoints.down('sm')]: {
			padding: '1rem 0 !important',
			marginBottom: 'unset',
		},
	},
	descriptionText: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	backgroundWaves: {
		backgroundImage: `url(${process.env.PUBLIC_URL + '/Assets/Img/bgWave2.png)'})`,
		width: '100vw !important',
	},
	firstGridText: {
		height: 'fit-content',
		position: 'absolute',
		right: '37%',
		top: '19%',
		zIndex: 1,
		[theme.breakpoints.down('xl')]: {
			right: '44%',
			top: '26%',
		},
		[theme.breakpoints.down('lg')]: {
			right: 'unset',
			position: 'unset',
			top: 'unset',
		},
	},
	secondGridText: {
		height: 'fit-content',
		position: 'absolute',
		left: '40%',
		top: '10%',
		[theme.breakpoints.down('xl')]: {
			left: '45%',
			top: '20%',
		},
		[theme.breakpoints.down('lg')]: {
			right: 'unset',
			position: 'unset',
			top: 'unset',
		},
	},
	thirdGridText: {
		height: 'fit-content',
		position: 'absolute',
		top: '20%',
		right: '37%',
		zIndex: 1,
		[theme.breakpoints.down('xl')]: {
			right: '44%',
			top: '25%',
		},
		[theme.breakpoints.down('lg')]: {
			right: 'unset',
			position: 'unset',
			top: 'unset',
		},
	},
	textStyle: {
		fontSize: '1.2rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	gridFlipCard: {
		margin: '15px 0 !important',
		height: 260,
		padding: '0 1rem',
		[theme.breakpoints.down('sm')]: {
			padding: 'unset',
			height: 180,
		},
	},
	divider: {
		minHeight: 1,
		backgroundColor: '#2C2C2C',
		width: '100%',
		margin: '2rem 0 !important',
	},
	subtitleGift: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		marginBottom: '1rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			marginBottom: '0.5rem !important',
		},
	},
	btn: {
		width: 'fit-content',
		padding: '0.25rem 5rem !important',
		fontSize: '1.125rem !important',
		fontWeight: '600 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			marginBottom: '0.5rem !important',
			padding: '0.25rem 4rem !important',
		},
	},
}));
