import { useEffect, useState } from 'react';
import Footer from '../../../Components/Footer';
import { Header } from '../../../Components/Header';
import Form from '../../../Components/Legion/Form';
import { Alert, useMediaQuery, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Api from '../../../Services/Api';

//--Assets
import LogoHQ from './Assets/logo-hardworQ.svg';
import { trackPromise } from 'react-promise-tracker';

export default function PesquisaHQ() {
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesUpXl = useMediaQuery(theme.breakpoints.up('xl'));

	const [step, setStep] = useState(0);
	const [isSearching, setIsSearching] = useState(false);

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const email = queryParams.get('email');

	const [formData, setFormData] = useState({
		id_pesquisa: 1003,
		cod_respondente: '',
		nome: '-',
		email: email,
		telefone: '11111111111',
		respostas: ['', '', '', ''],
		complementos: ['', '', '', ''],
	});
	const handleNextStep = () => {
		answerSurvey(FormInfos[step].codigo, formData.respostas[step]);
	};

	const handleNavigation = (step) => {
		setStep(step);
	};

	useEffect(() => {
		getSurveyId();
	}, []);

	useEffect(() => {
		if (step > 3) {
			window.location.href = '/';
		}
	}, [step]);

	const FormInfos = [
		{
			titulo: 'Qual a tua faculdade?',
			codigo: 'P1',
			infos: [
				{
					type: 'search',
					placeholder: 'Buscar faculdade',
					onChange: (e) => {
						let frm = JSON.parse(JSON.stringify(formData));
						frm.respostas[0] = e.target.value;
						setFormData(frm);
					},
					onSelect: (opt) => {
						let frm = JSON.parse(JSON.stringify(formData));
						frm.respostas[0] = opt;
						setFormData(frm);
					},
					value: formData.respostas[0],
					// options: faculdades,
				},
			],
		},
		{
			titulo: 'Qual o teu ano de formatura?',
			codigo: 'P2',
			infos: [
				{
					type: 'radio',
					options: ['2025', '2026', '2027', 'Após 2027', 'Já sou formado'],
					onChange: (e) => {
						let frm = JSON.parse(JSON.stringify(formData));
						frm.respostas[1] = e.target.value;
						setFormData(frm);
					},
					value: formData.respostas[1],
				},
			],
		},
		{
			titulo: 'Como o HardworQ vai te ajudar?',
			codigo: 'P3',
			infos: [
				{
					type: 'radio',
					options: [
						'Preparação para prova de residência (R1)',
						'Preparação para prova de R+',
						'Estudos durante a faculdade',
						'Revalidação de diploma',
						'Atualização',
					],
					onChange: (e) => {
						let frm = JSON.parse(JSON.stringify(formData));
						frm.respostas[2] = e.target.value;
						setFormData(frm);
					},
					value: formData.respostas[2],
				},
			],
		},
		{
			titulo: 'Pesquisa recebida com sucesso!',
			codigo: 'P4',
			infos: [
				{
					type: 'none',
				},
			],
		},
	];

	// --- FUNÇÃO DAS DEMAIS REQUISIÇÕES
	const getSurveyId = async () => {
		const body = {
			id_pesquisa: 1003,
			//id_aluno: 0,
			nome: '-',
			email: email,
			telefone: '31914451445',
			respostas: [
				{
					pergunta: `PERG0_Nome`,
					resposta: '-',
				},
				{
					pergunta: `PERG0_Email`,
					resposta: email,
				},
				{
					pergunta: `PERG0_Telefone`,
					resposta: '11111111111',
				},
			],
		};

		trackPromise(Api.post('Pesquisas', body))
			.then((response) => {
				setFormData({ ...formData, cod_respondente: response });
				// setCanPass(true);
				// setStep(step + 1);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const answerSurvey = async (codPergunta, resposta) => {
		const body = {
			id_pesquisa: formData.id_pesquisa,
			cod_respondente: formData.cod_respondente,
			respostas: [
				{
					pergunta: codPergunta,
					resposta: resposta,
				},
			],
		};

		if (formData.complementos[step].length > 0) {
			body.respostas.push({
				pergunta: `${codPergunta}_C`,
				resposta: formData.complementos[step],
			});
		}

		if (step !== 3 && resposta.length === 0) {
			alert('Por favor informe seus dados');
			return;
		}

		trackPromise(Api.put('Pesquisas', body))
			.then(() => {
				setStep(step + 1);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<main>
			{!matchesMobile && <Header />}
			<section
				style={{
					backgroundImage: `url(${require('./Assets/bg-pesquisa.png')})`,
					backgroundSize: 'cover',
					width: '100%',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					padding: matchesMobile ? '120px 0 240px' : '162px 0 162px',
					gap: '24px',
					fontFamily: 'Manrope',
					color: 'white',
				}}
			>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<img
						src={LogoHQ}
						alt=""
						width={'80%'}
					/>
				</div>
				<div
					style={{
						width: matchesMobile ? '96%' : '55%',
						display: 'flex',
						justifyContent: 'center',
						textAlign: 'center',
					}}
				>
					<p
						style={{
							fontFamily: 'Manrope',
							fontSize: matchesMobile ? '16px' : '24px',
							lineHeight: matchesMobile ? '130%' : '31px',
							fontWeight: 600,
							color: 'white',
						}}
					>
						Queremos te conhecer melhor para proporcionarmos uma experiência personalizada.
					</p>
				</div>
				<div style={{ width: matchesMobile ? '95%' : '30%' }}>
					<Form
						titulo={FormInfos[step]?.titulo}
						infos={FormInfos[step]?.infos}
						onNextStep={handleNextStep}
						isLastStep={step === FormInfos.length - 1}
						step={step}
						mobile={matchesMobile}
						setIsSearching={setIsSearching}
						altPage={true}
					/>
				</div>
				<div
					style={{
						marginTop: 20,
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						gap: 5,
						zIndex: isSearching && -10,
					}}
				>
					{FormInfos.map((item, index) => (
						<svg
							viewBox="0 0 100 100"
							style={{ height: index === step ? 12 : 8, cursor: 'pointer' }}
							onClick={() => handleNavigation(index)}
						>
							<circle
								cx="50%"
								cy="50%"
								r="50"
								fill={index === step ? '#fff' : '#ccc'}
							/>
						</svg>
					))}
				</div>
			</section>
			<Footer />
		</main>
	);
}
