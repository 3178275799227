import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const InfoContainer = ({ imgUrl, title, text }) => {
	const classes = useStyles();
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<>
			<Grid
				container
				item
				xs={2}
				lg={1}
			>
				<img
					src={imgUrl}
					alt=""
					style={{ alignSelf: matchesMobile ? 'flex-start' : null }}
				/>
			</Grid>
			<Grid
				container
				item
				justifyContent={'space-around'}
				alignItems={'flex-start'}
				direction={'column'}
				xs={10}
				lg={11}
				className={classes.gridText}
			>
				<Typography
					color="secondary"
					variant="h1"
					className={`${classes.titleWhereWhen} poppins`}
				>
					{title}
				</Typography>
				<Typography
					variant={'body1'}
					className={`${classes.paragraphWhereWhen}`}
					color={'#D4D4D4'}
				>
					{text}
				</Typography>
			</Grid>
		</>
	);
};

export default function WhereWhenComponent({ contest }) {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Grid
			container
			item
			justifyContent={'flex-start'}
			alignItems={'center'}
			className={`${classes.containerParagraphWhereWhenInfos}`}
		>
			<Grid
				container
				className="poppins"
				rowGap={matchesMobile ? 3 : 3}
			>
				<InfoContainer
					imgUrl={require('../Assets/icon-ldl-experience-calendar-rev0.svg').default}
					title="Quando?"
					text={contest?.secondDescription}
				/>
				<InfoContainer
					imgUrl={
						contest?.modality === 'presencial'
							? require('../Assets/icon-ldl-experience-location-rev0.svg').default
							: require('../Assets/icon-ldl-online-location.svg').default
					}
					title="Onde?"
					text={contest?.firstDescription}
				/>
				{contest?.thirdDescription && (
					<InfoContainer
						imgUrl={require('../Assets/icon-ldl-experience-bus-rev0.svg').default}
						title="Conveniência"
						text={contest?.thirdDescription}
					/>
				)}
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme, linkActive) => ({
	titleWhereWhen: {
		fontSize: '2rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	paragraphWhereWhen: {
		fontSize: '1.4rem!important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1rem !important',
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			lineHeight: 'unset !important',
			marginTop: '0.5rem !important',
		},
	},
	containerParagraphWhereWhenInfos: {
		marginTop: '3rem !important',
		marginBottom: '5rem!important',
		[theme.breakpoints.down('sm')]: {
			marginTop: '2rem !important',
			marginBottom: '4rem!important',
		},
	},
	gridText: {
		paddingLeft: '0.75rem',
		[theme.breakpoints.down('xl')]: {
			paddingLeft: '1.5rem',
		},
	},
}));
