import { useState } from 'react';
// import { useRef } from 'react';
import { Button, Container, Divider, Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import DefaultButton from '../../../../../Components/Buttons/DefaultButton';

import BgTechGrey from '../../../../../Assets/SprintFinal/bg-tech-grey.png';
import PurchaseCardOnline20242 from './PurchaseCardOnline2024';

import Api from '../../../../../Services/Api';
import Swal from 'sweetalert2';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import bgTitleArena from '../assets/bg-arena-title.svg';
import imgInfo1 from '../assets/img-arena-info-1.png';
import imgInfo2 from '../assets/img-arena-info-2.png';
import imgInfo3 from '../assets/img-arena-info-3.png';
import FlipCard from './FlipCard';

const arenaOnlineBenefits = [
	'+200  checklists com orientação dos professores',
	'+200  estações com orientação dos professores',
	'+150  casos temáticos',
	'+30  estações em vídeos',
	'+20  vídeos de procedimentos',
	'Pratique estações com outros alunos',
	'Pratique estações com a Inteligência Artificial',
	'BÔNUS: Pratique individualmente estações com instrutores Hardwork',
	'Prioridade de inscrição no Arena Presencial',
];

const arenaPresencialBenefits = [
	'Treinamento presencial em São Paulo',
	'Treinamento intensivo em 2 dias',
	'+20 estações individuais no formato INEP',
	'8 estações temáticas em grupo no formato INEP',
	'Orientações personalizadas',
	'Treinamento de procedimentos importantes para a prova',
];

const arenaOnlinePresencialBenefits = [
	'Todos os benefícios presentes no Arena Online',
	'Treinamento presencial em São Paulo',
	'Treinamento intensivo em 2 dias',
	'+20 estações individuais no formato INEP',
	'8 estações temáticas em grupo no formato INEP',
	'Orientações personalizadas',
	'Treinamento de procedimentos importantes para a prova',
];

const checklists = [
	{
		icon: require('../assets/icon-green-doctor-arena.svg').default,
		text: 'O maior número de checklists da história',
	},
	{
		icon: require('../assets/icon-green-lamp-arena.svg').default,
		text: 'O maior número de estações da história',
	},
	{
		icon: require('../assets/icon-green-sheets-arena.svg').default,
		text: 'A maior preparação para prova prática do Revalida INEP',
	},
];

export function AviseMeModal({ agroupment }) {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const [isOpenModal, setIsOpenModal] = useState(false);
	const [preInscricao, setPreInscricao] = useState({
		agrupamento: `${agroupment}`,
		nome: '',
		email: '',
		telefone: '',
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});

	const handleCloseWaitList = () => {
		setIsOpenModal(false);
	};

	const handlePhone = (event) => {
		let input = event.target;
		input.value = phoneMask(input.value);
		setPreInscricao({
			...preInscricao,
			telefone: input.value,
		});
	};

	const phoneMask = (value) => {
		if (!value) return '';
		value = value.replace(/\D/g, '');
		value = value.replace(/(\d{2})(\d)/, '($1) $2');
		value = value.replace(/(\d)(\d{4})$/, '$1-$2');
		return value;
	};

	let sendPreInscricao = async function () {
		try {
			if (!preInscricao.nome) {
				alert('Ops... Por favor informe seu nome');
				return;
			}
			if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
				alert('Ops... Por favor preencha seu email');
				return;
			}
			if (!preInscricao.telefone || preInscricao.telefone.length < 11) {
				alert('Ops... Por favor preencha seu telefone');
				return;
			}

			await Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					Swal.fire({
						title: 'Massa! ',
						text: `${'Sua pré-inscrição está confirmada! Avisaremos assim que a nova turma estiver liberada.'}`,
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then(() => {
						setIsOpenModal(false);
					});
				}
			});
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<div
			style={{
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				width: '100%',
				height: 130,
				backgroundColor: '#FFFFFF26',
				borderRadius: 24,
			}}
		>
			{/* {agroupment === 'ARENA ONLINE - 2024.2 - LISTA ESPERA' && ( */}
			<p style={{ color: '#FFFFFF ', fontFamily: 'Poppins', fontSize: 26 }}>
				Abertura em {agroupment === 'ARENA ONLINE - 2024.2 - LISTA ESPERA' ? '17/09' : '26/09'}
			</p>
			{/* )} */}
			<DefaultButton
				onClick={() => setIsOpenModal(true)}
				variant="contained"
				color={'primary'}
				className={`${classes.selectButton} poppins`}
				style={{
					height: 47,
					width: '60%',
					backgroundColor: '#00AD58',
				}}
			>
				Avise-me
			</DefaultButton>
			{isOpenModal && (
				<form className="form-control">
					<Grid
						item
						container
						justifyContent={'center'}
						className={classes.containerWaitList}
						rowGap={1}
						style={{ visibility: isOpenModal ? 'visible' : 'hidden' }}
					>
						<Grid
							item
							container
							justifyContent="space-between"
						>
							<Typography
								color="primary"
								className={`${classes.textWaitList} poppins`}
							>
								Inscrever-se na Lista de Espera
							</Typography>
							<CancelRoundedIcon
								onClick={() => handleCloseWaitList()}
								className={classes.cancelIcon}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							container
							justifyContent={matchesTablet ? 'center' : 'flex-start'}
						>
							<TextField
								fullWidth
								type="string"
								name="nome"
								placeholder="Seu nome completo"
								required={true}
								InputProps={{
									className: `${classes.form} ${classes.formHeight}`,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								style={matchesTablet ? { width: '100%' } : { width: '97%' }}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										nome: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							container
							justifyContent={matchesTablet ? 'center' : 'flex-end'}
						>
							<TextField
								fullWidth
								type="tel"
								name="telefone"
								placeholder="Seu celular com DDD"
								required={true}
								pattern="[0-9]*"
								inputProps={{
									maxLength: 15,
								}}
								InputProps={{
									className: `${classes.form} ${classes.formHeight}`,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								style={matchesTablet ? { width: '100%' } : { width: '97%' }}
								onChange={(e) => handlePhone(e)}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent={matchesMobile ? 'center' : 'flex-start'}
						>
							<TextField
								fullWidth
								type="email"
								name="email"
								placeholder="Seu melhor e-mail"
								required={true}
								InputProps={{
									className: `${classes.form} ${classes.formHeight}`,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								style={{ width: '100%' }}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										email: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={10}
							lg={8}
							container
							className="residencia"
							style={{ height: 50, padding: '4px 0px' }}
						>
							<DefaultButton
								// type="submit"
								variant="outlined"
								color="secondary"
								className={`${classes.btnWaitList} btn`}
								onClick={() => sendPreInscricao()}
							>
								<img
									src={require('../../../../../Assets/Arena/icon-waitList.svg').default}
									alt=""
									style={{ width: 20 }}
								/>{' '}
								&nbsp; Confirmar inscrição
							</DefaultButton>
						</Grid>
					</Grid>
				</form>
			)}
		</div>
	);
}

const ArenaOptionCard = ({
	id,
	category,
	title,
	subtitle,
	benefits,
	activeButtonId,
	setIsContainerOpen,
	setActiveButtonId,
	executeScroll,
	matchesTablet,
	colorAlt,
	opening,
	setGambsActive,
	gambsActive,
	kind,
}) => {
	const classes = useStyles();

	// REGEX para destacar em verde números, dias e textos.
	const highlightRegex =
		/(\+\d+|\d+\s?dias|São Paulo|Prioridade de inscrição no Arena Presencial|Todos os benefícios presentes\s*no Arena Online|\b\d+\b)/gi;

	const HighlightedText = ({ text, classes }) => {
		const parts = text.split(highlightRegex);

		return (
			<Typography
				color={'secondary'}
				className={`${classes.textBenefits} poppins`}
			>
				{parts.map((part, index) =>
					highlightRegex.test(part) ? (
						<span
							key={index}
							style={{ color: '#09D785', fontSize: 18, fontWeight: 600 }}
						>
							{part}
						</span>
					) : part === 'BÔNUS: Pratique individualmente estações com instrutores Hardwork' ? (
						<span
							key={index}
							style={{ fontSize: 14 }}
						>
							<strong>BÔNUS:</strong> Pratique individualmente estações com instrutores Hardwork
						</span>
					) : (
						<span
							key={index}
							style={{ fontSize: 14 }}
						>
							{part}
						</span>
					)
				)}
			</Typography>
		);
	};

	const handleButtonClick = (buttonId) => {
		setActiveButtonId(buttonId);
		setIsContainerOpen(true);
		if (kind === 'exclusivo') {
			setGambsActive(true);
		} else {
			setGambsActive(false);
		}
	};

	const subtitleParts = subtitle?.split(' ');

	return (
		<Button
			variant="contained"
			color={'secondary'}
			className={`${classes.btn} poppins`}
			onClick={() => handleButtonClick(id)}
			style={{
				flexDirection: 'column',
				backgroundColor: colorAlt ? colorAlt : `#FFFFFF${id === '1374' ? '38' : '26'}`,
				filter: id === '1374,1407' && 'drop-shadow(0px 0px 0px rgba(9,215,215,1))',
				backgroundImage:
					id === '1374,1407' && 'linear-gradient(to bottom right, #ffffff15, #ffffff25, #ffffff05)',
				// scale: activeButtonId === id ? '1.025' : null,
				border: opening
					? null
					: activeButtonId === id && gambsActive
					? '1px solid #ffffff'
					: !gambsActive && activeButtonId === id && id !== '1407'
					? '1px solid #ffffff'
					: null,
			}}
		>
			<Grid
				item
				container
				direction={'column'}
				alignItems={'center'}
				justifyContent={'center'}
				rowGap={1.5}
				style={{
					padding: matchesTablet ? '1rem' : '1rem 0',
				}}
			>
				<Typography
					color="secondary"
					className={`${classes.titleBenefits} poppins`}
					align="center"
				>
					{title}
				</Typography>
				{category && (
					<Typography
						color={colorAlt ? '#09D785' : 'secondary'}
						className={`${classes.categoryText} poppins`}
						align="center"
					>
						{category}
					</Typography>
				)}
			</Grid>
			{subtitle && (
				<Grid
					item
					container
					xs={12}
					width={matchesTablet ? '100%' : 'fit-content'}
					padding={'0.25rem 1rem'}
					justifyContent="center"
					alignContent={'center'}
					border={'1px solid #09D785'}
					bgcolor={'#464646'}
					borderRadius={2}
					style={{ boxShadow: '0px 0px 12px 0px #09D785', flex: 0 }}
				>
					{colorAlt ? (
						<Typography
							align="center"
							color={'#CBCBCB'}
							className="poppins"
							fontWeight={500}
							fontSize={matchesTablet ? 18 : 14}
						>
							Inclua o <strong style={{ color: '#ffffff' }}>treinamento presencial</strong> na sua
							preparação
						</Typography>
					) : (
						<Typography
							align="center"
							color={'#CBCBCB'}
							className="poppins"
							fontWeight={500}
							fontSize={matchesTablet ? 18 : 14}
						>
							{subtitleParts[0]}
							<strong style={{ color: '#ffffff' }}> {subtitleParts[1]} </strong>
							{subtitleParts[2]}
						</Typography>
					)}
				</Grid>
			)}
			{benefits?.map((text, index) => {
				return (
					<Grid
						key={index}
						item
						container
						justifyContent="center"
						alignContent={'center'}
						alignItems={'center'}
						columnGap={1}
						wrap={'nowrap'}
						style={{
							flex: 1,
							borderBottom: index !== benefits?.length - 1 && '1px solid #FFFFFF50',
							padding: '0.75rem 0',
							marginTop: index === 0 ? '2rem' : '0',
						}}
					>
						{/* Componente para textos com destaque */}
						<HighlightedText
							text={text}
							classes={classes}
						/>
					</Grid>
				);
			})}
			<Grid
				item
				container
				alignItems={'end'}
				justifyContent={'center'}
				className={classes.gridSelectButton}
			>
				<DefaultButton
					variant={opening ? 'outlined' : 'contained'}
					color={
						id === '1407' || id === '1374,1407'
							? 'primary'
							: activeButtonId === id
							? 'secondary'
							: 'primary'
					}
					className={`${classes.selectButton} poppins`}
					onClick={() => executeScroll()}
					style={{
						color: opening ? '#09D785' : '',
						height: 47,
						width: 'fit-content',
						backgroundColor: opening
							? 'transparent'
							: // TIRAR ISSO QUANDO ABRIR PRESENCIAL PARA TODOS
							id === '1407' || id === '1374,1407'
							? '#ffffff33'
							: activeButtonId === id
							? '#ffffff'
							: '#00AD58',
					}}
				>
					{/* REGRA CONSTRUIDA ÁS 1H18 para resolver a gambiarra de dois cards presenciais com mesmo id, mas que representam coisas e comportamentos diferentes  */}
					{activeButtonId === id && id !== '1407'
						? 'Selecionado'
						: activeButtonId === id && kind === 'exclusivo' && gambsActive
						? 'Selecionado'
						: activeButtonId === id && kind === 'público' && !gambsActive
						? 'Selecionado'
						: 'Selecionar'}
				</DefaultButton>
			</Grid>
		</Button>
	);
};

const presencialTrainingExp = [
	{
		id: 1,
		img: require('../../../../../Assets/Arena/icon-intense-training.svg').default,
		title: ['2 dias de treinamento intenso'],
		description: [
			'Dois dias de programação',
			<strong
				style={{ margin: '0 auto' }}
				className="gradient-text"
			>
				intensa e muito dinâmica
			</strong>,
			'para entrar de vez na alma da prova prática do INEP.',
		],
	},
	{
		id: 2,
		img: require('../../../../../Assets/Arena/icon-stations-of-training.svg').default,
		title: ['+ de 20 estações individuais no novo formato INEP*'],
		description: [
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				20 estações individuais para treinamento
			</strong>,
			'; todas as estações simulam em detalhes a prova prática do INEP.',
			<br />,
			<br />,
			'*10 estações como aluno avaliado e 10 como examinador.',
		],
	},
	{
		id: 3,
		img: require('../../../../../Assets/Arena/icon-discussion-checklist-black.svg').default,
		title: ['Discussão de estações e checklists'],
		description: [
			'Discussão de estações e checklists ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				no formato INEP
			</strong>,
			'.',
		],
	},
	{
		id: 4,
		img: require('../../../../../Assets/Arena/icon-training-skills-black.svg').default,
		title: ['Oficinas para treinamento', <br />, 'de habilidades'],
		description: [
			'Treinamento de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				habilidades específicas
			</strong>,
			' em pequenos grupos.',
		],
	},
	{
		id: 5,
		img: require('../../../../../Assets/Arena/icon-personal-guidelines-black.svg').default,
		title: ['Orientações personalizadas'],
		description: [
			'Orientações personalizadas do time Hardwork, com ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				professores e instrutores que já dominaram
			</strong>,
			' a prova prática do INEP.',
		],
	},
	{
		id: 6,
		img: require('../../../../../Assets/Arena/icon-presencial-training-black.svg').default,
		title: ['Treinamento de gatilhos de', <br />, 'prova prática'],
		description: [
			'O jogo da prova prática exige raciocínio rápido. ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				Uma informação pode mudar tudo...
			</strong>,
			'são os "gatilhos de estação". No PRESENCIAL, treinamos brincando!',
		],
	},
	{
		id: 7,
		img: require('../../../../../Assets/Arena/icon-food-black.svg').default,
		title: ['Café da manhã, almoço ', <br />, 'e lanche'],
		description: [
			'Momentos de integração com teus colegas, professores e instrutores. ',
			<br />,
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				A gente cuida de ti integralmente
			</strong>,
			'.',
		],
	},
];

const onlineTraining = [
	{
		id: 1,
		img: require('../../../../../Assets/Arena/icon-60-stations-white.svg').default,
		title: ['+ de 200 estações', <br />, 'simuladas'],
		description: [
			'Várias estações que ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				simulam a prova prática
			</strong>,
			' do INEP. Algumas no formato interativo, outras no modelo de vídeo. Todas com orientação do professor.',
		],
	},
	{
		id: 2,
		img: require('../../../../../Assets/Arena/icon-discussions-by-teachers-white.svg').default,
		title: ['Discussões de estações ', <br />, 'pelos professores'],
		description: [
			'Entre na alma de cada estação, saiba exatamente ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				o que é esperado da banca
			</strong>,
			' para diversas situações, revise checklists e assimile conceitos importantes.',
		],
	},
	{
		id: 3,
		img: require('../../../../../Assets/Arena/icon-learn-white.svg').default,
		title: ['Aprenda avaliando', <br />, 'outros alunos'],
		description: [
			'Entre na cabeça do examinador, ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				veja pelo ponto de vista dele
			</strong>,
			' o que é esperado em cada situação e avalie o desempenho de outro aluno.',
		],
	},
	{
		id: 4,
		img: require('../../../../../Assets/Arena/icon-learning-at-different-stations-white.svg').default,
		title: ['Aprenda em estações', <br />, 'interativas'],
		description: [
			'Interaja com a plataforma para ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				treinar a busca de elementos numa estação
			</strong>,
			'. É a hora de aprender a fazer as perguntas.',
		],
	},
	{
		id: 5,
		img: require('../../../../../Assets/Arena/icon-lung-white.svg').default,
		title: ['+ de 150 casos para', <br />, 'aprendizado teórico'],
		description: [
			'É fundamental ter conhecimento teórico na prova prática! No ARENA nós fazemos isso de forma diferente: ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				mais de 150 casos para revisar os temas
			</strong>,
			' que podem aparecer na prova prática do INEP.',
		],
	},
	{
		id: 6,
		img: require('../../../../../Assets/Arena/icon-teacher-orientation-white.svg').default,
		title: ['Para cada caso, uma orientação ', <br />, 'em vídeo do professor'],
		description: [
			'Os casos temáticos trazem consigo ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				orientações do professor em vídeo
			</strong>,
			' sobre o tema.',
		],
	},
	{
		id: 7,
		img: require('../../../../../Assets/Arena/icon-dm-teachers-white.svg').default,
		title: ['Contato direto', <br />, 'com o professor'],
		description: [
			'É isso mesmo, tenha acesso aos ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				contatos de todos os professores envolvidos no projeto
			</strong>,
			'. E-mail e Instagram, para tirar suas dúvidas e compartilhar experiências.',
		],
	},
	{
		id: 8,
		img: require('../../../../../Assets/Arena/icon-exam-resources-white.svg').default,
		title: ['Orientações para', <br />, 'recursos de prova'],
		description: [
			'Obtenha apoio dos professores Hardwork para ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				avaliar a possibilidade de recursos
			</strong>,
			' para suas estações da prova prática',
		],
	},
];

export default function ToggleContainerPro({ arena_card, executeScroll }) {
	const theme = useTheme();
	const classes = useStyles();

	const [isContainerOpen, setIsContainerOpen] = useState(false);
	const [activeButtonId, setActiveButtonId] = useState('1374');
	const [gambsActive, setGambsActive] = useState(false);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<>
			<Grid
				container
				className={`${!isContainerOpen ? classes.containerBackground : null}`}
			>
				<Grid
					container
					className="section"
					style={{ margin: '0 auto' }}
				>
					<Grid
						item
						container
						className={`${classes.mainContainer} ${isContainerOpen ? classes.containerBackground : null}`}
						gap={matchesMobile ? 1 : 4}
					>
						{checklists.map((elm, index) => {
							return (
								<Grid
									key={index}
									item
									container
									justifyContent={'space-between'}
									alignItems={'center'}
									wrap="nowrap"
									style={{
										border: '2px solid #09D7D7',
										padding: '0.5rem',
										borderRadius: 20,
										marginBottom:
											index === checklists.length - 1 ? (matchesTablet ? '3rem' : '5rem') : null,
										marginLeft: matchesTablet ? '0.5rem' : null,
									}}
								>
									<Grid
										item
										container
										alignItems={'center'}
										wrap="nowrap"
									>
										<img
											src={elm.icon}
											alt=""
											className={classes.icon}
										/>
										<Typography
											color="secondary"
											className={`${classes.textChecklist} poppins`}
										>
											{elm.text}
										</Typography>
									</Grid>
									<img
										src={require('../assets/check-animation.gif')}
										alt=""
										className={classes.checkGif}
									/>
								</Grid>
							);
						})}
						{/* <Grid
							item
							container
							style={matchesMobile ? { marginBottom: '1rem' } : null}
						>
							<Typography
								align={matchesMobile ? 'center' : 'left'}
								color="#09D785"
								className={`${classes.optionTitle} roboto-condensed`}
							>
								QUAL O MODELO DE ARENA É IDEAL PRA TI?
							</Typography>
						</Grid> */}
						{/* <Grid
							item
							xs={12}
							container
							alignContent={'flex-start'}
							justifyContent={'space-around'}
							rowGap={matchesTablet ? 3 : 0}
						>
							<Grid
								item
								
								xs={12}
								lg={2.875}
								container
								justifyContent={'flex-start'}
								alignContent={'center'}
							>
								
								<ArenaOptionCard
									key={'1374'}
									id={'1374'}
									title={'Arena Online'}
									subtitle={'Acesso EXCLUSIVAMENTE Online'}
									benefits={arenaOnlineBenefits}
									activeButtonId={activeButtonId}
									setActiveButtonId={setActiveButtonId}
									executeScroll={executeScroll}
									matchesTablet={matchesTablet}
									setIsContainerOpen={setIsContainerOpen}
									setGambsActive={setGambsActive}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								lg={2.875}
								container
								justifyContent={'flex-start'}
								alignContent={'center'}
							>
								
								<ArenaOptionCard
									kind={'exclusivo'}
									category={['Exclusivo para alunos', <br />, 'Arena Online 24.2']}
									colorAlt={'#80FFCC33'}
									key={'1407'}
									id={'1407'}
									title={'Arena Presencial'}
									subtitle={'Inclua o treinamento presencial na sua preparação'}
									benefits={arenaPresencialBenefits}
									activeButtonId={activeButtonId}
									setActiveButtonId={setActiveButtonId}
									executeScroll={executeScroll}
									matchesTablet={matchesTablet}
									setIsContainerOpen={setIsContainerOpen}
									setGambsActive={setGambsActive}
									gambsActive={gambsActive}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								lg={2.875}
								container
								justifyContent={'flex-start'}
								alignContent={'center'}
							>
								
								<ArenaOptionCard
									kind={'público'}
									category={'Público geral'}
									key={'1407'}
									id={'1407'}
									title={'Arena Presencial'}
									subtitle={'Acesso EXCLUSIVAMENTE Presencial'}
									benefits={arenaPresencialBenefits}
									activeButtonId={activeButtonId}
									setActiveButtonId={setActiveButtonId}
									executeScroll={executeScroll}
									matchesTablet={matchesTablet}
									setIsContainerOpen={setIsContainerOpen}
									opening={'Abertura às 17:00h'}
									setGambsActive={setGambsActive}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								lg={2.875}
								container
								justifyContent={'flex-start'}
								alignContent={'center'}
							>
								
								<ArenaOptionCard
									category={'Público geral'}
									key={'1374,1407'}
									id={'1374,1407'}
									title={['Arena Online', <br />, '+ Presencial']}
									benefits={arenaOnlinePresencialBenefits}
									activeButtonId={activeButtonId}
									setActiveButtonId={setActiveButtonId}
									executeScroll={executeScroll}
									matchesTablet={matchesTablet}
									setIsContainerOpen={setIsContainerOpen}
									opening={'Abertura às 17:00h'}
									setGambsActive={setGambsActive}
								/>
							</Grid>
						</Grid> */}
					</Grid>
					<Grid
						item
						container
						className={classes.containerBackgroundColor}
					>
						<Grid
							item
							xs={12}
							container
							style={matchesMobile ? { marginBottom: 16, paddingLeft: '1em' } : { left: '2em' }}
							className="revalida"
						>
							<Typography
								className={`${classes.subtitle1} roboto-condensed gradient-text`}
								color="primary"
							>
								{activeButtonId === '1374,1407'
									? 'No treinamento PRESENCIAL tu receberás:'
									: activeButtonId === '1407'
									? 'No treinamento PRESENCIAL tu receberás:'
									: 'No treinamento online tu receberás:'}
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent={'center'}
							style={{ marginTop: matchesMobile ? 0 : '2rem', paddingBottom: '2rem' }}
						>
							{activeButtonId === '1374,1407' ? (
								<>
									{presencialTrainingExp.map((item, index) => {
										return (
											<Grid
												key={item.id}
												item
												xs={6}
												md={/*activeButtonId === 1 ? 4 : */ 3}
												container
												justifyContent={'center'}
												className={`${classes.gridFlipCard} revalida`}
											>
												<FlipCard
													image={item.img}
													title={item.title}
													description={item.description}
													color="secondary"
													mobile={matchesMobile}
													size={/*activeButtonId === 1 ? 4 : */ 3}
													classes={classes}
												/>
											</Grid>
										);
									})}
									<Grid
										item
										xs={12}
										container
										justifyContent={'center'}
									>
										<Divider
											variant="middle"
											className={classes.divider}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										container
										style={
											matchesMobile
												? { marginBottom: 16, paddingLeft: '1em' }
												: { left: '2em', marginBottom: '2rem' }
										}
										className="revalida"
									>
										<Typography
											className={`${classes.subtitle1} roboto-condensed gradient-text`}
											color="primary"
										>
											E ainda, tu também receberás no treinamento online:
										</Typography>
									</Grid>
									{!matchesTablet ? (
										<>
											{' '}
											{onlineTraining.map((item, index) => {
												return (
													<Grid
														key={index}
														item
														xs={6}
														md={3}
														container
														justifyContent={'center'}
														className={`${classes.containerFlipCard} revalida`}
													>
														<FlipCard
															image={item.img}
															title={item.title}
															description={item.description}
															color="secondary"
															mobile={matchesMobile}
															size={3}
															classes={classes}
														/>
													</Grid>
												);
											})}{' '}
										</>
									) : (
										<>
											{onlineTraining.map((item, index) => {
												return (
													<Grid
														key={index}
														item
														xs={6}
														md={3}
														container
														justifyContent={'center'}
														className={`${classes.containerFlipCard} revalida`}
													>
														<FlipCard
															image={item.img}
															title={item.title}
															description={item.description}
															color="secondary"
															mobile={matchesMobile}
															size={3}
															classes={classes}
														/>
													</Grid>
												);
											})}{' '}
										</>
									)}
								</>
							) : activeButtonId === '1407' ? (
								presencialTrainingExp.map((item, index) => {
									return (
										<Grid
											key={item.id}
											item
											xs={6}
											md={/*activeButtonId === 1 ? 4 : */ 3}
											container
											justifyContent={'center'}
											className={`${classes.gridFlipCard} revalida`}
										>
											<FlipCard
												image={item.img}
												title={item.title}
												description={item.description}
												color="secondary"
												mobile={matchesMobile}
												size={/*activeButtonId === 1 ? 4 : */ 3}
												classes={classes}
											/>
										</Grid>
									);
								})
							) : (
								<>
									{!matchesTablet ? (
										<>
											{' '}
											{onlineTraining.map((item, index) => {
												return (
													<Grid
														key={index}
														item
														xs={6}
														md={3}
														container
														justifyContent={'center'}
														className={`${classes.containerFlipCard} revalida`}
													>
														<FlipCard
															image={item.img}
															title={item.title}
															description={item.description}
															color="secondary"
															mobile={matchesMobile}
															size={3}
															classes={classes}
														/>
													</Grid>
												);
											})}{' '}
										</>
									) : (
										<>
											{onlineTraining.map((item, index) => {
												return (
													<Grid
														key={index}
														item
														xs={6}
														md={3}
														container
														justifyContent={'center'}
														className={`${classes.containerFlipCard} revalida`}
													>
														<FlipCard
															image={item.img}
															title={item.title}
															description={item.description}
															color="secondary"
															mobile={matchesMobile}
															size={3}
															classes={classes}
														/>
													</Grid>
												);
											})}{' '}
										</>
									)}
								</>
							)}
						</Grid>
					</Grid>
				</Grid>
				{!activeButtonId && (
					<Grid
						item
						container
						justifyContent={'center'}
						alignContent={'center'}
						style={{ padding: matchesTablet && '0 1rem' }}
					>
						<Typography
							color="secondary"
							align="center"
							className="poppins"
							style={{ fontStyle: 'italic', fontSize: matchesTablet ? '0.8rem' : '1.2rem' }}
						>
							Selecione um modelo de Arena para ver mais detalhes...
						</Typography>
					</Grid>
				)}
			</Grid>
			{activeButtonId !== '1407' && (
				<>
					<section
						className="section"
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: matchesTablet ? 'column' : 'row',
							justifyContent: 'space-between',
							marginTop: 100,
						}}
					>
						<div style={{ width: '100%' }}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									padding: '0 30px',
									width: '100%',
									height: 80,
									backgroundImage: `url(${bgTitleArena})`,
									backgroundSize: 'cover',
									border: '2px solid #09D7D7',
									borderRadius: 20,
									boxShadow: '0px 0px 12px 0px #09D7D7',
								}}
							>
								<h1
									style={{
										fontFamily: 'Poppins',
										color: '#FFFFFF',
										fontSize: matchesTablet ? 28 : 49,
									}}
								>
									ARENA INTERATIVO
								</h1>
							</div>
							<div style={{ width: '100%', marginTop: 20 }}>
								<p style={{ fontSize: matchesTablet ? 16 : 21, color: '#FFFFFF' }}>
									Pratique estações com a Inteligência Artificial do Hardwork.
								</p>
								<p style={{ fontSize: matchesTablet ? 16 : 21, color: '#FFFFFF', marginTop: 30 }}>
									São mais de 50 estações com casos clínicos e checklists, finalizadas com vídeos de
									professores com orientações da estação.
								</p>
							</div>
						</div>
						<div
							style={{
								width: '100%',
								height: 300,
								backgroundImage: `url(${imgInfo1})`,
								backgroundRepeat: 'no-repeat',
								backgroundSize: 'contain',
								backgroundPosition: 'right',
							}}
						></div>
					</section>

					<section
						className="section"
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'space-between',
							flexDirection: matchesTablet ? 'column' : 'row',
						}}
					>
						{!matchesTablet && (
							<div
								style={{
									width: '100%',
									height: 300,
									backgroundImage: `url(${imgInfo2})`,
									backgroundRepeat: 'no-repeat',
									backgroundSize: 'contain',
								}}
							></div>
						)}
						<div style={{ width: '100%' }}>
							<div style={{ width: '100%' }}>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'end',
										padding: '0 30px',
										width: '100%',
										height: 80,
										backgroundImage: `url(${bgTitleArena})`,
										backgroundSize: 'cover',
										border: '2px solid #09D7D7',
										borderRadius: 20,
										boxShadow: '0px 0px 12px 0px #09D7D7',
									}}
								>
									<h1
										style={{
											fontFamily: 'Poppins',
											color: '#FFFFFF',
											fontSize: matchesTablet ? 28 : 49,
											textWrap: matchesTablet ? 'wrap' : 'nowrap',
										}}
									>
										ESTAÇÃO COM AMIGO
									</h1>
								</div>
								<div style={{ width: '100%', marginTop: 20 }}>
									<p
										style={{
											fontSize: matchesTablet ? 16 : 21,
											color: '#FFFFFF',
											textAlign: matchesTablet ? 'start' : 'end',
											whiteSpace: matchesTablet ? 'normal' : 'pre-wrap',
										}}
									>
										{`Conecte-se com outros alunos através de chamadas\n de vídeo e treine com mais de 200 estações com\n casos clínicos e checklists.`}
									</p>
									<p
										style={{
											fontSize: matchesTablet ? 16 : 21,
											color: '#FFFFFF',
											marginTop: 30,
											textAlign: matchesTablet ? 'start' : 'end',
											whiteSpace: matchesTablet ? 'normal' : 'pre-wrap',
										}}
									>
										{`No final de cada caso, um vídeo explicativo do
                                    professor, com orientações da estação.`}
									</p>
								</div>
							</div>
						</div>
						{matchesTablet && (
							<div
								style={{
									width: '100%',
									height: 300,
									backgroundImage: `url(${imgInfo2})`,
									backgroundRepeat: 'no-repeat',
									backgroundSize: 'contain',
									marginTop: 30,
								}}
							></div>
						)}
					</section>
					<section
						className="section"
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'space-between',
							marginBottom: '40px',
							flexDirection: matchesTablet ? 'column' : 'row',
						}}
					>
						<div style={{ width: '100%' }}>
							<div
								style={{
									display: 'flex',
									padding: '0 30px',
									width: '100%',
									alignItems: 'center',
									height: 80,
									backgroundImage: `url(${bgTitleArena})`,
									backgroundSize: 'cover',
									border: '2px solid #09D7D7',
									borderRadius: 20,
									boxShadow: '0px 0px 12px 0px #09D7D7',
								}}
							>
								<h1
									style={{
										fontFamily: 'Poppins',
										color: '#FFFFFF',
										fontSize: matchesTablet ? 28 : 49,
										textWrap: matchesTablet ? 'wrap' : 'nowrap',
									}}
								>
									ESTAÇÃO COM INSTRUTOR
								</h1>
							</div>
							<div style={{ width: '100%', marginTop: 20 }}>
								<p style={{ fontSize: matchesTablet ? 16 : 21, color: '#FFFFFF' }}>
									Simule a prova com nosso time de instrutores.
								</p>
								<p style={{ fontSize: matchesTablet ? 16 : 21, color: '#FFFFFF', marginTop: 30 }}>
									Faz toda a diferença ter a vivência real de uma estação, recebendo orientações
									personalizadas de quem entende do assunto.
								</p>
							</div>
						</div>
						<div
							style={{
								width: '100%',
								height: 300,
								backgroundImage: `url(${imgInfo3})`,
								backgroundRepeat: 'no-repeat',
								backgroundSize: 'contain',
								backgroundPosition: 'right',
							}}
						></div>
					</section>
					{/* <section
						className="section"
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'space-between',
							marginBottom: matchesTablet ? '40px' : '220px',
							flexDirection: matchesTablet ? 'column' : 'row',
						}}
					>
						{!matchesTablet && (
							<div
								style={{
									width: '100%',
									height: 300,
									backgroundImage: `url(${imgInfo2})`,
									backgroundRepeat: 'no-repeat',
									backgroundSize: 'contain',
								}}
							></div>
						)}
						<div style={{ width: '100%' }}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'end',
									alignItems: 'center',
									padding: '0 30px',
									width: '100%',
									height: 80,
									backgroundImage: `url(${bgTitleArena})`,
									backgroundSize: 'cover',
									border: '2px solid #09D7D7',
									borderRadius: 20,
									boxShadow: '0px 0px 12px 0px #09D7D7',
								}}
							>
								<h1
									style={{
										fontFamily: 'Poppins',
										color: '#FFFFFF',
										fontSize: matchesTablet ? 21 : 34,
										textWrap: matchesTablet ? 'wrap' : 'nowrap',
										textTransform: 'uppercase',
									}}
								>
									Live das Lives de Prova prática
								</h1>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: 20 }}>
								<p
									style={{
										fontSize: matchesTablet ? 16 : 21,
										color: '#FFFFFF',
										textAlign: matchesTablet ? 'start' : 'end',
										whiteSpace: matchesTablet ? 'normal' : 'pre-wrap',
									}}
								>
									{`Lives com dicas de preparação para a prova, instrutores contando experiência e dicas de professores Hardwork.`}
								</p>
							</div>
						</div>
						{matchesTablet && (
							<div
								style={{
									width: '100%',
									height: 300,
									backgroundImage: `url(${imgInfo2})`,
									backgroundRepeat: 'no-repeat',
									backgroundSize: 'contain',
									marginTop: 30,
								}}
							></div>
						)}
					</section> */}
				</>
			)}

			<Grid container>
				<Grid
					container
					className={classes.backgroundWaves}
				>
					<Grid
						container
						className="section"
						style={{ marginBottom: matchesMobile ? 20 : null }}
					>
						<Container
							fixed
							maxWidth="xl"
							disableGutters={true}
						>
							{!activeButtonId ? (
								<Grid
									item
									container
									alignContent="center"
									justifyContent="center"
									className={classes.glassContainer}
								>
									<Grid
										item
										container
										justifyContent={'center'}
										style={matchesMobile ? { marginBottom: '1em' } : null}
									>
										<img
											src={require('../../../../../Assets/Arena/logo-lock.svg').default}
											alt=""
										/>
									</Grid>
									<Grid
										item
										container
										alignContent="center"
										justifyContent="center"
									>
										<Typography
											align="center"
											color="secondary"
											className={`${classes.optionTitle} roboto-condensed`}
										>
											Selecione acima de qual Arena tu vais participar para visualizar os valores
											e fazer inscrição.
										</Typography>
									</Grid>
								</Grid>
							) : null}
							<div ref={arena_card}>
								<PurchaseCardOnline20242
									gambiarra={gambsActive && 'Valores sob consulta'}
									activeButtonId={activeButtonId}
									idTurma={activeButtonId}
									// urlToRedirect="/arena-hardwork"
									confirmationMessage={
										'Sua inscrição na lista de espera está confirmada! Avisaremos caso surjam novas vagas.'
									}
								/>
								{/* {activeButtonId === '1374' && (
									<>
										<Grid
											item
											container
											flexDirection={matchesMobile ? 'column' : 'row'}
											justifyContent="space-between"
											alignItems="center"
											marginTop={'30px'}
											rowGap={3}
										>
											<Grid
												item
												xs={12}
												md={3.25}
												container
												alignContent={'center'}
												justifyContent={'flex-start'}
												rowGap={1}
												className="background-ldl-revalida-horizontal"
												width={'fit-content'}
												sx={{
													borderRadius: 2,
													padding: 3,
												}}
											>
												<Typography
													color={'#fff'}
													fontSize={matchesMobile ? 18 : 22}
													className="poppins"
												>
													{' '}
													Quero me inscrever!
												</Typography>
												<Typography
													color={'#fff'}
													fontSize={matchesMobile ? 18 : 22}
													fontWeight={'bold'}
													lineHeight={1.25}
													className="poppins"
												>
													{' '}
													Mas e se eu não passar para a segunda fase?
												</Typography>
											</Grid>
											<Grid
												item
												xs={12}
												md={8.45}
												container
												alignContent={'center'}
												justifyContent={'flex-start'}
												backgroundColor={'#292929'}
												sx={{
													borderRadius: 2,
													padding: matchesTablet ? '1.5rem' : '24px 24px 24px 40px',
													maxWidth: matchesMobile ? '100%' : '65%',
												}}
											>
												<Typography
													color={'#fff'}
													fontSize={matchesMobile ? 14 : 20}
													className="poppins"
													style={{ marginBottom: '0.5rem' }}
												>
													Calma! Caso não passe para a segunda fase, tu poderás escolher (até
													o dia 07/10) entre:
												</Typography>
												<Typography
													color={'#fff'}
													fontSize={matchesMobile ? 14 : 20}
													className="poppins"
												>
													• Usar o valor investido para migrar para o Trilha Revalida 25.1;
												</Typography>

												<Typography
													color={'#fff'}
													fontSize={matchesMobile ? 14 : 20}
													className="poppins"
												>
													• Continuar na rotina de estudos do Arena;
												</Typography>
											</Grid>
										</Grid>
									</>
								)} */}
							</div>
						</Container>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	backgroundWaves: {
		backgroundImage: `url(${BgTechGrey})`,
		width: '100vw !important',
	},
	mainContainer: {
		borderTopLeftRadius: 15,
		borderTopRightRadius: 15,
		padding: '6em 0 2.5em',
		[theme.breakpoints.down('xl')]: {
			padding: '4em 2.5em 6em',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1em 0 2em 0',
			height: 'unset',
		},
	},
	optionTitle: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		textShadow: '0 0 0.2em #121213, 0 0 0.2em #0c0c0d',
		alignSelf: 'flex-end',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.75rem !important',
			lineHeight: '1 !important',
		},
	},
	btn: {
		width: '100%',
		height: '100%',
		fontSize: '2rem !important',
		fontWeight: 'bold !important',
		borderRadius: '15px !important',
		padding: '1.75rem 1rem !important',
		textTransform: 'unset !important',
		transition: '0.2s ease-in-out !important',
		cursor: 'auto !important',
		[theme.breakpoints.up('md')]: {
			'&:hover': {
				scale: 1.02,
			},
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			padding: '1rem 0.5rem 2rem !important',
		},
	},
	subtitle1: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
			lineHeight: 'unset !important',
		},
	},
	containerBackgroundColor: {
		backgroundColor: '#33333380',
		margin: '0 auto 30px',
		borderRadius: 15,
		// maxWidth: '1410px !important',
		padding: '2vh 2.5% 3vh !important',
		[theme.breakpoints.down('sm')]: {
			padding: '3vh 2.5% 0 !important',
		},
	},
	divider: {
		minHeight: 1,
		backgroundColor: '#6D6D6D',
		width: '90%',
		margin: '2rem 0 !important',
	},
	glassContainer: {
		backdropFilter: 'blur(5px)',
		boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.37)',
		height: '100%',
		width: '100%',
		position: 'absolute',
		zIndex: 1,
		borderRadius: 15,
		[theme.breakpoints.down('sm')]: {
			padding: '0 1.5rem ',
		},
	},
	containerFlipCard: {
		margin: '15px 0 !important',
		height: 270,
		padding: '0 1rem ',
		[theme.breakpoints.down('sm')]: {
			padding: 'unset',
			height: 200,
		},
	},
	gridFlipCard: {
		margin: '15px 0 !important',
		height: 270,
		padding: '0 1rem',
		[theme.breakpoints.down('sm')]: {
			padding: 'unset',
			height: 180,
		},
	},
	selectButton: {
		fontSize: '1.25rem !important',
		fontWeight: 'bold !important',
		padding: '0.5rem 2.5rem !important',
	},
	gridSelectButton: {
		marginTop: '2rem !important',
		width: '100% !important',
	},
	disclaimerText: {
		fontSize: '0.8rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.6rem !important',
		},
	},
	disclaimerText2: {
		fontSize: '0.95rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.85rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7rem !important',
		},
	},
	textChecklist: {
		fontSize: '2rem !important',
		left: '-2rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
			left: '-1.5rem !important',
		},
	},
	checkGif: {
		width: '5rem',
		[theme.breakpoints.down('sm')]: {
			width: '3rem',
		},
	},
	icon: {
		left: '-3rem !important',
		[theme.breakpoints.down('sm')]: {
			width: '3rem',
			left: '-2rem !important',
		},
	},
	textBenefits: {
		fontSize: '0.95rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	textBenefitsGreen: {
		fontSize: '1.25rem !important',
		fontWeight: '700 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	titleBenefits: {
		fontSize: '2rem !important',
		fontWeight: 'bold !important',
		lineHeight: '1 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
		},
	},
	categoryText: {
		fontSize: '1.2rem !important',
		lineHeight: '1.25 !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.95rem !important',
		},
	},
	containerGridArenaInterativo: {
		margin: '0 auto 30px !important',
		borderRadius: 15,
		maxWidth: '1410px !important',
		padding: '2vh 2.5% 3vh',
		[theme.breakpoints.down('md')]: {
			padding: '2vh 1.25rem 3vh',
		},
	},
	textArenaInterativo: {
		fontSize: '1rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.9rem !important',
		},
	},
	gridTitleArenaBlock: {
		padding: '0.75rem 2rem',
		border: 'solid 4px #09D7D7',
		height: 'fit-content',
		borderRadius: 20,
		backgroundImage: `url(${require('../assets/bg-arena-cards.png')})`,
		backgroundSize: 'contain',
		boxShadow: '0px 0px 8px rgba(9, 215, 215, 1)',
		[theme.breakpoints.down('md')]: {
			padding: '0.5rem 1rem',
		},
	},
	arenaBlockTitle: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: '1.5 !important',
			fontSize: '1.75rem !important',
		},
	},
	gridArenaBlock: {
		paddingBottom: '10rem',
		[theme.breakpoints.down('md')]: {
			paddingBottom: '0',
		},
	},
	gridBackgroundImage: {
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		[theme.breakpoints.down('md')]: {
			height: 210,
		},
	},
	containerWaitList: {
		width: '440px !important',
		backgroundColor: '#d6cece',
		zIndex: 1,
		borderRadius: 15,
		padding: '0.5rem 1.25rem',
		boxShadow: '0px 0px 30px 0px #000000',
		border: '2px solid #000000',
		position: 'absolute',
		bottom: -40,
		left: -220,
		[theme.breakpoints.down('sm')]: {
			padding: '1.5rem 1.25rem',
			width: '350px !important',
			left: -180,
		},
	},
	textWaitList: {
		fontSize: '1.3rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.95rem !important',
		},
	},
	cancelIcon: {
		color: '#000000',
		right: '-15px',
		top: '-5px',
		'&:hover': {
			cursor: 'pointer',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'absolute',
			top: '-20px',
			right: '-18px',
		},
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: '#E1E1E1 !important',
		margin: '5px 0 !important',
	},
	formHeight: {
		maxHeight: '3em',
	},
	btnWaitList: {
		fontSize: '1rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		backgroundColor: '#00AB55 !important',
		border: '1px solid #707070 !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				minWidth: '72% !important',
				fontSize: '1.025rem !important',
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
}));
