import React from 'react';
import { Avatar, Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import FeedbackAvatarResidencia from '../../Assets/Extensivo/R1/2023/feedbackAvatarResidencia.svg';
// import FeedbackAvatarRevalida from '../../Assets/Extensivo/Revalida/feedbackAvatarRevalida.svg';
// import FeedbackAvatarClinicaMedica from '../../Assets/Extensivo/R3CM/avatarPurple.svg';
// import FeedbackAvatarCirurgiaGeral from '../../Assets/Extensivo/R3CG/feedbackAvatarCirurgiaGeral.svg';

const FeedbackCard = ({ nome, college, date, dep_texto, type }) => {
	const classes = useStyles();

	return (
		<Paper
			className={`${classes.root} rounded-card`}
			color={'#1D1D1D'}
			elevation={4}
		>
			<Grid
				container
				direction={'column'}
				wrap={'nowrap'}
				justifyContent={'space-between'}
				className={classes.gridHeightFull}
			>
				<Grid
					item
					xs={12}
					container
					justifyContent="center"
				>
					<Typography
						classes={{ root: classes.text }}
						color={'#FCFCFC'}
						className={`${classes.content} content-feedback-card poppins`}
						gutterBottom
					>
						{dep_texto}
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					container
					alignItems={'flex-end'}
				>
					<Grid
						item
						xs={9}
						container
						direction="column"
						justifyContent="center"
						className={`${
							type === 'residencia'
								? 'residencia'
								: type === 'extensivo'
								? 'residencia'
								: type === 'clinica medica' || type === 'Trilha R3'
								? 'clinica-medica'
								: 'revalida'
						}`}
					>
						<Typography
							className={`${classes.title} poppins`}
							color={'#FCFCFC'}
							gutterBottom
						>
							{`Dr. ${nome}`}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default FeedbackCard;

const useStyles = makeStyles((theme) => ({
	root: {
		height: 286,
		width: '100%',
		minWidth: 420,
		padding: '1.5rem',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		backgroundColor: '#1D1D1D !important',
		[theme.breakpoints.down('sm')]: {
			maxHeight: '240px !important',
			minWidth: '322px !important',
			padding: '1rem',
		},
	},
	title: {
		fontWeight: 'bold !important',
		lineHeight: '1.25em !important',
		marginBottom: '0px !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	info: {
		fontSize: '.85rem',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	text: {
		// color: theme.palette.secondary.grey,
		[theme.breakpoints.down('md')]: {
			fontSize: '0.85rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	blackText: {
		color: theme.palette.primary.main,
		alignSelf: 'flex-end',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	gridHeightFull: {
		height: '100%',
	},
	img: {
		width: '75px !important',
		height: '75px !important',
		[theme.breakpoints.down('sm')]: {
			width: '60px !important',
			height: '60px !important',
		},
	},
	expandedText: {
		maxHeight: 290,
		overflowY: 'auto',
		[theme.breakpoints.down('xl')]: {
			maxHeight: 180,
		},
		[theme.breakpoints.down('lg')]: {
			maxHeight: 170,
		},
		[theme.breakpoints.down('sm')]: {
			height: 240,
		},
	},
	content: {
		lineHeight: '1.35 !important',
		maxHeight: 178,
		minHeight: 178,
		overflowY: 'auto',
		paddingRight: 20,
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.15rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
			maxHeight: 144,
			minHeight: 144,
		},
	},
}));
