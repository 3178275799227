import React, { useState } from 'react';
import { Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DefaultButton from '../../../../../Components/Buttons/DefaultButton';
import PlayIconWhite from '../../../../../Assets/Svg/playIconBlackCard.svg';
// import DoubleArrow from '../../Assets/Svg/DoubleArrowSprint.svg';

const LearningPathCard = ({ title, description, type, url, color, bgColor, titleColor, descriptionColor }) => {
	const classes = useStyles();
	const theme = useTheme();

	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		console.log('runsss');

		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Paper
			className={`${classes.root}`}
			elevation={bgColor === 'transparent' ? 0 : 4}
			style={{
				padding: open ? '' : '1.5rem',
			}}
		>
			{open ? (
				<Grid
					container
					style={{ height: '100%' }}
				>
					<Grid
						item
						xs={12}
					>
						<DefaultButton
							variant="outlined"
							color="secondary"
							className={`${classes.btn}`}
							style={{
								position: 'absolute',
								zIndex: 2,
								right: 20,
								top: 24,
							}}
							onClick={handleClose}
						>
							<img
								src={PlayIconWhite}
								alt=""
								style={{ width: '100%' }}
							/>
						</DefaultButton>
						<iframe
							title={title}
							src={url}
							style={{
								border: 0,
								width: '100%',
								height: '100%',
								// aspectRatio: 16 / 9,
								borderRadius: open && 16,
							}}
						></iframe>
					</Grid>
				</Grid>
			) : (
				<>
					<Grid
						container
						justifyContent="center"
						alignContent="flex-start"
					>
						<Grid
							item
							xs={12}
							container
							className={`${
								titleColor === 'purple'
									? 'clinica-medica'
									: titleColor === 'cirurgia'
									? 'cirurgia-geral'
									: null
							} saiba-mais-grid-img`}
						>
							<Typography
								color="#FCFCFC"
								align="left"
								className={`${classes.titleText}`}
								fontFamily={'Bebas Neue'}
								fontSize={'3rem'}
								style={{ color: `${titleColor}` }}
							>
								{title}
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							className="grid-description-saiba-mais"
							style={{ marginTop: 15 }}
						>
							<Typography
								color={`${descriptionColor}`}
								align="left"
								className={`${classes.content} description-saiba-mais-card poppins`}
								style={url || matchesMobile ? { overflowY: 'auto' } : { overflowY: 'auto' }}
							>
								{description}
							</Typography>
						</Grid>
					</Grid>
					{/* DESCOMENTAR QUANDO HOUVER VÍDEOS */}
					{/* <Grid
						item
						xs={12}
						container
						justifyContent={'flex-end'}
						alignContent={'flex-end'}
					>
						{url && (
							<DefaultButton
								variant="outlined"
								color="secondary"
								className={`${classes.btn}`}
								onClick={handleClickOpen}
							>
								<img
									src={PlayIconWhite}
									alt=""
									style={{ width: '100%' }}
								/>
							</DefaultButton>
						)}
					</Grid> */}
				</>
			)}
		</Paper>
	);
};

const useStyles = makeStyles((theme, bgColor) => ({
	root: {
		color: 'unset !important',
		height: '622px',
		width: '352px',
		borderRadius: '15px !important',
		display: 'flex',
		flexDirection: 'column',
		border: '1px solid #959595',
		background: 'linear-gradient(to bottom right, #272727, #101010)',
		[theme.breakpoints.down('sm')]: {
			height: '506px !important',
			width: '300px !important',
		},
	},
	titleText: {
		lineHeight: '1 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
		},
	},
	content: {
		lineHeight: '1.35 !important',
		minHeight: 200,
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
			maxHeight: 200,
		},
	},
	btn: {
		minWidth: '40px !important',
		maxWidth: '40px !important',
		height: 40,
		borderRadius: '50% !important',
		color: '#fff !important',
		backgroundColor: '#0d0d0d !important',
		padding: '0 !important',
		// '&:hover': {
		// 	scale: 1.1,
		// 	backgroundColor: '#383333 !important',
		// },
		[theme.breakpoints.down('sm')]: {
			minHeight: 'unset !important',
			maxHeight: '3.5em !important',
			width: '150px',
			fontSize: '0.8rem !important',
		},
	},
	popup: {
		maxWidth: 'unset !important',
		minWidth: '90%',
	},
	dialogContent: {
		paddingBottom: '0 !important',
	},
	popupBtn: {
		backgroundColor: 'black !important',
	},
}));

export default LearningPathCard;
