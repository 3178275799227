import { Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import Faq from '../Utils/Faq';
import { makeStyles } from '@mui/styles';

export default function FaqSection({ type, title }) {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Grid
			container
			alignContent="center"
			justifyContent="center"
			className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
			style={
				window.location.pathname !== '/extensivo-r1'
					? matchesMobile || matchesTablet
						? { marginTop: 40 }
						: null
					: null
			}
		>
			<Grid
				item
				xs={12}
				container
				className={type ? type : 'residencia'}
			>
				<Typography
					color="secondary"
					className={`${classes.title} gradient-text roboto-condensed`}
				>
					F.A.Q.
				</Typography>
			</Grid>
			{matchesMobile ? null : (
				<Grid
					item
					xs={12}
					style={{ padding: '1.25em 0' }}
				>
					<Typography
						align="left"
						variant="h4"
						color="secondary"
						className={`${classes.text} poppins`}
					>
						{title}
					</Typography>
				</Grid>
			)}
			<Grid
				item
				xs={12}
				style={{ margin: '20px 0' }}
			>
				<Divider
					variant="middle"
					className={classes.divider}
				/>
			</Grid>
			<Grid
				item
				xs={12}
			>
				<Faq />
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '2.85em !important',
		lineHeight: '1 !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '2.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
		},
	},
	text: {
		fontSize: '1.324rem !important',
		lineHeight: '1.25 !important',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	divider: {
		margin: 'unset !important',
		backgroundColor: 'grey',
		minHeight: 1,
	},
}));
