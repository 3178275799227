import React from 'react';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { Grid } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

export default function RadarQuestions() {
	const [searchParams] = useSearchParams();

	const dataInt = searchParams
		.get('data')
		.split(',')
		.map((elm) => parseInt(elm));

	const chartLabels = ['CM', 'PED', 'MP', 'GO', 'CG'];
	const chartData = dataInt;

	const chartDataConfig = {
		labels: chartLabels.map((label, index) => [label, dataInt[index]]),
		datasets: [
			{
				data: chartData,
				label: 'Questões',
				backgroundColor: '#B2EEFE99',
				borderColor: '#8596FF',
				borderWidth: 3,
				pointBackgroundColor: '#13479F',
				pointBorderWidth: 5,
				pointBorderColor: '#13479F',
			},
		],
	};
	const chartOptions = {
		plugins: {
			legend: {
				display: false,
			},
		},
		scales: {
			r: {
				ticks: {
					display: false,
					color: '#13479F',
					font: {
						size: 10,
					},
					stepSize: 20,
				},
				pointLabels: {
					color: '#13479F',
					font: {
						size: 14,
						weight: 'bold',
						family: 'Poppins',
					},
				},
				angleLines: {
					color: '#707070',
				},
				grid: {
					color: '#707070',
				},
			},
		},
	};

	return (
		<Grid style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center' }}>
			<Grid>
				<Radar
					data={chartDataConfig}
					options={chartOptions}
				/>
			</Grid>
		</Grid>
	);
}
