import React from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';

import ExtensivoR12324Logo from '../../../../Assets/Extensivo/R1/2023-24/extensivoR12324Logo.svg';

import Form from '../../../../Components/Utils/Forms/PreRegistration';

export default function ExtensivoR12324Subscription() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						md={7}
						container
						style={{ paddingBottom: '1em' }}
						className="residencia"
					>
						<Typography
							className={`${classes.title} gradient-text roboto-condensed`}
							color="secondary"
						>
							A maior revolução da
							<br />
							preparação para provas
							<br />
							de residência
						</Typography>
						<img
							src={process.env.PUBLIC_URL + '/Assets/Img/plataformaExtensivo2022.png'}
							alt="Plataforma Hardwork"
							className={classes.imgExtensivo}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						container
						justifyContent="center"
					>
						<img
							src={ExtensivoR12324Logo}
							alt="Extensivo R1 2023/2024"
							style={{ marginBottom: 30 }}
						/>
						<Form
							group="Extensivo R1 2023-2024"
							title={['Cadastre-se para saber de todos', <br />, 'os detalhes de lançamento!']}
							titleButton="Confirmar Pré-inscrição"
							type="residencia"
						/>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '4.5rem !important',
		lineHeight: '1 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '3.2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
		},
	},
	text: {
		fontSize: '1.324rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	bottomSignInCardText: {
		fontSize: '1.15rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
	},
	divider: {
		margin: 'unset !important',
		backgroundColor: 'grey',
		minHeight: 1,
	},
	workHourTitle: {
		fontSize: '1.7em !important',
		lineHeight: 1.25,
		fontWeight: 'bold !important',
	},
	containerWorkHours: {
		padding: '2em 0 2em 2em',
		[theme.breakpoints.down('lg')]: {
			padding: 0,
		},
	},
	workHourPaper: {
		height: '100%',
		[theme.breakpoints.down('lg')]: {
			height: 'unset !important',
		},
	},
	imgExtensivo: {
		[theme.breakpoints.down('md')]: {
			margin: '40px 0 !important',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '0px !important',
		},
	},
}));
