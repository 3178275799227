import React from 'react';
import { Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Header } from '../../../../Components/Header';
import Footer from '../../../../Components/Footer';

export default function SubscriptionSouHardworkForm() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					className="section"
					style={matchesTablet ? { marginTop: 80 } : null}
				>
					<iframe
						src="https://forms.office.com/r/znk23zP8LA?embed=true"
						style={{ minHeight: matchesTablet ? '1500px' : '1300px' }}
						className={classes.iframeStyle}
					></iframe>
				</Grid>{' '}
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	iframeStyle: {
		width: '100%',
		frameborder: 0,
		// marginwidth"0",
		marginheight: '0',
		border: 'none',
		maxWidth: '100%',
		maxHeight: '100vh',
		borderRadius: 15,
		'&.-s-32': {
			marginBottom: 'unset !important',
		},
	},
}));
