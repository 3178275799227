import React, { useEffect, useState } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';

export default function RemainingVacancyBarRevalida({ numParticipants, qtd_limite }) {
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Grid
			item
			xs={12}
			container
			style={{
				height: matchesMobile ? 6 : 8,
				backgroundColor: '#9A9A9A',
				borderRadius: 8,
				maxWidth: '80%',
			}}
		>
			<Grid
				item
				xs={12}
				className="background-gradient-residencia-light-shadow-revalida"
				style={{
					height: '100%',
					maxWidth: `${(numParticipants / qtd_limite) * 100}%`,
					borderRadius: 15,
					boxShadow: '0px 0px 2px 1px #00c26e', //mudar a cor para revalida
				}}
			></Grid>
		</Grid>
	);
}
