import { Grid, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import Api from '../../../../../Services/Api';

export default function BoxInfoOqueTemMobile({ conteudos, turmaTrilha, logo }) {
	const theme = useTheme();
	const [turma, setTurma] = useState();
	const [lote1, setLot1] = useState();

	const currentDate = new Date();

	useEffect(() => {
		Api.get(`turmasabertas/${turmaTrilha}`).then((res) => {
			if (res) {
				setTurma(res[0]);
			}
			const activeLot = res[0]?.lotes?.find((lot, index) => {
				return lot?.id === res[0]?.id_lote_vigente;
			});

			if (activeLot) {
				setLot1([activeLot]);
			} else {
				const nextLot = res[0]?.lotes?.find((lot, index) => {
					const startDate = new Date(lot.data_inicio);

					return currentDate < startDate;
				});
				setLot1([nextLot]);
			}
		});
	}, [turmaTrilha]);

	return (
		<div
			style={{
				backgroundColor: '#040404',
				marginTop: '3rem',
				borderRadius: '16px',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<div
				style={{
					width: '100%',
					display: 'flex',
					justifyContent: 'space-between',
					padding: '2rem 1rem',
					position: 'sticky',
					top: 70,
					zIndex: 2,
					backgroundColor: '#040404',
					borderTopLeftRadius: 16,
					borderTopRightRadius: 16,
				}}
			>
				<div
					style={{
						width: '50%',
						display: 'flex',
						flexDirection: 'column',
						color: '#FCFCFC',
						justifyContent: 'end',
					}}
				>
					<p style={{ fontWeight: 700, fontSize: '24px' }}>Diferenciais</p>
				</div>
				<div
					style={{
						width: '50%',
						display: 'flex',
						flexDirection: 'column',
						color: 'white',
						justifyContent: 'center',
					}}
				>
					<img
						src={logo}
						alt=""
					/>
				</div>
			</div>
			{/* INICIO DO MAP */}
			{conteudos?.map((e, i) => {
				const isDivisa = e.logo === 'divisa' || e.logo === 'divisa2';
				const isLastItem = i === conteudos.length - 1;

				return (
					<div
						key={i}
						style={{
							backgroundColor: i % 2 === 0 ? '#1D1D1D' : '#040404',
							display: 'flex',
							flexDirection: isDivisa ? 'column' : 'row',
							alignItems: 'center',
							padding: '0.5rem 1rem',
							minHeight: isDivisa ? 'auto' : '84px',
							borderEndStartRadius: isLastItem && '16px',
							borderEndEndRadius: isLastItem && '16px',
						}}
					>
						{isDivisa ? (
							<div
								style={{
									// backgroundColor: '#040404',
									display: 'flex',
									padding: '1rem 1rem',
									minHeight: '54px',
									justifyContent: 'center',
								}}
							>
								<div
									style={{
										backgroundColor: '#303030',
										border: '1.5px solid white',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										padding: e.logo === 'divisa2' ? '0.3rem 1rem' : '0.3rem 2rem',
										borderRadius: '4px',
									}}
								>
									<p style={{ color: '#FCFCFC', fontWeight: 700, fontSize: '16px' }}>{e.titulo1}</p>
								</div>
							</div>
						) : (
							<>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										gap: '0.5rem',
										justifyContent: 'center',
									}}
								>
									{e.logo && (
										<img
											src={e.logo}
											alt=""
											width={120}
										/>
									)}
									<p style={{ color: '#FCFCFC', fontSize: '16px', fontWeight: 500 }}>{e.titulo1}</p>
									{e.subtitle && <p style={{ fontSize: '12px', color: '#AAA' }}>{e.subtitle}</p>}
								</div>
								<div
									style={{
										width: '50%',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<img
										src={e.icone}
										alt=""
									/>
								</div>
							</>
						)}
					</div>
				);
			})}
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					rowGap: 12,
					backgroundColor: '#101010',
					padding: '16px 20px',
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						columnGap: 8,
					}}
				>
					<img
						src={require('../../LDL/Assets/gift-duotone-solid.png')}
						alt=""
						style={{
							width: 32,
							height: 32,
						}}
					/>
					<p
						style={{
							fontSize: 14,
							fontFamily: 'Manrope',
							fontWeight: 500,
							color: '#FCFCFC',
						}}
					>
						Ganhe um Kit de flashcards exclusivo se inscrevendo em qualquer turma por tempo limitado!
					</p>
				</div>
				{/* <div
					style={{
						display: 'flex',
						columnGap: 12,
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<div
						style={{
							backgroundColor: '#CCCCCC',
							borderRadius: 8,
							padding: '4px 10px',
						}}
					>
						<p
							style={{
								color: '#101010',
								fontSize: 13,
								fontFamily: 'Manrope',
								fontWeight: 700,
							}}
						>
							Kit de
							<br />
							flashcards
						</p>
					</div>
					<p
						style={{
							color: '#FF8251',
							fontFamily: 'Manrope',
							fontWeight: 700,
							fontSize: 20,
						}}
					>
						+
					</p>
					<div
						style={{
							backgroundColor: '#CCCCCC',
							borderRadius: 8,
							padding: '4px 10px',
						}}
					>
						<p
							style={{
								color: '#101010',
								fontSize: 13,
								fontFamily: 'Manrope',
								fontWeight: 700,
							}}
						>
							Echo
							<br />
							Pop Alexa
						</p>
					</div>
					<p
						style={{
							color: '#959595',
							fontFamily: 'Manrope',
							fontWeight: 500,
							fontSize: 14,
						}}
					>
						ou
					</p>
					<div
						style={{
							backgroundColor: '#CCCCCC',
							borderRadius: 8,
							padding: '4px 10px',
						}}
					>
						<p
							style={{
								color: '#101010',
								fontSize: 13,
								fontFamily: 'Manrope',
								fontWeight: 700,
							}}
						>
							Novo
							<br />
							Kindle
						</p>
					</div>
					<sup
						style={{
							color: '#959595',
							fontFamily: 'Manrope',
							fontWeight: 500,
							fontSize: 12,
						}}
					>
						4
					</sup>
				</div> */}
			</div>
			<div
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					padding: '1rem',
					backgroundColor: '#040404',
					borderBottomLeftRadius: 16,
					borderBottomRightRadius: 16,
				}}
			>
				<p style={{ color: '#656565', fontSize: '12px', fontWeight: 500 }}>
					De: R${' '}
					{turma?.valor?.toLocaleString('pt-BR', {
						minimumFractionDigits: 2,
					})}
				</p>
				{lote1 && (
					<Typography
						fontSize={'1.125rem'}
						fontWeight={600}
						fontFamily={'Manrope'}
						color={'#B3B3B3'}
					>
						por até{' '}
						<strong
							style={{
								fontFamily: 'Outfit',
								display: 'inline',
								color: '#FCFCFC',
							}}
						>
							{turma?.parcelas?.length}x
						</strong>{' '}
						de
					</Typography>
				)}
				{lote1 && (
					<Typography
						color={'#B3B3B3'}
						fontFamily={'Manrope'}
						fontWeight={700}
						fontSize={'1.125rem'}
					>
						<strong
							style={{
								fontSize: '1.875rem',
								fontWeight: 'bold',
								fontFamily: 'Outfit',
								display: 'inline',
							}}
							className="gradient-text-trilha-r1-residencia"
						>
							R${' '}
							{turma?.parcelas[turma?.parcelas.length - 1].valor?.toLocaleString('pt-BR', {
								minimumFractionDigits: 2,
							})}
						</strong>{' '}
					</Typography>
				)}
				<Grid
					item
					container
					alignItems={'center'}
					columnGap={1}
				>
					{turma && (
						<Typography
							fontSize={'1.125rem'}
							fontWeight={600}
							fontFamily={'Manrope'}
							color={'#B3B3B3'}
						>
							ou{' '}
							<strong style={{ fontFamily: 'Outfit', color: '#FCFCFC' }}>
								R${' '}
								{lote1[0]?.valor?.toLocaleString('pt-BR', {
									minimumFractionDigits: 2,
								})}
							</strong>{' '}
							à vista
						</Typography>
					)}
					<Grid
						item
						style={{
							backgroundColor: '#00AD5899',
							padding: 6,
							borderRadius: 4,
						}}
					>
						<Typography
							fontFamily={'Manrope'}
							color={'#FCFCFC'}
							fontSize={'0.75rem'}
							style={{
								lineHeight: 'unset',
							}}
						>
							<strong>5% </strong>
							OFF
						</Typography>
					</Grid>
				</Grid>
				<div
					onClick={() => window.open(`//hardworkmedicina.com.br/inscricao/${turma?.id}`)}
					style={{
						backgroundColor: '#33C96F',
						color: '#FCFCFC',
						display: 'flex',
						justifyContent: 'center',
						borderRadius: '8px',
						padding: '0.5rem 1rem',
						marginTop: '1rem',
						cursor: 'pointer',
					}}
				>
					<img
						src={require('../../../../../Assets/Svg/Icons/bagIcon.svg').default}
						alt=""
						style={{
							width: 16,
							marginRight: 8,
						}}
					/>
					<p>Inscreva-se</p>
				</div>
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	face: {
		perspective: '700px',
		transformStyle: 'preserve-3d',
		height: 200,
		'& $front': {
			height: '100%',
		},
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				'& $front': {
					transform: 'rotateY(180deg)',
					zIndex: 0,
				},
				'& $back': {
					transform: 'rotateY(0)',
					zIndex: 1,
				},
			},
		},
		[theme.breakpoints.down('xl')]: {
			width: '95% !important',
		},
		[theme.breakpoints.down('sm')]: {
			width: '90% !important',
			height: 135,
		},
	},
	noFlip: {
		perspective: '700px',
		'& $front': {
			height: '100%',
		},
		[theme.breakpoints.down('xl')]: {
			width: '95% !important',
		},
		[theme.breakpoints.down('sm')]: {
			width: '90% !important',
		},
	},
	active: {
		'& $front': {
			transform: 'rotateY(180deg)',
			zIndex: 0,
		},
		'& $back': {
			transform: 'rotateY(0)',
			zIndex: 1,
		},
	},
	front: {
		width: '100% !important',
		position: 'absolute !important',
		zIndex: 1,
		transition: '700ms',
		border: '1px solid #9E9E9E',
		borderRadius: 12,
		padding: '1.5rem',
		background: `linear-gradient(135deg, #101010 0%, #161616 100%)`,
		[theme.breakpoints.down('sm')]: {
			padding: '1rem',
		},
	},
	back: {
		height: '100% !important',
		width: '100% !important',
		position: 'absolute !important',
		transform: 'rotateY(-180deg)',
		backfaceVisibility: 'hidden',
		transition: '700ms',
		border: '1px solid #9E9E9E',
		borderRadius: 12,
		padding: '1.5rem',
		background: '#161616',
		[theme.breakpoints.down('sm')]: {
			padding: '15px !important',
		},
	},
}));
