import { useEffect, useRef, useState } from 'react';
import { ListItem } from '@mui/material';
import list from './faculdades.json';

export default function Form({ titulo, infos, onNextStep, isLastStep, step, mobile, setIsSearching, altPage }) {
	const [filteredFaculdades, setFilteredFaculdades] = useState([]);
	const [errors, setErrors] = useState({ email: '', tel: '' });

	const formref = useRef(null);

	const executeScroll = () => formref?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });

	useEffect(() => {
		if (filteredFaculdades.length > 0) {
			setIsSearching(true);
		} else {
			setIsSearching(false);
		}
	}, [filteredFaculdades]);

	useEffect(() => {
		executeScroll();
	}, [step]);

	const handleValidation = () => {
		let isValid = true;
		let emailError = '';
		let telError = '';

		const emailInfo = infos.find((info) => info.type === 'email');
		const telInfo = infos.find((info) => info.type === 'tel');

		// Validação de e-mail
		if (emailInfo?.value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailInfo.value)) {
			emailError = 'E-mail inválido.';
			isValid = false;
		}

		// Validação de telefone
		if (telInfo?.value && !/^\(?\d{2}\)?\s?\d{4,5}-?\d{4}$/.test(telInfo.value)) {
			telError = 'Telefone inválido.';
			isValid = false;
		}

		setErrors({ email: emailError, tel: telError });

		return isValid;
	};

	const handleNextStep = () => {
		if (handleValidation()) {
			onNextStep();
		}
	};

	const handleSearch = (e) => {
		const value = e.target.value;
		if (value) {
			const filtered = list.faculdades.filter((faculdade) =>
				faculdade.toLowerCase().includes(value.toLowerCase())
			);
			setFilteredFaculdades(filtered);
		} else {
			setFilteredFaculdades([]);
		}
	};

	if (!titulo) return null;

	return (
		<div
			ref={formref}
			style={{
				display: 'flex',
				flexDirection: 'column',
				backgroundColor: altPage ? '#1D1D1D90' : '#303030CC',
				width: '100%',
				padding: '32px',
				borderRadius: '24px',
				gap: '16px',
				boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
				backdropFilter: 'blur(4px)',
			}}
		>
			{altPage ? (
				<p
					style={{
						fontSize: step === 3 && !mobile ? '25px' : '20px',
						fontWeight: 800,
						lineHeight: '120%',
						textAlign: step === 3 && 'center',
						textWrap: mobile && step === 3 && 'nowrap',
					}}
				>
					{titulo}
				</p>
			) : (
				<p
					style={{
						fontSize: step === 0 ? '32px' : '20px',
						fontWeight: 800,
						lineHeight: '120%',
						textAlign: step === 0 && 'center',
						textWrap: mobile && step === 0 && 'nowrap',
					}}
				>
					{titulo}
				</p>
			)}

			{infos?.map((info, index) => {
				if (info.type === 'text' || info.type === 'email' || info.type === 'tel') {
					return (
						<div
							key={index}
							style={{ width: '100%' }}
						>
							<input
								type={info.type}
								placeholder={info.placeholder}
								value={info.value}
								onChange={info.onChange}
								name={info?.type === 'text' ? 'nome' : info?.type}
								style={{
									width: '100%',
									backgroundColor: '#ffffff10',
									padding: '12px 16px',
									borderRadius: '8px',
									color: '#fff',
									border: 'none',
									fontSize: '16px',
								}}
							/>
							{/* Mensagem de erro */}
							{errors[info.type] && <p style={{ color: 'red', fontSize: '12px' }}>{errors[info.type]}</p>}
						</div>
					);
				} else if (info.type === 'search') {
					return (
						<div
							key={index}
							style={{ position: 'relative', width: '100%' }}
						>
							<input
								type="text"
								name="faculdade"
								placeholder={info.placeholder}
								value={info.value}
								onChange={(e) => {
									info.value = e.target.value;
									info.onChange(e);
									handleSearch(e);
								}}
								style={{
									width: '100%',
									backgroundColor: '#ffffff10',
									padding: '12px 16px',
									borderRadius: '8px',
									color: '#fff',
									border: 'none',
									fontSize: '16px',
								}}
							/>

							{filteredFaculdades.length > 0 && (
								<ul
									style={{
										position: 'absolute',
										top: '100%',
										left: 0,
										width: '100%',
										backgroundColor: '#3B3B3B',
										border: '1px solid #303030',
										borderRadius: '8px',
										maxHeight: '200px',
										overflowY: 'auto',
										zIndex: 100,
									}}
								>
									{filteredFaculdades?.map((faculdade, idx) => (
										<ListItem
											key={idx}
											onClick={() => {
												info.onSelect(faculdade);
												setFilteredFaculdades([]);
											}}
											sx={{
												backgroundColor: '#3B3B3B',
												color: '#CCCCCC',
												fontSize: '12px',
												transition: 'background-color 0.3s',

												'&:hover': {
													backgroundColor: '#656565',
												},
											}}
										>
											{faculdade}
										</ListItem>
									))}
								</ul>
							)}
						</div>
					);
				} else if (info.type === 'radio') {
					return (
						<div
							style={{
								width: '100%',
								padding: '12px 0px',
								borderRadius: '8px',
								color: '#fff',
								border: 'none',
								fontSize: '16px',
								display: 'flex',
								flexDirection: 'column',
								gap: '16px',
							}}
							key={index}
						>
							{info?.options?.map((option, idx) => (
								<>
									<label
										key={idx}
										style={{
											display: 'flex',
											color: '#fff',
											backgroundColor: '#ffffff10',
											padding: '12px 16px',
											borderRadius: '8px',
											justifyContent: 'flex-start',
											// flexDirection: 'column',
										}}
									>
										<input
											type="radio"
											name={`pergunta-${step}`}
											value={option.value || option}
											checked={info.value === (option.value || option)}
											onChange={info.onChange}
											style={{ marginRight: '8px' }}
										/>
										{option.label || option}
									</label>
									{step === 4 && idx === 0 && info.value === (option.value || option) && (
										<div
											style={{
												display: 'flex',
												color: '#fff',
												backgroundColor: '#ffffff10',
												padding: '12px 16px',
												borderRadius: '8px',
												justifyContent: 'flex-start',
												flexDirection: 'column',
												marginTop: '-10px',
											}}
										>
											<p style={{ fontSize: '12px' }}>
												Qual a especialidade e serviço pretendido?
											</p>
											<textarea
												name="pergunta-complementar-1"
												style={{
													backgroundColor: '#FFFFFF26',
													color: '#fff',
													fontSize: '12px',
													marginTop: '0.5rem',
												}}
												onChange={info.onChangeTwo}
											></textarea>
										</div>
									)}
									{step === 4 && idx === 1 && info.value === (option.value || option) && (
										<div
											style={{
												display: 'flex',
												color: '#fff',
												backgroundColor: '#ffffff10',
												padding: '12px 16px',
												borderRadius: '8px',
												justifyContent: 'flex-start',
												flexDirection: 'column',
												marginTop: '-10px',
											}}
										>
											<p style={{ fontSize: '12px' }}>
												Qual a especialidade e serviço pretendido?
											</p>
											<textarea
												name="pergunta-complementar-2"
												style={{
													backgroundColor: '#FFFFFF26',
													color: '#fff',
													fontSize: '12px',
													marginTop: '0.5rem',
												}}
												onChange={info.onChangeTwo}
											></textarea>
										</div>
									)}
								</>
							))}
						</div>
					);
				} else if (info.type === 'none') {
					return null;
				}
				return null;
			})}

			<div
				onClick={handleNextStep}
				style={{
					display: 'flex',
					width: '100%',
					marginTop: '24px',
					background: altPage
						? 'linear-gradient(90deg, #FF005D 0%,#D52A8A 50.97%, #9F38B9 99.94%)'
						: 'linear-gradient(0deg, #E11941 0.02%, #E93740 100.05%)',
					justifyContent: 'center',
					alignItems: 'center',
					padding: '10px 0',
					borderRadius: '50px',
					cursor: 'pointer',
				}}
			>
				<p style={{ fontSize: '18px', fontWeight: 700 }}>{isLastStep ? 'Concluir' : 'Avançar'}</p>
			</div>
		</div>
	);
}
