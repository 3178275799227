import React from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';

import Form from '../../../../Components/Utils/Forms/PreRegistration';
import LiveLogo from '../../../../Assets/Svg/img-lives.svg';

export default function EnareReviewSubscriptionPage() {
	const classes = useStyles();
	const theme = useTheme();

	// const { code } = useParams();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						md={7}
						container
						className="residencia"
						style={matchesMobile ? { marginBottom: 8 } : { marginBottom: 16, height: 'fit-content' }}
					>
						<Grid
							item
							xs={12}
							md={7}
							container
							alignContent={'center'}
							style={{ marginBottom: '1.5rem' }}
							gap={2}
						>
							<img
								src={LiveLogo}
								alt="Live logo"
								className={classes.img}
							/>
							<Typography
								color="secondary"
								className={`${classes.mainTitle} poppins`}
							>
								REVISÃO <br /> ENARE
							</Typography>
						</Grid>
						<Typography
							className={`${classes.title} gradient-text roboto-condensed`}
							color="secondary"
							// style={matchesTablet ? { marginTop: 16, paddingLeft: 0 } : { paddingLeft: 15 }}
						>
							Revisão gratuita • Lives todos os dias
						</Typography>
						{matchesMobile ? (
							<Typography
								align="left"
								color="secondary"
								className={`${classes.text} poppins`}
							>
								Resolvemos escancarar TUDO! O Hardwork domina a banca do ENARE, e vamos te mostrar isso
								discutindo, DIARIAMENTE, os temas mais importantes, do jeito que são esperados na prova.
								<br />
								<br />
								Inscreva-se para não perder!
							</Typography>
						) : (
							<Typography
								align="left"
								color="secondary"
								className={`${classes.text} poppins`}
							>
								Resolvemos escancarar TUDO! O Hardwork domina a banca do ENARE,
								<br />e vamos te mostrar isso discutindo, DIARIAMENTE, os temas mais
								<br />
								importantes, do jeito que são esperados na prova.
								<br />
								<br />
								Inscreva-se para não perder!
							</Typography>
						)}
						<Typography
							align="left"
							color="secondary"
							className={`${classes.impactText} poppins`}
						>
							A PARTIR DE 21AGO
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						container
						justifyContent="center"
						alignContent="flex-end"
						style={{ paddingBottom: 50 }}
					>
						<Form
							group={`REVISÃO - ENARE`}
							title={
								matchesMobile
									? 'Inscreva-se gratuitamente para participar das lives!'
									: ['Inscreva-se gratuitamente', <br />, 'para participar das lives!']
							}
							titleButton="Confirmar inscrição"
							type="residencia"
							urlToRedirect={`/inscricao-revisao-enare`}
							confirmationMessage={
								'Sua inscrição está confirmada! Em breve você também receberá uma confirmação pelo e-mail cadastrado.'
							}
							noCellphone
						/>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	mainTitle: {
		fontSize: '2.75rem !important',
		lineHeight: 'unset !important',
		fontWeight: 'bold !important',
		alignSelf: 'center',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
		},
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '2.2rem !important',
		lineHeight: '1 !important',
		marginBottom: '1.5rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
			marginBottom: '1rem !important',
		},
	},
	text: {
		fontSize: '1.25rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			marginBottom: '1rem !important',
		},
	},
	impactText: {
		margin: '2rem 0 0 !important',
		fontSize: '2rem !important',
		lineHeight: 'unset !important',
		background: 'linear-gradient(90deg, rgb(255 72 0) 10%, rgb(255 0 66) 100%)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.45rem !important',
			margin: '0 0 2rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down(361)]: {
			fontSize: '1.25rem !important',
		},
	},
	img: {
		width: '32%',
		[theme.breakpoints.down('lg')]: {
			width: '35%',
		},
		[theme.breakpoints.down('md')]: {
			width: '21%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '34%',
		},
	},
}));
