import React from 'react';
import { Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function HostCard({ ig_username, img, specialty }) {
	const classes = useStyles();
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Paper
			className={`rounded-card`}
			style={{ backgroundColor: 'transparent' }}
		>
			<img
				src={img}
				alt=""
				className={ig_username === '@fabricio.valois' ? classes.imageHostCardFabricio : classes.imageHostCard}
				style={
					ig_username === '@fabricio.valois' && !specialty ? { minWidth: matchesMobile ? 290 : 305 } : null
				}
			/>
			{specialty && (
				<Grid
					container
					direction="column"
					className={classes.gridTextInfo}
					style={
						ig_username === '@fabricio.valois'
							? matchesMobile
								? { left: 75 }
								: { left: 80 }
							: { left: 10 }
					}
				>
					<Typography
						align="left"
						color="secondary"
						className={`${classes.userName} title-card-home`}
					>
						{ig_username}
					</Typography>
					<Typography
						align="left"
						color="secondary"
						className={`${classes.specialtyText} poppins`}
					>
						{specialty}
					</Typography>
				</Grid>
			)}
		</Paper>
	);
}

const useStyles = makeStyles((theme) => ({
	imageHostCardFabricio: {
		minWidth: 315,
		[theme.breakpoints.down('sm')]: {
			minWidth: 300,
		},
	},
	imageHostCard: {
		minWidth: 247,
		[theme.breakpoints.down('sm')]: {
			minWidth: 235,
		},
	},
	userName: {
		fontSize: '1.6rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.35rem !important',
		},
	},
	specialtyText: {
		fontSize: '1rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	gridTextInfo: {
		position: 'absolute',
		marginTop: -100,
		[theme.breakpoints.down('sm')]: {
			marginTop: -85,
		},
	},
}));
