import React, { useState } from 'react';
import { Checkbox, Grid, Paper, TextField, Typography } from '@mui/material';
import Api from '../../../Services/Api';
import DefaultButton from '../../Buttons/DefaultButton';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import 'react-intl-tel-input/dist/main.css';
import { useEffect } from 'react';

export default function TestsPaperForm({ group, title, titleButton, type, urlToRedirect, institution }) {
	const classes = useStyles();
	const [preInscricao, setPreInscricao] = useState({
		agrupamento: '',
		nome: '',
		email: '',
		provas: '',
		telefone: '',
		rg: '',
		provas_outras: '',
		optin: false,
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});

	let sendPreInscricao = function (event) {
		event.preventDefault();
		const nomeCompleto = preInscricao.nome.trim();
		const nomeSobrenome = nomeCompleto.split(' ');

		if (!preInscricao.nome || nomeSobrenome.length < 2) {
			alert('Ops... Por favor informe seu nome e sobrenome');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!preInscricao.optin) {
			alert('Ops... Você precisa ler e aceitar os termos de uso acima para se pré-inscrever');
			return;
		}

		trackPromise(
			Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					document.location.href = `${urlToRedirect}`;
				}
			})
		);
	};

	useEffect(() => {
		setPreInscricao({
			...preInscricao,
			provas: institution,
			agrupamento: group,
		});
	}, [institution, group]);

	return (
		<Paper
			elevation={4}
			className={`${classes.paper} rounded-card background-${type}`}
		>
			<Grid container>
				<form
					className="form-control"
					onSubmit={sendPreInscricao}
				>
					<Grid
						item
						xs={12}
						container
						rowGap={1}
					>
						<Grid
							item
							container
							justifyContent="center"
						>
							<Typography
								className={`${classes.signInCardTitle} poppins`}
								color="secondary"
								align="center"
							>
								{title}
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
						>
							<TextField
								fullWidth
								type="string"
								name="nome"
								placeholder="Seu nome completo"
								required={true}
								InputProps={{
									className: `${classes.form} ${classes.formHeight}`,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										nome: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
						>
							<TextField
								fullWidth
								type="email"
								name="email"
								placeholder="Seu melhor e-mail"
								required={true}
								InputProps={{
									className: `${classes.form} ${classes.formHeight}`,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										email: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
						>
							<Checkbox
								style={{ padding: '0px' }}
								checked={preInscricao.optin}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										optin: !preInscricao.optin,
									})
								}
							/>
							<Typography
								variant="caption"
								color="primary"
								className={classes.captionText}
							>
								Estou de acordo com o tratamento dos meus dados pessoais para fins de inscrição na
								plataforma Hardwork Medicina, bem como para envio de comunicações relativas aos serviços
								educacionais que se adequem ao meu perfil, de acordo com a nossa{' '}
								<Link
									color="primary"
									href="https://hardworkmedicina.com.br/politica-de-privacidade"
									target="_blank"
									underline="hover"
									variant="caption"
									align="right"
									className={`${classes.copyrightText} poppins`}
								>
									Politica de privacidade
								</Link>
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent="center"
							className="residencia"
						>
							<DefaultButton
								type={'submit'}
								variant="outlined"
								color="secondary"
								className={`${classes.btn} btn`}
								onClick={(e) => sendPreInscricao(e)}
							>
								{titleButton}
							</DefaultButton>
						</Grid>
					</Grid>
				</form>
			</Grid>
		</Paper>
	);
}

const useStyles = makeStyles((theme) => ({
	paper: {
		margin: '0 auto',
		padding: '1.5rem',
		[theme.breakpoints.up('lg')]: {
			// height: 449,
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1rem',
		},
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: theme.palette.secondary.main,
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'transparent !important',
		},
	},
	formHeight: {
		maxHeight: '3em',
		[theme.breakpoints.down('sm')]: {
			maxHeight: '45px',
		},
	},
	formHeightShort: {
		maxHeight: '2.5em',
	},
	phoneNumberForm: {
		height: '3em',
		borderRadius: 8,
		width: '100%',
		border: 'unset',
	},
	signInCardTitle: {
		fontSize: '1.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.5rem !important',
		},
	},
	btn: {
		fontSize: '1.15rem !important',
		width: '75%',
		fontWeight: 'bold !important',
		textTransform: 'uppercase !important',
		backgroundColor: '#000000 !important',
		border: '1px solid transparent !important',
		'&:hover': {
			border: '1px solid #ffffff !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	copyrightText: {
		color: theme.palette.primary.main,
	},
	captionText: {
		[theme.breakpoints.down('xl')]: {
			fontSize: '0.65rem !important',
		},
	},
	selectMenu: {
		width: '100%',
		borderRadius: '8px !important',
		backgroundColor: '#FFFFFF',
		maxHeight: '3em',
	},
	selectFieldText: {
		fontSize: '0.9rem !important',
		// marginTop: '1rem !important',
	},
	foodRequirementText: {
		fontSize: '1.1rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '0.9rem !important',
		},
	},
}));
