export function redirectAPP({ playStore, appleStore }) {
	var isMobile = {
		Android: function () {
			return /Android/i.test(navigator.userAgent);
		},

		iOS: function () {
			return /iPhone|iPad|iPod/i.test(navigator.userAgent);
		},

		// Windows: function () {
		// 	return /IEMobile/i.test(navigator.userAgent);
		// },
	};

	if (isMobile.Android()) {
		window.location.href = playStore;
	} else if (isMobile.iOS()) {
		window.location.href = appleStore;
	}
}
