import { useEffect, useState } from 'react';
import { Divider, Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link, useSearchParams } from 'react-router-dom';
import DefaultButton from '../../../../../Components/Buttons/DefaultButton';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Api from '../../../../../Services/Api';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import { useStylesPurchaseCard } from './PurchaseCard';
import moment from 'moment/moment';

export default function PurchaseCardOnline20242({
	activeButtonId,
	idTurma,
	urlToRedirect,
	confirmationMessage,
	gambiarra,
}) {
	const classes = useStylesPurchaseCard();
	const specificClasses = useStyles();
	const theme = useTheme();
	const [turma, setTurma] = useState([]);
	const [turmaSelecionada, setTurmaSelecionada] = useState(null);
	const [currentLot, setCurrentLot] = useState([]);
	const [isOpenCard, setIsOpenCard] = useState(false);
	const [waitListVisibility, setWaitListVisibility] = useState('hidden');
	const [searchParams] = useSearchParams();
	const [preInscricao, setPreInscricao] = useState({
		agrupamento:
			activeButtonId === '1407'
				? 'ARENA 2024.2 PRESENCIAL LISTA DE ESPERA'
				: activeButtonId === '1374,1407'
				? 'ARENA 2024.2 PRESENCIAL + ONLINE LISTA DE ESPERA'
				: `ARENA 2024.2 ONLINE LISTA DE ESPERA`,
		nome: '',
		email: '',
		telefone: '',
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));

	useEffect(() => {
		setPreInscricao({
			...preInscricao,
			utm_source: searchParams.get('utm_source'),
			utm_medium: searchParams.get('utm_medium'),
			utm_campaign: searchParams.get('utm_campaign'),
			utm_content: searchParams.get('utm_content'),
			utm_term: searchParams.get('utm_term'),
		});
		Api.get(`turmasabertas/${idTurma}`).then((res) => {
			if (res) {
				// console.log(idTurma);
				// console.log(res);
				if (idTurma === '1374,1407') {
					setTurma(res);
				} else {
					setTurma(res[0]);
				}

				const data_hoje = moment(new Date()).format();

				const current_lot = res[0]?.lotes?.filter(
					(lot) =>
						data_hoje > moment(lot?.data_inicio).format() && data_hoje < moment(lot?.data_limite).format()
				);

				if (current_lot?.length > 0) {
					setCurrentLot(current_lot);
					setIsOpenCard(true);
				} else {
					setIsOpenCard(false);
				}
			}
		});
	}, [idTurma]);

	useEffect(() => {
		if (turma && turma.id) {
			setPreInscricao({
				agrupamento: `ARENA 2025.1 - LISTA DE ESPERA`,
				nome: '',
				email: '',
				telefone: '',
				utm_source: '',
				utm_medium: '',
				utm_campaign: '',
			});
		}
	}, [turma]);

	let sendPreInscricao = function () {
		if (!preInscricao.nome) {
			alert('Ops... Por favor informe seu nome');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!preInscricao.telefone || preInscricao.telefone.length < 11) {
			alert('Ops... Por favor preencha seu telefone');
			return;
		}

		trackPromise(
			Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					Swal.fire({
						title: 'Massa! ',
						text: `${
							confirmationMessage
								? confirmationMessage
								: 'Avisaremos assim que novas turmas estiverem liberadas.'
						}`,
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						if (result.isConfirmed) {
							if (urlToRedirect) {
								document.location.href = `${urlToRedirect}`;
							} else {
								document.location.href = '/';
							}
						}
					});
				}
			})
		);
	};

	const handleCloseWaitList = () => {
		setWaitListVisibility('hidden');
	};

	const handleOpenWaitList = () => {
		setWaitListVisibility('unset');
		setTurmaSelecionada(null);
	};

	const phoneMask = (value) => {
		if (!value) return '';
		value = value.replace(/\D/g, '');
		value = value.replace(/(\d{2})(\d)/, '($1) $2');
		value = value.replace(/(\d)(\d{4})$/, '$1-$2');
		return value;
	};

	const handlePhone = (event) => {
		let input = event.target;
		input.value = phoneMask(input.value);
		setPreInscricao({
			...preInscricao,
			telefone: input.value,
		});
	};

	return (
		<Grid
			container
			style={!activeButtonId ? { opacity: 0.65 } : null}
			className={`${specificClasses.root}`}
		>
			<Grid
				item
				xs={12}
				container
			>
				<Grid
					item
					container
					justifyContent={'center'}
					alignContent={'center'}
					className={specificClasses.background}
					rowGap={3}
					style={{
						padding:
							activeButtonId === '1374' && matchesTablet
								? '2em 2em 1em'
								: matchesTablet
								? '2em 1em 1em'
								: '4rem 3rem 3rem',
					}}
				>
					<img
						src={
							idTurma.includes('1374') && idTurma.includes('1407')
								? require('../assets/logo-arena-online-presencial-2024.svg').default
								: idTurma === '1374'
								? require('../assets/logo-arena-online-2024.svg').default
								: require('../assets/logo-arena-presencial-2024.svg').default
						}
						alt="logo"
						style={{ width: matchesLg ? '60%' : matchesTablet ? '70%' : 'unset' }}
					/>
					{idTurma === '1374' && (
						<Grid
							item
							container
							justifyContent={'center'}
							alignContent={'center'}
							rowGap={1}
						>
							<Grid
								item
								container
								width={'fit-content'}
								alignContent={'center'}
								justifyContent={'center'}
								paddingX={1.5}
								paddingY={0.75}
								backgroundColor={'#008779'}
								sx={{
									borderRadius: '6px',
								}}
							>
								<Typography
									color={'#fff'}
									fontSize={12}
									fontWeight={600}
									className="poppins"
								>
									{' '}
									10% OFF para alunos e ex-alunos Trilha, Extensivo, Sprint e Arena Hardwork
								</Typography>
							</Grid>
							<Grid
								item
								container
								width={'fit-content'}
								alignContent={'center'}
								justifyContent={'center'}
								paddingX={1.5}
								paddingY={0.75}
								backgroundColor={'#008779'}
								sx={{
									borderRadius: '6px',
								}}
							>
								<Typography
									color={'#fff'}
									fontSize={12}
									fontWeight={600}
									className="poppins"
								>
									{' '}
									Condição especial para alunos inscritos no Arena Presencial 2025.1
								</Typography>
							</Grid>
						</Grid>
					)}
					<>
						{idTurma === '1407' && (
							<Grid
								item
								container
								width={'fit-content'}
								alignContent={'center'}
								justifyContent={'center'}
								paddingX={1.5}
								paddingY={0.75}
								backgroundColor={'#008779'}
								sx={{
									borderRadius: '6px',
									padding: '4px 32px',
								}}
							>
								<Typography
									color={'#fff'}
									fontSize={13}
									fontWeight={600}
									className="poppins"
								>
									{' '}
									{gambiarra
										? 'A inscrição nesta turma não altera os contratos vigentes de outras turmas'
										: 'Condição especial para alunos inscritos no Arena Online 2024.2'}
								</Typography>
							</Grid>
						)}
						{idTurma !== '1374' && (
							<Grid
								item
								container
								justifyContent={'center'}
								rowGap={1.5}
								marginTop={2}
							>
								<Typography
									color={'#BFBFBF'}
									fontSize={13}
									align="center"
									className="poppins"
								>
									**Caso a prova seja confirmada para estas datas, o Arena Presencial será realizado
									no final de semana anterior à data da prova.
								</Typography>
							</Grid>
						)}
					</>
				</Grid>

				{turma.encerrada ? (
					<Grid
						container
						direction={'column'}
						justifyContent={'center'}
						alignContent={'center'}
						className={classes.containerOptions}
					>
						<Grid
							item
							container
							alignItems={'center'}
							height={matchesTablet ? null : '50%'}
							marginBottom={matchesTablet ? '2rem' : null}
						>
							{/* <Typography
								color={'secondary'}
								fontSize={matchesTablet ? 32 : 56}
								fontWeight={'bold'}
								lineHeight={1}
								className="poppins"
							>
								INSCRIÇÕES
								<br /> ENCERRADAS
							</Typography> */}
							{/* <Grid
								item
								container
								alignContent={'center'}
								justifyContent={'center'}
								marginTop={3}
								paddingTop={3}
								style={{ borderTop: idTurma === '1374' && '1px solid #515151' }}
							></Grid> */}
							<DefaultButton
								variant="outlined"
								color="secondary"
								className={`${specificClasses.btn} btn`}
								component={Link}
								onClick={() => handleOpenWaitList()}
								// to={`//hardworkmedicina.com.br/inscricao/${idTurma}`}
							>
								{'Avise-me'}
							</DefaultButton>
						</Grid>
						{turma && (
							<form
								className="form-control"
								action="javascript:;"
							>
								<Grid
									item
									container
									justifyContent={'center'}
									className={classes.containerWaitList}
									rowGap={1}
									style={{ visibility: waitListVisibility }}
								>
									<Grid
										item
										container
										justifyContent="space-between"
									>
										<Typography
											color="primary"
											className={`${classes.textWaitList} poppins`}
										>
											Inscrever-se na Lista de Espera
										</Typography>
										<CancelRoundedIcon
											onClick={() => handleCloseWaitList()}
											className={classes.cancelIcon}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										md={6}
										container
										justifyContent={matchesTablet ? 'center' : 'flex-start'}
									>
										<TextField
											fullWidth
											type="string"
											name="nome"
											placeholder="Seu nome completo"
											required={true}
											InputProps={{
												className: `${classes.form} ${classes.formHeight}`,
											}}
											InputLabelProps={{
												shrink: true,
											}}
											style={matchesTablet ? { width: '100%' } : { width: '97%' }}
											onChange={(e) =>
												setPreInscricao({
													...preInscricao,
													nome: e.target.value,
												})
											}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										md={6}
										container
										justifyContent={matchesTablet ? 'center' : 'flex-end'}
									>
										<TextField
											fullWidth
											type="tel"
											name="telefone"
											placeholder="Seu celular com DDD"
											required={true}
											pattern="[0-9]*"
											inputProps={{
												maxLength: 15,
											}}
											InputProps={{
												className: `${classes.form} ${classes.formHeight}`,
											}}
											InputLabelProps={{
												shrink: true,
											}}
											style={matchesTablet ? { width: '100%' } : { width: '97%' }}
											onChange={(e) => handlePhone(e)}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										container
										justifyContent={matchesMobile ? 'center' : 'flex-start'}
									>
										<TextField
											fullWidth
											type="email"
											name="email"
											placeholder="Seu melhor e-mail"
											required={true}
											InputProps={{
												className: `${classes.form} ${classes.formHeight}`,
											}}
											InputLabelProps={{
												shrink: true,
											}}
											style={{ width: '100%' }}
											onChange={(e) =>
												setPreInscricao({
													...preInscricao,
													email: e.target.value,
												})
											}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										md={10}
										lg={8}
										container
										className="residencia"
										style={{ height: 50, padding: '4px 0px' }}
									>
										<DefaultButton
											// type="submit"
											variant="outlined"
											color="secondary"
											className={`${classes.btnWaitList} btn`}
											onClick={() => sendPreInscricao()}
										>
											<img
												src={require('../../../../../Assets/Arena/icon-waitList.svg').default}
												alt=""
												style={{ width: 20 }}
											/>{' '}
											&nbsp; Confirmar inscrição
										</DefaultButton>
									</Grid>
								</Grid>
							</form>
						)}
					</Grid>
				) : (
					<Grid
						container
						direction={'column'}
						justifyContent={activeButtonId === 1 ? 'space-evenly' : 'space-between'}
						alignContent={'center'}
						className={classes.containerOptions}
					>
						{gambiarra ? (
							<Grid
								item
								container
								alignItems={'center'}
								height={matchesTablet ? null : '50%'}
								marginBottom={matchesTablet ? '2rem' : null}
							>
								<Typography
									color={'secondary'}
									fontSize={matchesTablet ? 32 : 76}
									fontWeight={'bold'}
									lineHeight={1}
									className="poppins"
								>
									Valores
									<br /> sob consulta
								</Typography>
							</Grid>
						) : (
							<Grid
								item
								container
								direction={'column'}
								className="revalida"
								style={
									idTurma === '1407' || idTurma === '1374,1407'
										? {
												borderBottom: '1px solid #515151',
												paddingBottom: '1rem',
										  }
										: null
								}
							>
								<>
									{/* {(idTurma === '1374,1407' || idTurma === '1407') && (
									<Typography
										color="secondary"
										fontSize={matchesTablet ? 24 : 40}
										fontWeight={700}
										className={`poppins`}
									>
										Valores a partir de:
									</Typography>
								)} */}
									{/* {!idTurma === '1407' && ( */}
									<Typography
										color="#CECECE"
										className={`${specificClasses.paymentModeText} poppins`}
										style={{ marginTop: '1rem' }}
									>
										À&nbsp;
										<span
											className="gradient-text"
											style={{ display: 'inline', fontWeight: 700, fontSize: 26 }}
										>
											vista
										</span>
										&nbsp;por
									</Typography>
									{/* )} */}
									{idTurma === '1374' && (
										<>
											<Typography
												className={`poppins`}
												fontSize={matchesMobile ? 15 : 30}
												fontWeight={700}
												style={{ textDecoration: 'line-through' }}
											>
												R${' '}
												{turma?.valor?.toLocaleString('pt-BR', {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												})}
											</Typography>
											<Typography
												color="secondary"
												className={`${classes.priceText} roboto-condensed`}
											>
												R${' '}
												{currentLot[0]?.valor?.toLocaleString('pt-BR', {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												})}
											</Typography>{' '}
										</>
									)}
									{idTurma === '1407' && (
										<>
											<Typography
												color="secondary"
												className={`${classes.priceText} roboto-condensed`}
											>
												R${' '}
												{turma?.valor?.toLocaleString('pt-BR', {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												})}
											</Typography>{' '}
										</>
									)}
									{idTurma === '1374,1407' && (
										<>
											<Typography
												color="secondary"
												className={`${classes.priceText} roboto-condensed`}
											>
												{/* REGRA DO VALOR DO COMBO(1374,1407): METADE DO VALOR DO ONLINE + 100% DO VALOR DO PRESENCIAL */}
												R${' '}
												{turma[0] && turma[1]
													? (turma[0]?.valor * 0.5 + turma[1].valor)?.toLocaleString(
															'pt-BR',
															{
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
															}
													  )
													: null}
											</Typography>
										</>
									)}
									<Typography
										color="#CECECE"
										className={`${specificClasses.paymentModeText} poppins`}
										style={{ marginTop: 25 }}
									>
										Ou até&nbsp;
										<span
											className="gradient-text"
											style={{ display: 'inline', fontWeight: 700, fontSize: 26 }}
										>
											{idTurma === '1374,1407' ? '12' : turma?.max_parcelas}x
										</span>
										&nbsp;de
									</Typography>
									<Typography
										color="secondary"
										className={`${classes.priceText} roboto-condensed`}
									>
										R${' '}
										{/* REGRA DO VALOR DA PARCELA DO COMBO(1374,1407): METADE DO VALOR DO ONLINE + 100% DO VALOR DO PRESENCIAL DIVIDIDO POR 12 */}
										{idTurma === '1374,1407'
											? turma[0] && turma[1]
												? ((turma[0]?.valor * 0.5 + turma[1].valor) / 12)?.toLocaleString(
														'pt-BR',
														{
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														}
												  )
												: null
											: turma?.parcelas && turma?.parcelas?.length > 0
											? `${turma?.parcelas[turma?.parcelas?.length - 1].valor?.toLocaleString(
													'pt-BR',
													{
														minimumFractionDigits: 2,
													}
											  )}`
											: ''}
									</Typography>
								</>
								{/* )} */}
								<Divider
									variant="middle"
									className={classes.divider}
								/>
							</Grid>
						)}
						{idTurma.includes('1407') && (
							<Grid
								item
								container
								alignContent={'center'}
								marginTop={2}
								sx={{
									border: 1,
									borderRadius: 2,
									borderColor: '#00AD58',
									padding: 2,
								}}
							>
								<img
									src={require('../../../../../Assets/Arena/logo-sold-out-wide.png')}
									alt=""
									style={{ position: 'absolute', zIndex: 1, top: 10 }}
								/>
								<Typography
									color={'#fff'}
									fontSize={16}
									className="poppins"
								>
									{' '}
									<span style={{ fontWeight: 'bold', fontSize: 30 }}>07</span> e{' '}
									<span style={{ fontWeight: 'bold', fontSize: 30 }}>08</span> de{' '}
									<span style={{ fontWeight: 'bold' }}>Dezembro</span> (sábado e domingo)**
								</Typography>
							</Grid>
						)}

						<Grid
							item
							container
							alignContent={'center'}
							justifyContent={'center'}
							marginTop={3}
							paddingTop={3}
							style={{ borderTop: idTurma === '1374' && '1px solid #515151' }}
						>
							{idTurma === '1374' ? (
								<DefaultButton
									variant="outlined"
									color="secondary"
									className={`${specificClasses.btn} btn`}
									component={Link}
									// disabled={idTurma !== '1374'}
									// onClick={() => handleOpenWaitList()}
									to={`//hardworkmedicina.com.br/inscricao/${idTurma}`}
								>
									{'Inscreva-se'}
								</DefaultButton>
							) : (
								<DefaultButton
									variant="outlined"
									color="secondary"
									className={`${specificClasses.btn} btn`}
									component={Link}
									onClick={() => handleOpenWaitList()}
									// to={`//hardworkmedicina.com.br/inscricao/${idTurma}`}
								>
									{'Lista de Espera'}
								</DefaultButton>
							)}
						</Grid>
						{turma && (
							<form
								className="form-control"
								action="javascript:;"
							>
								<Grid
									item
									container
									justifyContent={'center'}
									className={classes.containerWaitList}
									rowGap={1}
									style={{ visibility: waitListVisibility }}
								>
									<Grid
										item
										container
										justifyContent="space-between"
									>
										<Typography
											color="primary"
											className={`${classes.textWaitList} poppins`}
										>
											Inscrever-se na Lista de Espera
										</Typography>
										<CancelRoundedIcon
											onClick={() => handleCloseWaitList()}
											className={classes.cancelIcon}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										md={6}
										container
										justifyContent={matchesTablet ? 'center' : 'flex-start'}
									>
										<TextField
											fullWidth
											type="string"
											name="nome"
											placeholder="Seu nome completo"
											required={true}
											InputProps={{
												className: `${classes.form} ${classes.formHeight}`,
											}}
											InputLabelProps={{
												shrink: true,
											}}
											style={matchesTablet ? { width: '100%' } : { width: '97%' }}
											onChange={(e) =>
												setPreInscricao({
													...preInscricao,
													nome: e.target.value,
												})
											}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										md={6}
										container
										justifyContent={matchesTablet ? 'center' : 'flex-end'}
									>
										<TextField
											fullWidth
											type="tel"
											name="telefone"
											placeholder="Seu celular com DDD"
											required={true}
											pattern="[0-9]*"
											inputProps={{
												maxLength: 15,
											}}
											InputProps={{
												className: `${classes.form} ${classes.formHeight}`,
											}}
											InputLabelProps={{
												shrink: true,
											}}
											style={matchesTablet ? { width: '100%' } : { width: '97%' }}
											onChange={(e) => handlePhone(e)}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										container
										justifyContent={matchesMobile ? 'center' : 'flex-start'}
									>
										<TextField
											fullWidth
											type="email"
											name="email"
											placeholder="Seu melhor e-mail"
											required={true}
											InputProps={{
												className: `${classes.form} ${classes.formHeight}`,
											}}
											InputLabelProps={{
												shrink: true,
											}}
											style={{ width: '100%' }}
											onChange={(e) =>
												setPreInscricao({
													...preInscricao,
													email: e.target.value,
												})
											}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										md={10}
										lg={8}
										container
										className="residencia"
										style={{ height: 50, padding: '4px 0px' }}
									>
										<DefaultButton
											// type="submit"
											variant="outlined"
											color="secondary"
											className={`${classes.btnWaitList} btn`}
											onClick={() => sendPreInscricao()}
										>
											<img
												src={require('../../../../../Assets/Arena/icon-waitList.svg').default}
												alt=""
												style={{ width: 20 }}
											/>{' '}
											&nbsp; Confirmar inscrição
										</DefaultButton>
									</Grid>
								</Grid>
							</form>
						)}
					</Grid>
				)}
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: 15,
		backgroundColor: '#000000',
		position: 'relative',
		[theme.breakpoints.down('sm')]: {
			border: 'unset',
		},
	},
	discountText: {
		fontSize: '0.85rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.75rem !important',
		},
	},
	gridDiscount: {
		borderTop: 'solid 1px #515151',
		paddingTop: '2rem !important',
		marginTop: '2rem !important',
		[theme.breakpoints.down('md')]: {
			paddingTop: '1rem !important',
			marginTop: '1rem !important',
		},
	},
	background: {
		padding: '3rem',
		width: '55% !important',
		backgroundColor: '#000000',
		borderTopLeftRadius: '15px !important',
		borderBottomLeftRadius: '15px !important',
		[theme.breakpoints.down('lg')]: {
			padding: '2em',
		},
		[theme.breakpoints.down('md')]: {
			width: '100% !important',
			borderBottomLeftRadius: '0 !important',
			borderTopRightRadius: '15px !important',
			padding: '2em 2em 1em',
		},
	},
	turmaText: {
		fontWeight: '500 !important',
		bottom: 3,
		[theme.breakpoints.down('md')]: {
			fontSize: '0.7rem !important',
		},
	},
	turmaBox: {
		borderRadius: 9,
		padding: '0.5rem 1rem',
		border: 'solid 1px #09D785',
		[theme.breakpoints.down('md')]: {
			padding: '0.5rem',
		},
	},
	spanText: {
		fontWeight: 'bold !important',
		fontSize: '1.75rem !important',
		lineHeight: 'unset !important',
		top: 3,
		[theme.breakpoints.down('md')]: {
			fontSize: '1.25rem !important',
		},
	},
	spanText2: {
		fontWeight: 'bold !important',
		fontSize: '1.25rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '1rem !important',
		},
	},
	gridContainerTurmas: {
		borderTop: 'solid 1px #515151',
		marginTop: '1rem !important',
		[theme.breakpoints.down('md')]: {
			borderTop: 'solid 1px #515151',
			marginTop: '2rem !important',
		},
	},
	btn: {
		width: '90%',
		fontSize: '1.35rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		borderColor: 'transparent',
		backgroundColor: '#00AB55 !important',
		border: '2px solid #fff !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			width: '75%',
		},
	},
	supportText: {
		fontSize: '0.9rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.8rem !important',
		},
	},
	paymentModeText: {
		fontSize: '1.625rem !important',
		lineHeight: 'unset !important',
	},
	divider: {
		margin: 'unset !important',
		backgroundColor: '#6D6D6D',
		minHeight: 1,
	},
}));
