import React from 'react';
import { Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

// COMPONENTS
import SaibaMaisCard from '../Components/Cards/SaibaMaisCard';
import Footer from '../Components/Footer';
import { Header } from '../Components/Header';
import data from '../data';

export default function TrainingPage() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					// style={matchesMobile || matchesTablet ? { marginTop: 80 } : null}
				>
					<Grid
						item
						xs={12}
					>
						<Typography
							align="left"
							color="secondary"
							className={classes.introTitle}
						>
							Todos os cursos Hardwork Medicina
						</Typography>
						<Typography
							align="left"
							className={classes.introText}
						>
							Temos 06 cursos para Residência Médica e 03 cursos para Revalida INEP
						</Typography>
						<Divider
							variant="middle"
							className={classes.divider}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						container
						className={classes.container}
					>
						<Grid
							item
							xs={12}
							style={matchesMobile ? { paddingBottom: '1em' } : { padding: '1em' }}
							className={classes.spacingText}
						>
							<Typography
								className={classes.title}
								variant="h1"
								color="secondary"
							>
								Cursos para sua Residência médica
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							md={9}
							spacing={matchesMobile ? 1 : 0}
							rowSpacing={matchesMobile ? 3 : matchesTablet ? 4 : 6}
							container
						>
							{data.sprint.map((item, index) => (
								<Grid
									item
									xs={6}
									md={4}
									key={index}
									container
									justifyContent="center"
								>
									<SaibaMaisCard
										type="residencia"
										name={item.name}
										description={item.description}
										url={item.url}
										disabled={item.disabled}
									/>
								</Grid>
							))}
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						container
						className={classes.container}
						style={{ marginTop: 50 }}
					>
						<Grid
							item
							xs={12}
							style={matchesMobile ? { padding: '1em 0' } : { padding: '1em' }}
							className={classes.spacingText}
						>
							<Typography
								className={classes.title}
								color="secondary"
							>
								Cursos para o Revalida INEP
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							spacing={matchesMobile ? 1 : 0}
							rowSpacing={matchesMobile ? 3 : matchesTablet ? 4 : 6}
							container
						>
							{data.revalida.map((item, index) => (
								<Grid
									item
									xs={6}
									key={index}
									container
									justifyContent="center"
								>
									<SaibaMaisCard
										type="revalida"
										name={item.name}
										description={item.description}
										url={item.url}
										disabled={item.disabled}
									/>
								</Grid>
							))}
						</Grid>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	divider: {
		backgroundColor: 'grey',
		minHeight: 1,
		margin: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			margin: 'unset !important',
		},
	},
	introTitle: {
		fontWeight: 'bold !important',
		fontSize: '2em !important',
		paddingLeft: '.5em',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
			lineHeight: 'unset !important',
			paddingLeft: '0',
		},
	},
	introText: {
		padding: '0.5em 0.75em 1.5em',
		fontSize: '1.324rem !important',
		[theme.breakpoints.down('sm')]: {
			padding: '0.5em 0 1.5em',
			fontSize: '1rem !important',
		},
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '2.5em !important',
		lineHeight: '1 !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '2.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
			lineHeight: 'unset !important',
		},
	},
	container: {
		paddingTop: '30px',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '1em !important',
		},
	},
	sectionMobile: {
		padding: '2vh 3% 0',
	},
	spacingText: {
		marginBottom: '35px !important',
	},
}));
