import { Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

// COMPONENTS
import Footer from '../../Components/Footer';
import { Header } from '../../Components/Header';
import LearningPath from '../../Components/Sections/LearningPath';

// ASSETS
import RevalidaHwmLogo from '../../Assets/Extensivo/Revalida/logo-trilha-revalida-migracao.svg';

// COMPONENTS
import FirstSection from '../../Components/Sections/FirstSection';
import StudentsFeedbackSection from '../../Components/Sections/StudentsFeedback';
import FlipCard from './Sale/Arena/components/FlipCard';
import MultimidiaCard from '../../Components/Cards/MultimidiaCard';
import { useRef } from 'react';
import DefaultButton from '../../Components/Buttons/DefaultButton';
import { Link } from 'react-router-dom';
import MigrationCard from '../../Components/Cards/MigrationCard';

const MigrationTexts = [
	{
		title: 'Como funciona a migração?',
		description: [
			'É bem simples! Tu poderás acessar por aqui ou pela plataforma e escolher se deseja realizar a migração. Basta clicar no botão “Realizar migração”, iniciar os estudos e se preparar para dominar o Revalida!',
			<br />,
			<br />,
			'É importante lembrar que, depois que realizar a migração, não terás mais acesso ao curso anterior, ok?!',
		],
	},
	// {
	// 	title: 'Por que eu deveria migrar?',
	// 	description: [
	// 		'Tu sabes que o Hardwork trabalha duro, com excelência em tudo o que faz! E nós desenvolvemos o Trilha Revalida em um novo modelo de plataforma, pensando justamente no melhor aprendizado que tu poderia ter!',
	// 		<br />,
	// 		<br />,
	// 		'Esse preparatório não é uma atualização do Hardwork Revalida, mas um novo curso! E nós estamos permitindo que você faça a migração, sem custo adicional – porque sabemos como seus estudos serão beneficiados por isso!',
	// 		<br />,
	// 		<br />,
	// 		'Se escolher migrar, irás descobrir como revolucionamos a maneira se de preparar para as provas com o Trilha Revalida!',
	// 	],
	// },
	{
		title: 'Quais as principais diferenças da nova plataforma?',
		description: [
			'Além do visual, estruturamos as aulas e o estudo por questões de forma a permitir um aprendizado progressivo, partindo dos temas mais simples evoluindo até os mais complexos. Além disso, o Trilha traz um bônus especial: uma biblioteca com grandes obras digitais da medicina!',
		],
	},
	{
		title: 'Em que período eu poderei realizar a migração?',
		description: ['A migração estará disponível de 25/08 a 05/09/2023.'],
	},
	{
		title: 'Com a migração como fica o meu progresso no curso anterior?',
		description: [
			'Por ser um novo curso, em uma nova plataforma, tu iniciarás uma nova caminhada no Trilha Revalida. Isso significa que o seu progresso não será importado do curso anterior. Tu iniciarás o Trilha desde o início.',
			<br />,
			<br />,
			'No entanto, as anotações feitas no "bloco de anotações" ainda estarão disponíveis.',
		],
	},
	{
		title: 'Se eu migrar, continuarei tendo acesso ao Sprint?',
		description: ['Sim. Assim como o Hardwork Revalida, o Trilha também garante acesso ao Sprint Final.'],
	},
	{
		title: 'E seu eu optar por não fazer a migração?',
		description: [
			'Sem problemas! Caso opte por não fazer a migração, tu continuarás tendo acesso à plataforma original do extensivo vigente.',
		],
	},
	{
		title: 'Até quando terei acesso ao novo preparatório Revalida?',
		description: [
			'O acesso à plataforma, incluindo o estudo por questões será até: ',
			<br />,
			<br />,
			'• 10/03/2024 para a turma 2024.1',
			<br />,
			'• 11/08/2024 para a turma 2024.2',
			<br />,
			<br />,
			'Exceção feita ao Sprint Final.',
			<br />,
			<br />,
			'• No Trilha 2024.1, o acesso ao Sprint Final dura até a data da prova da primeira fase da primeira edição do Revalida INEP 2024.',
			<br />,
			'• No Trilha 2024.2, o acesso ao Sprint Final dura até a data da prova da primeira fase da segunda edição do Revalida INEP 2024.',
		],
	},
	{
		title: 'Qualquer um pode efetuar a migração?',
		description: [
			'Não. Apenas alunos regularmente inscritos no Hardwork Revalida 2024.1 ou 2024.2 que estejam sem pendências financeiras poderão realizar a migração.',
		],
	},
	{
		title: 'Que custo eu terei com essa migração?',
		description: ['Nenhum. A migração é gratuita!'],
	},
];

// const trilhaBonus = [
// 	{
// 		id: 1,
// 		img: require('../../Assets/Extensivo/Revalida/icon-live-bank.svg').default,
// 		title: ['Banco de lives', <br />, 'Hardwork'],
// 		description: [
// 			'Acesso ao banco de lives com temas de diversas áreas, em uma conversa informal e olho-no-olho, no padrão consagrado do Hardwork Medicina!',
// 		],
// 	},
// 	{
// 		id: 2,
// 		img: require('../../Assets/Extensivo/Revalida/icon-library-access.svg').default,
// 		title: ['Leitura conjunta'],
// 		description: [
// 			'Já pensou em estudar os principais capítulos daquele livro em conjunto com o professor? Nós sabemos como esse suporte é importante e por isso estaremos contigo, revisando e relendo as principais obras relacionadas ao Revalida! ',
// 		],
// 	},
// 	{
// 		id: 3,
// 		img: require('../../Assets/Extensivo/Revalida/icon-library-access.svg').default,
// 		title: ['Biblioteca digital'],
// 		description: [
// 			'Isso mesmo! No Trilha Revalida tu terás acesso a milhares de obras consagradas. Nada de se restringir a textos resumidos, tu dominarás a prova a partir das principais referências da área médica, e nós te mostraremos como! ',
// 		],
// 	},
// 	{
// 		id: 5,
// 		img: require('../../Assets/Extensivo/Revalida/icon-resums-white.svg').default,
// 		title: ['Resumos dos', <br />, 'principais temas'],
// 		description: [
// 			'Se tu gostas de material de apoio em complemento às videoaulas, vai curtir os resumos que preparamos! Para cada aula tu encontrarás um PDF com nossos HardTopics para te ajudar nos estudos! ',
// 		],
// 	},
// 	{
// 		id: 6,
// 		img: require('../../Assets/Extensivo/Revalida/icon-postit-sheet.svg').default,
// 		title: ['Acesso ao', <br />, 'Banco de questões'],
// 		description: [
// 			'Além das questões já inseridas no cronograma de estudos, também terás acesso a um banco com as questões dos últimos 5 anos de prova do Revalida INEP.',
// 		],
// 	},
// 	{
// 		id: 7,
// 		img: require('../../Assets/Extensivo/Revalida/icon-study-by-questions.svg').default,
// 		title: ['Método de', <br />, 'estudo por questões'],
// 		description: [
// 			'O jogo da prova prática exige raciocínio rápido. ',
// 			<strong
// 				style={{ margin: '0 auto', display: 'inline' }}
// 				className="gradient-text"
// 			>
// 				Uma informação pode mudar tudo...
// 			</strong>,
// 			'são os "gatilhos de estação". No PRESENCIAL, treinamos brincando!',
// 		],
// 	},
// 	{
// 		id: 8,
// 		img: require('../../Assets/Extensivo/Revalida/icon-mock-sheet.svg').default,
// 		title: ['Simulados com', <br />, 'comentários em vídeo'],
// 		description: [
// 			'Tu terás acesso a simulados mensais, com questões que já caíram no Revalida do INEP! Assim, será possível identificar possíveis lacunas na preparação para a prova e fazer ajustes nos estudos enquanto há tempo. ',
// 		],
// 	},
// 	{
// 		id: 9,
// 		img: require('../../Assets/Extensivo/Revalida/icon-cellphone.svg').default,
// 		title: ['Contato direto', <br />, 'com o professor'],
// 		description: [
// 			'Não existe escola em que o aluno não fale com o professor! Aqui o contato é direto, todos os alunos têm acesso aos professores, tanto nos times, quanto via WhatsApp.',
// 		],
// 	},
// 	{
// 		id: 10,
// 		img: require('../../Assets/Extensivo/Revalida/icon-bonfire.svg').default,
// 		title: ['Métricas', <br />, 'do aprendizado'],
// 		description: [
// 			'Te mostraremos toda a tua evolução de acordo com os simulados que farás. Dessa forma, poderás identificar os temas que domina e aqueles em que precisarás revisar.',
// 		],
// 	},
// ];

const trilhaBonus = [
	{
		id: 1,
		img: require('./Sale/SprintFinal/assets/icon-teacher-orientation-white.svg').default,
		title: ['Aulas dos', <br />, 'principais temas'],
		description: [
			'Dois dias de programação',
			<strong
				style={{ margin: '0 auto' }}
				className="gradient-text"
			>
				intensa e muito dinâmica
			</strong>,
			'para entrar de vez na alma da prova prática do INEP.',
		],
	},
	{
		id: 2,
		img: require('../../Assets/Extensivo/Revalida/icon-live-bank.svg').default,
		title: ['Banco de lives', <br />, 'Hardwork'],
		description: [
			'Mais de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				20 estações para treinamento intenso
			</strong>,
			'; todas as estações simulam em detalhes a prova prática do INEP.',
		],
	},
	{
		id: 3,
		img: require('../../Assets/Extensivo/Revalida/icon-library-access.svg').default,
		title: ['Acesso aos principais livros*'],
		description: [
			'Treinamento de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				habilidades específicas
			</strong>,
			' em pequenos grupos.',
		],
	},
	{
		id: 4,
		img: require('./Sale/SprintFinal/assets/icon-dot-white.svg').default,
		title: ['Questões', <br />, 'Temáticas'],
		description: [
			'No presencial temos uma oportunidade adicional de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				aprender estações e aprender checklists
			</strong>,
			'.',
		],
	},
	{
		id: 5,
		img: require('../../Assets/Extensivo/Revalida/icon-resums-white.svg').default,
		title: ['Resumos dos', <br />, 'principais temas'],
		description: [
			'Orientações personalizadas do time Hardwork, com ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				professores e instrutores que já dominaram
			</strong>,
			' a prova prática do INEP.',
		],
	},
	{
		id: 6,
		img: require('../../Assets/Extensivo/Revalida/icon-postit-sheet.svg').default,
		title: ['Acesso ao', <br />, 'Banco de questões'],
		description: [
			'Momentos de integração com teus colegas, professores e instrutores. ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				Trocas riquíssimas
			</strong>,
			' durante os dois dias.',
		],
	},
	{
		id: 7,
		img: require('../../Assets/Extensivo/Revalida/icon-study-by-questions.svg').default,
		title: ['Método de', <br />, 'estudo por questões'],
		description: [
			'O jogo da prova prática exige raciocínio rápido. ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				Uma informação pode mudar tudo...
			</strong>,
			'são os "gatilhos de estação". No PRESENCIAL, treinamos brincando!',
		],
	},
	// {
	// 	id: 8,
	// 	img: require('../../Assets/Extensivo/Revalida/icon-tutor-lives.svg').default,
	// 	title: ['Lives de tutoria'],
	// 	description: [
	// 		'Momentos de integração com teus colegas, professores e instrutores. ',
	// 		<strong
	// 			style={{ margin: '0 auto', display: 'inline' }}
	// 			className="gradient-text"
	// 		>
	// 			Trocas riquíssimas
	// 		</strong>,
	// 		' durante os dois dias.',
	// 	],
	// },
	{
		id: 8,
		img: require('../../Assets/Extensivo/Revalida/icon-mock-sheet.svg').default,
		title: ['Simulados com', <br />, 'comentários em vídeo'],
		description: [
			'Momentos de integração com teus colegas, professores e instrutores. ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				Trocas riquíssimas
			</strong>,
			' durante os dois dias.',
		],
	},
	{
		id: 9,
		img: require('../../Assets/Extensivo/Revalida/icon-cellphone.svg').default,
		title: ['Contato direto', <br />, 'com o professor'],
		description: [
			'Momentos de integração com teus colegas, professores e instrutores. ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				Trocas riquíssimas
			</strong>,
			' durante os dois dias.',
		],
	},
	{
		id: 10,
		img: require('../../Assets/Extensivo/Revalida/icon-bonfire.svg').default,
		title: ['Métricas', <br />, 'do aprendizado'],
		description: [
			'Momentos de integração com teus colegas, professores e instrutores. ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				Trocas riquíssimas
			</strong>,
			' durante os dois dias.',
		],
	},
];

const sprintFinal = [
	{
		id: 1,
		img: require('../../Assets/Extensivo/Revalida/icon-sprint-watch.svg').default,
		title: ['Acesso antecipado', <br />, 'ao Sprint'],
		description: [
			'Orientações personalizadas do time Hardwork, com ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				professores e instrutores que já dominaram
			</strong>,
			' a prova prática do INEP.',
		],
	},
	{
		id: 2,
		img: require('./Sale/SprintFinal/assets/icon-global.svg').default,
		title: ['100% Online'],
		description: [
			'Mais de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				20 estações para treinamento intenso
			</strong>,
			'; todas as estações simulam em detalhes a prova prática do INEP.',
		],
	},
	{
		id: 3,
		img: require('../../Assets/Extensivo/Revalida/icon-cyclic-revisions.svg').default,
		title: ['Revisões cíclicas', <br />, 'automáticas'],
		description: [
			'Orientações personalizadas do time Hardwork, com ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				professores e instrutores que já dominaram
			</strong>,
			' a prova prática do INEP.',
		],
	},
	{
		id: 4,
		img: require('./Sale/SprintFinal/assets/icon-dot-white.svg').default,
		title: ['Temas mais relevantes para', <br />, 'prova do Revalida-INEP'],
		description: [
			'No presencial temos uma oportunidade adicional de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				aprender estações e aprender checklists
			</strong>,
			'.',
		],
	},
	{
		id: 5,
		img: require('./Sale/SprintFinal/assets/icon-teacher-orientation-white.svg').default,
		title: ['Para cada tema, uma', <br />, 'orientação em vídeo do professor'],
		description: [
			'Mais de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				20 estações para treinamento intenso
			</strong>,
			'; todas as estações simulam em detalhes a prova prática do INEP.',
		],
	},
	{
		id: 6,
		img: require('../../Assets/Extensivo/Revalida/icon-mock-sheet.svg').default,
		title: ['Questões comentadas de todas as provas do Revalida INEP'],
		description: [
			'Momentos de integração com teus colegas, professores e instrutores. ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				Trocas riquíssimas
			</strong>,
			' durante os dois dias.',
		],
	},
	{
		id: 7,
		img: require('./Sale/SprintFinal/assets/icon-mock-test.svg').default,
		title: ['Grupos de discussão'],
		description: [
			'Orientações personalizadas do time Hardwork, com ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				professores e instrutores que já dominaram
			</strong>,
			' a prova prática do INEP.',
		],
	},
	{
		id: 8,
		img: require('../../Assets/Extensivo/Revalida/icon-bell.svg').default,
		title: ['Alerta de necessidade de', <br />, 'revisão'],
		description: [
			'Treinamento de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				habilidades específicas
			</strong>,
			' em pequenos grupos.',
		],
	},
	{
		id: 9,
		img: require('../../Assets/Extensivo/Revalida/icon-dm-teacher.svg').default,
		title: ['Grupo de discussão com', <br />, 'professores e hardworkers'],
		description: [
			'Treinamento de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				habilidades específicas
			</strong>,
			' em pequenos grupos.',
		],
	},
	{
		id: 10,
		img: require('../../Assets/Extensivo/Revalida/icon-brain-engine.svg').default,
		title: ['Treinamento', <br />, 'personalizado'],
		description: [
			'Orientações personalizadas do time Hardwork, com ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				professores e instrutores que já dominaram
			</strong>,
			' a prova prática do INEP.',
		],
	},
	{
		id: 11,
		img: require('./Sale/SprintFinal/assets/icon-head.svg').default,
		title: ['Orientaçaõ para recursos', <br />, 'da prova'],
		description: [
			'Treinamento de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				habilidades específicas
			</strong>,
			' em pequenos grupos.',
		],
	},
	{
		id: 12,
		img: require('./Sale/SprintFinal/assets/icon-direct-contact.svg').default,
		title: ['Contato direto com os', <br />, 'professores'],
		description: [
			'Orientações personalizadas do time Hardwork, com ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				professores e instrutores que já dominaram
			</strong>,
			' a prova prática do INEP.',
		],
	},
];

const learningPathTemplate = [
	{
		title: 'Primeira liga',
		description: [
			'A trilha do aprendizado funciona por ligas de evolução. Começaremos por temas mais palpáveis, evoluindo para outros mais peculiares.',
			<>
				<br />
				<br />
			</>,
			'São 8 ligas no total, cada uma com temas diferentes. Para passar para a próxima, precisaremos dominar por completo a atual.',
			<>
				<br />
				<br />
			</>,
			'Veja como tudo vai funcionar em cada liga:',
		],
		bgColor: '#ffffff',
		titleColor: '#121212',
		descriptionColor: 'primary',
		// url: 'https://player.vimeo.com/video/769930065',
	},
	{
		title: 'Aula Hardwork',
		description: [
			'Tudo começa com a apresentação ao tema, que no Hardwork é feito com rigor em didática.',
			<>
				<br />
				<br />
			</>,
			'São as melhores aulas que você já viu sobre cada tema.',
		],
		bgColor: '#0D0D0D',
		titleColor: '#09D785',
		descriptionColor: 'secondary',
		// url: 'https://player.vimeo.com/video/769929590',
	},
	{
		title: 'Estudo por questões',
		description: [
			'Alguns dias após a apresentação ao tema, vamos te conduzir pelo estudo por questões.',
			<>
				<br />
				<br />
			</>,
			'Aqui você entenderá todas as formas em que cada parte do tema é explorado em questões.',
			<>
				<br />
				<br />
			</>,
			'O professor Hardwork te mostrará tudo em detalhes, aprofundando em cada tema.',
		],
		bgColor: '#0D0D0D',
		titleColor: '#09D785',
		descriptionColor: 'secondary',
		// url: 'https://player.vimeo.com/video/769929662',
	},
	{
		title: 'Revisões cíclicas e personalizadas',
		description: [
			'Questões, questões e questões! É assim que revisamos no Hardwork!',
			<>
				<br />
				<br />
			</>,
			'Toda a dinâmica de revisões é, na verdade, uma continuidade do estudo por questões, explorando com mais ênfase os pontos que você tiver mais dificuldade.',
			<>
				<br />
				<br />
			</>,
			'Todos os temas precisam ser dominados!',
		],
		bgColor: '#0D0D0D',
		titleColor: '#09D785',
		descriptionColor: 'secondary',
		// url: 'https://player.vimeo.com/video/769929881',
	},
	{
		title: 'Avaliação de desempenho',
		description: [
			'A regra é dominar temas! A avaliação de desempenho e domínio dos temas será diária, usando métricas do estudo por questões.',
			<>
				<br />
				<br />
			</>,
			'Assim que o grau de suficiência em um tema for atingido, ele estará dominado! Domine todos e migre para a próxima liga de aprendizado.',
			<>
				<br />
				<br />
			</>,
			'"E se eu tiver dificuldade"? Calma! Siga a trilha...',
		],
		bgColor: '#0D0D0D',
		titleColor: '#09D785',
		descriptionColor: 'secondary',
		// url: 'https://player.vimeo.com/video/769929824',
	},
	{
		title: 'Estude com professor',
		description: [
			'Teve dificuldade? O professor estudará contigo!',
			<>
				<br />
				<br />
			</>,
			'Isso mesmo... vamos abrir o livro juntos, entender frase por frase, parágrafo por parágrafo, o tema ou seu fragmento!',
			<br />,
			'Vamos fazer isso usando a principal referência disponível, que estará à sua disposição na biblioteca virtual do Trilha.',
			<>
				<br />
				<br />
			</>,
			'Após o estudo em conjunto, nova avaliação de desempenho!',
		],
		bgColor: '#0D0D0D',
		titleColor: '#09D785',
		descriptionColor: 'secondary',
		// url: 'https://player.vimeo.com/video/769929949',
	},
	{
		title: 'Próxima liga',
		description: [
			'A evolução é contínua, são 8 ligas no total.',
			<>
				<br />
				<br />
			</>,
			'Na liga do aprendizado seguinte teremos novos temas, ou fragmentos complementares de temas anteriores.',
			<>
				<br />
				<br />
			</>,
			'Mas não pense que os já vistos serão esquecidos... jamais!',
			<>
				<br />
				<br />
			</>,
			'As revisões serão contínuas, até o fim!',
		],
		bgColor: '#ffffff',
		titleColor: '#121212',
		descriptionColor: 'primary',
		// url: 'https://player.vimeo.com/video/769929780',
	},
];

export default function TrilhaRevalidaMigracao2023() {
	const classes = useStyles();
	const theme = useTheme();
	const subscribe_card = useRef(null);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const executeScroll = () => subscribe_card.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="revalida"
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<FirstSection
					backgroundImageUrl={process.env.PUBLIC_URL + '/Assets/Img/bg-trilha-revalida.png'}
					logoUrl={RevalidaHwmLogo}
					type="green"
					buttonText="Realizar migração"
					executeScroll={executeScroll}
					firstTextPaper={[
						<Typography
							align="left"
							color="secondary"
							className={`${classes.firstText} roboto-condensed`}
						>
							Hora de trilhar o aprendizado.
						</Typography>,
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							A nova preparação revolucionária
							<br />
							para a prova do{' '}
							<strong
								className={`${classes.gradientText} gradient-text roboto-condensed`}
								style={matchesMobile ? { display: 'block' } : { display: 'inline' }}
							>
								Revalida INEP.
							</strong>
						</Typography>,
						<Grid
							item
							container
						>
							<Divider
								variant="middle"
								className={classes.divider2}
							/>
						</Grid>,
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text2} poppins`}
						>
							{matchesMobile ? (
								<>
									A junção do Extensivo Hardwork com o
									<p
										className="gradient-text"
										style={{ display: 'inline' }}
									>
										{' '}
										Sprint Final Revalida{' '}
									</p>
									num novo formato.
									<br />
									<br />
									Aqui a gente aprende de verdade e domina a prova!
								</>
							) : (
								<>
									A junção do Extensivo Hardwork com o
									<p
										className="gradient-text"
										style={{ display: 'inline' }}
									>
										{' '}
										Sprint Final Revalida{' '}
									</p>
									num novo formato.
									<br />
									<br />
									Aqui a gente aprende de verdade e domina a prova!
								</>
							)}
						</Typography>,
					]}
					imageProps={{
						[theme.breakpoints.down('lg')]: {
							top: '1.75em',
						},
					}}
					buttonProps={{
						color: 'secondary',
						buttonLogoUrl: require('../../Assets/Svg/Icons/suitcaseIcon.svg').default,
						height: '1.75rem',
					}}
				/>
			</Container>
			<LearningPath
				title={'O que é a Trilha do aprendizado definitivo?'}
				cardsInfo={learningPathTemplate}
				background={'white-background'}
			/>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid className={classes.containerBackgroundColor}>
					<Grid
						item
						xs={12}
						container
						style={matchesMobile ? { marginBottom: 16, paddingLeft: '1em' } : { left: '2em' }}
						className="revalida"
					>
						<Typography
							className={`${classes.subtitle1} roboto-condensed gradient-text`}
							color="primary"
						>
							Além da trilha do aprendizado você também ganha
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						container
						justifyContent={'center'}
						style={{ marginTop: matchesMobile ? 0 : '2rem', paddingBottom: '2rem' }}
					>
						{trilhaBonus.map((item, index) => {
							return (
								<Grid
									key={item.id}
									item
									xs={6}
									md={/*activeButtonId === 1 ? 4 : */ 3}
									container
									justifyContent={'center'}
									className={`${classes.gridFlipCard} revalida`}
								>
									<FlipCard
										noFlip
										key={item.id}
										image={item.img}
										title={item.title}
										description={item.description}
										color="secondary"
										mobile={matchesMobile}
										size={/*activeButtonId === 1 ? 4 : */ 3}
										classes={classes}
									/>
								</Grid>
							);
						})}
					</Grid>
					<Grid
						item
						xs={12}
						container
						alignContent="center"
						justifyContent="flex-start"
					>
						<Typography
							color="secondary"
							className={`${classes.thirdSectionText} poppins`}
						>
							*Bônus.
						</Typography>
					</Grid>
				</Grid>
				<Grid
					className={classes.containerBackgroundColor}
					style={matchesMobile ? { paddingBottom: '2rem' } : null}
				>
					<Grid
						item
						xs={12}
						container
						style={
							matchesMobile
								? { marginBottom: 16, paddingLeft: '1em' }
								: { left: '2em', marginBottom: '2rem' }
						}
						className="revalida"
					>
						<Typography
							className={`${classes.subtitle1} roboto-condensed gradient-text`}
							color="primary"
						>
							E na hora da revisão, receba antecipadamente
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						container
						justifyContent="center"
						style={matchesMobile ? { marginBottom: '1rem' } : null}
					>
						<Grid
							item
							xs={6}
							container
							justifyContent={'center'}
							alignContent={'center'}
							style={{ borderRight: '2px solid #6D6D6D' }}
						>
							<img
								src={require('./Sale/SprintFinal/assets/logo-new-sf-revalida.svg').default}
								alt=""
							/>
						</Grid>
						<Grid
							item
							xs={6}
							container
							justifyContent={'center'}
							alignContent={'center'}
							style={{ padding: matchesMobile ? '0 1rem' : '0 3rem' }}
						>
							<Typography
								color="secondary"
								className={`${classes.sprintText} poppins`}
							>
								Acesso garantido e antecipado ao <strong>Sprint Final Revalida</strong>, a única revisão
								realmente focada na prova do Inep.
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						container
						justifyContent={'center'}
						style={{ marginTop: matchesMobile ? 0 : '2rem', paddingBottom: '2rem' }}
					>
						{sprintFinal.map((item, index) => {
							return (
								<Grid
									key={item.id}
									item
									xs={6}
									md={/*activeButtonId === 1 ? 4 : */ 3}
									container
									justifyContent={'center'}
									className={`${classes.gridFlipCard} revalida`}
								>
									<FlipCard
										noFlip
										key={item.id}
										image={item.img}
										title={item.title}
										description={item.description}
										color="secondary"
										mobile={matchesMobile}
										size={/*activeButtonId === 1 ? 4 : */ 3}
										classes={classes}
									/>
								</Grid>
							);
						})}
					</Grid>
					<Grid
						item
						xs={12}
						container
						justifyContent={'center'}
					>
						<Divider
							variant="middle"
							className={classes.divider}
						/>
					</Grid>
					<Grid
						container
						justifyContent={matchesTablet ? 'center' : 'space-around'}
						alignContent={'center'}
						style={{ margin: matchesMobile ? '1rem auto' : '2rem auto', padding: '0 2rem' }}
					>
						<MultimidiaCard
							imgUrl={require('../LandingPages/Sale/SprintFinal/assets/logo-multimidia.svg').default}
							description={'Ganhe acesso ao treinamento multimídia 2023 do HWM'}
						/>
					</Grid>
				</Grid>
			</Container>

			<Grid
				container
				className={classes.backgroundArrows}
				style={{ padding: '0px' }}
				ref={subscribe_card}
			>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					<Grid
						container
						className="section"
					>
						<Grid
							item
							container
							justifyContent={'center'}
							alignItems={'center'}
							className={classes.gridMigrationCard}
							style={{
								backgroundImage: `url(${require('../../Assets/Extensivo/Revalida/bg-trilha-migration.png')})`,
								marginTop: '2rem !important',
							}}
						>
							<img
								src={require('../../Assets/Extensivo/Revalida/logo-trilha-migration.svg').default}
								alt=""
								className={classes.logoTrilhaMigration}
							/>
							<Grid
								item
								container
								alignContent={'center'}
								justifyContent={'center'}
								className={classes.gridBtn}
							>
								<DefaultButton
									variant="outlined"
									color="secondary"
									className={`${classes.btn} btn button-info-card`}
									component={Link}
									to={`//hardworkmedicina.com.br/meus-treinamentos/extensivo?RevalidaMigracaoTrilha`}
								>
									{matchesMobile ? null : (
										<img
											src={require('../../Assets/Svg/Icons/suitcaseIcon.svg').default}
											alt=""
											style={{ height: '1.5rem' }}
										/>
									)}
									&nbsp; Realizar migração
								</DefaultButton>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Grid>

			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						container
						className="revalida"
						style={matchesMobile ? { paddingBottom: '1em' } : { paddingBottom: '2em' }}
					>
						<Typography
							className={`roboto-condensed`}
							color="secondary"
							style={
								matchesMobile
									? { fontSize: '1.6rem', fontWeight: 'bold' }
									: { fontSize: '3.5rem', fontWeight: 'bold', lineHeight: 'unset' }
							}
						>
							O que mudará ao migrar para o novo Trilha Revalida?
							{/* Dúvidas que costumam surgir: */}
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						container
						spacing={matchesMobile ? 0 : 3}
						rowSpacing={matchesMobile ? 2 : 0}
					>
						{MigrationTexts.map((item, index) => {
							return (
								<Grid
									item
									xs={12}
									md={6}
									key={index}
								>
									<MigrationCard
										id={index}
										key={index}
										title={item.title}
										description={item.description}
										matchesMobile={matchesMobile}
										matchesTablet={matchesTablet}
										color="#09D785"
									/>
								</Grid>
							);
						})}
					</Grid>
				</Grid>
			</Container>

			<StudentsFeedbackSection type="revalida" />
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	gradientText: {
		fontSize: '2.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '3rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down(901)]: {
			fontSize: '2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.75rem !important',
		},
	},
	firstText: {
		fontSize: '2.65rem !important',
		// lineHeight: 'unset !important',
		fontWeight: '600 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down(361)]: {
			fontSize: '1.4rem !important',
		},
	},
	text: {
		fontSize: '1.85rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.05rem !important',
		},
		[theme.breakpoints.down(361)]: {
			fontSize: '1rem !important',
		},
	},
	text2: {
		fontSize: '1.45rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.05rem !important',
		},
	},
	subtitle1: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.65rem !important',
			lineHeight: 'unset !important',
		},
	},
	containerBackgroundColor: {
		backgroundColor: '#33333380',
		margin: '60px auto 30px',
		borderRadius: 15,
		maxWidth: '1410px !important',
		padding: '2em 2.5% 3vh',
		[theme.breakpoints.down('sm')]: {
			padding: '3vh 2.5% 0',
			borderRadius: 'unset',
		},
	},
	divider: {
		minHeight: 1,
		backgroundColor: '#6D6D6D',
		width: '98%',
		margin: '2rem 0 !important',
	},
	divider2: {
		minHeight: 1,
		backgroundColor: '#6D6D6D',
		width: '98%',
		margin: '1rem 0 !important',
	},
	btn: {
		width: '70%',
		fontSize: '1.35rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		borderColor: 'transparent',
		backgroundColor: '#00AB55 !important',
		border: '2px solid #fff !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			width: '90%',
		},
	},
	sprintText: {
		fontSize: '1.35rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
		},
	},
	backgroundArrows: {
		backgroundImage: `url(${require('../../Assets/Extensivo/Revalida/bg-arrows-trilha-revalida.png')})`,
		backgroundSize: 'cover',
		padding: '0 0 3rem',
		[theme.breakpoints.down('md')]: {
			paddingTop: '3rem',
		},
		[theme.breakpoints.down('sm')]: {
			backgroundImage: `url(${require('../../Assets/Extensivo/Revalida/bg-arrows-trilha-revalida-mobile.png')})`,
			paddingTop: 'unset',
			marginTop: '3rem',
		},
	},
	logoTrilhaMigration: {
		[theme.breakpoints.down('xl')]: {
			width: '80%',
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	gridFlipCard: {
		margin: '15px 0 !important',
		height: 270,
		padding: '0 1rem',
		[theme.breakpoints.down('sm')]: {
			padding: 'unset',
			height: 160,
		},
	},
	gridVideoCard: {
		backgroundColor: '#ffffff',
		borderRadius: '32px',
		width: '65% !important',
		height: '430px',
		[theme.breakpoints.down('md')]: {
			height: '355px',
			width: '85% !important',
		},
	},
	gridBtn: {
		width: '70% !important',
		[theme.breakpoints.down('md')]: {
			width: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			width: '85% !important',
		},
	},
	gridMigrationCard: {
		border: '2px solid #ffffff',
		borderRadius: '17px',
		rowGap: '2rem',
		padding: '2rem 5rem',
		width: '100% !important',
		margin: '0 auto !important',
		[theme.breakpoints.down('sm')]: {
			padding: '2rem',
			marginBottom: '2rem !important',
		},
	},
	thirdSectionText: {
		fontSize: '1.12rem !important',
		fontStyle: 'italic !important',
		color: '#ffffff80 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
}));
