import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function LiveInfoCard({ title, description }) {
	const classes = useStyles();
	return (
		<Grid
			item
			xs={10}
			container
			direction={'column'}
			className={`${classes.root}`}
		>
			<Grid
				item
				container
				alignContent={'space-evenly'}
				className={`${classes.gridText}`}
			>
				<Typography
					color="secondary"
					className={`${classes.title} roboto-condensed`}
				>
					{title}
				</Typography>
				<Typography
					color="secondary"
					className={`${classes.text} poppins`}
				>
					{description}
				</Typography>
			</Grid>
			<Grid
				item
				className={
					window.location.pathname.includes('revalida')
						? 'background-ldl-vertical-revalida'
						: 'background-central-concursos-vertical'
				}
				// className="background-central-concursos-vertical"
				style={{ width: '2rem', height: '100%', borderTopRightRadius: 15, borderBottomRightRadius: 15 }}
			></Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		// borderTopRightRadius: 15,
		// borderBottomRightRadius: 15,
		paddingRight: '2rem',
		width: '85%',
		[theme.breakpoints.down('sm')]: {
			paddingRight: '1rem',
		},
	},
	title: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
			marginBottom: '0.5rem !important',
		},
	},
	gridText: {
		padding: '0.5rem 2rem 1.5rem 15.5rem',
		height: 230,
		backgroundColor: '#3b3b3bb3',
		[theme.breakpoints.down(1550)]: {
			padding: '0.5rem 2rem 1.5rem 3.5rem',
		},
		[theme.breakpoints.down('xl')]: {
			padding: '0px 2rem 0 7rem',
			height: 200,
		},
		[theme.breakpoints.down('md')]: {
			padding: '0 2rem 0 7rem',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0 1.2rem',
		},
	},
	text: {
		fontSize: '1.3125rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.9rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
}));
