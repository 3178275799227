import React, { lazy, Suspense, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

// INSTITUTIONAL
import Home from './Pages/Home.jsx';
import Faq from './Pages/Faq.jsx';
import Contact from './Pages/Contact.jsx';
import AboutUs from './Pages/AboutUs.jsx';
import Products from './Pages/Products.jsx';
import ResidenciaMedica2023 from './Pages/ResidenciaMedica-2023.jsx';
import Revalida2023 from './Pages/Revalida-2023.jsx';

// LANDING PAGES
import ExtensivoR12023 from './Pages/LandingPages/ExtensivoR1-2023.jsx';
import ExtensivoR12024 from './Pages/LandingPages/ExtensivoR1-2024.jsx';
import TrilhaRevalida232425 from './Pages/LandingPages/TrilhaRevalida-2023-24-25.jsx';
// import TrilhaRevalida232425old from './Pages/LandingPages/TrilhaRevalida-24-25-old.jsx';
import TrilhaRevalida232425Express from './Pages/LandingPages/TrilhaRevalida-2023-24-25-Express.jsx';
import ExtensivoR1Migracao2023 from './Pages/LandingPages/ExtensivoR1-Migracao-2023.jsx';
import ExtensivoR120232024 from './Pages/LandingPages/ExtensivoR1-2023-2024.jsx';
import ExtensivoR3ClinicaMedica from './Pages/LandingPages/ExtensivoR3-Clinica-Medica-2023.jsx';
import ExtensivoR3CirurgiaGeral from './Pages/LandingPages/ExtensivoR3-Cirurgia-Geral-2023.jsx';
import TrilhaRevalidaMigracao2023 from './Pages/LandingPages/TrilhaRevalida-Migracao-2023.jsx';
import AppAssesmentPage from './Pages/LandingPages/App-Assesment.jsx';
// import LiveDasLives from './Pages/LandingPages/Live-das-lives-residencia-2023.jsx';
import LiveDasLives from './Pages/LandingPages/Live-das-lives-residencia-2024.jsx';
import LiveDeCorrecao from './Pages/LandingPages/Live-de-correcao.jsx';
import RevalidaResearchPage from './Pages/LandingPages/Revalida-Research.jsx';
import ResearchRevalida27042024 from './Pages/LandingPages/Revalida/Research-27-04-2024.jsx';
import HardworqDownloadRedirect from './Pages/LandingPages/Hardworq/Hardworq-Download.jsx';

// SUBSCRIPTION
import ExtensivoR12324Subscription from './Pages/LandingPages/Subscription/Residencia/ExtensivoR1-2023-2024-Subscription.jsx';
// import LiveDasLivesRevalida2023 from './Pages/LandingPages/Sale/LDL/Revalida-2023.jsx';
import Arena from './Pages/LandingPages/Subscription/Arena/Arena-2023.jsx';
import SubscriptionSouHardwork from './Pages/LandingPages/Subscription/Residencia/SouHardwork-2023.jsx';
import SubscriptionSouHardworkForm from './Pages/LandingPages/Subscription/Residencia/SouHardwork-Form.jsx';
import TestsLibrarySubscriptionPage from './Pages/LandingPages/Subscription/Residencia/Tests-Library-Subscription-Page.jsx';
import EnareReviewSubscriptionPage from './Pages/LandingPages/Subscription/Residencia/Enare-Review-2023.jsx';
// import LiveDasLivesResidenciaTemplate2023 from './Pages/LandingPages/Sale/LDL/ResidenciaTemplate-2023.jsx';
import RevalidaTemplate2024 from './Pages/LandingPages/Sale/LDL/RevalidaTemplate-2024.jsx';
import LiveDemo from './Pages/LandingPages/Subscription/Arena/Live-Demo.jsx';
import LiveProvaDiscursivaRevalida from './Pages/LandingPages/Subscription/Revalida/Live-Prova-Discursiva.jsx';
import TemplateSalePage20232 from './Pages/LandingPages/Subscription/Residencia/Template-Sale-2023-2.jsx';
import LiveR3191023Subscription from './Pages/LandingPages/Subscription/Residencia/Live-R3-19-10-2023.jsx';
import LiveR1251023Subscription from './Pages/LandingPages/Subscription/Residencia/Live-R1-25-10-2023.jsx';
import LiveMockTestUnicampSubscription from './Pages/LandingPages/Subscription/Residencia/Live-Mock-Test-Unicamp.jsx';
import RaioXTemplateSubscription from './Pages/LandingPages/Subscription/Residencia/Raio-X-Template.jsx';
import TrilhaR3Cm from './Pages/LandingPages/TrilhaR3-Cm-2024.jsx';
import SprintFinal2024Pre from './Pages/LandingPages/Subscription/Residencia/Sprint-final-2024.jsx';
import SubscriptionEventArena20242 from './Pages/LandingPages/Subscription/Arena/Pre-inscricao-area-2024-2.jsx';
import LiveDasLivesResidenciaTemplate2024 from './Pages/LandingPages/Sale/LDL/ResidenciaTemplate-2024.jsx';

// SALE
// import ArenaSale2023 from './Pages/LandingPages/Sale/Arena/Arena-sale-2023.jsx';
import SprintFinalRevalida from './Pages/LandingPages/Sale/SprintFinal/SprintFinal-Revalida.jsx';
// import SprintFinalResidencia2023 from './Pages/LandingPages/Sale/SprintFinal/SprintFinal-Residencia-2023.jsx';
import SprintFinalResidencia2024 from './Pages/LandingPages/Sale/SprintFinal/SprintFinal-Residencia-2024-2.jsx';
import SprintFinalEnare from './Pages/LandingPages/Sale/SprintFinal/SprintFinal-Enare.jsx';

import ArenaSale2024 from './Pages/LandingPages/Sale/Arena/Arena-sale-2024.jsx';
import ArenaSale20242 from './Pages/LandingPages/Sale/Arena/Arena-Sale-Online-2024-2.jsx';
// import ArenaSale2024Pro from './Pages/LandingPages/Sale/Arena/Arena-sale-pro.jsx';
import CorrectionArena20241 from './Pages/LandingPages/Correction/Correction-Area-2024-1.jsx';
import CorrectionArena20242Materiais from './Pages/LandingPages/Correction/Correction-Area-2024-2-Materials.jsx';
import SubscriptionHardworkJuazeiro from './Pages/LandingPages/Subscription/Hardwork-Cidades/SalePageCidadesJuazeiroNorte.jsx';
import SubscriptionHardworkTeresina from './Pages/LandingPages/Subscription/Hardwork-Cidades/SalePageCidadesTeresina.jsx';
import SubscriptionHardworkJua from './Pages/LandingPages/Subscription/Hardwork-Cidades/SalePageCidadesJuazeiro.jsx';
import TrilhaR1Residencia2025 from './Pages/LandingPages/Sale/R1/TrilhaR1-2025.jsx';

//HARDWORQ
import RadarChartPerformance from './Components/Hardworq/RadarPerfomance/index.jsx';
import RadarQuestions from './Components/Hardworq/RadarQuestions/index.jsx';
import StackedBarChart from './Components/Hardworq/StackedChart/index.jsx';

// FREE CONTENT
import ContestCentral2023 from './Pages/LandingPages/FreeContent/ContestCentral/ContestCentral-2023.jsx';
import ContestTemplate from './Pages/LandingPages/FreeContent/ContestCentral/ContestTemplate.jsx';
import routesProductsTemplate from './Components/Utils/RoutesConfig/RoutesProductsTemplate.jsx';
import LoaderSpinner from './Components/LoaderSpinner.jsx';
import LegiaoHardworkResidencia from './Pages/LandingPages/Legiao-hardwork.jsx';
import LegiaoHardworkResidencia2 from './Pages/LandingPages/Legiao-hardwork2.jsx';
import PesquisaHQ from './Pages/LandingPages/Hardworq/PesquisaHQ.jsx';

const LiveBank = lazy(() => import('./Pages/LandingPages/FreeContent/LiveBank/LiveBank.jsx'));
const ExtensivoR12023Idomed = lazy(() => import('./Pages/LandingPages/ExtensivoR1-2023-Idomed.jsx'));
const CampinaGrande2023 = lazy(() =>
	import('./Pages/LandingPages/FreeContent/EmergencyCourse/Campina-Grande-2023.jsx')
);
const LiveTipsRevalida08052023 = lazy(() =>
	import('./Pages/LandingPages/Subscription/Revalida/Live-08-05-2023-Tips.jsx')
);
const YduqsPage = lazy(() => import('./Pages/LandingPages/FreeContent/Yduqs/YduqsPage.jsx'));
const ExtensivoR120232024Idomed = lazy(() => import('./Pages/LandingPages/ExtensivoR1-2023-2024-Idomed.jsx'));
const TestsTemplatePage = lazy(() => import('./Pages/LandingPages/FreeContent/TestsLibrary/Tests-Template-Page.jsx'));
const LiveDasLivesSubscription = lazy(() => import('./Pages/LandingPages/Live-das-lives-subscription.jsx'));
const SprintFinalR32023 = lazy(() => import('./Pages/LandingPages/Sale/SprintFinal/SprintFinalR3-2023.jsx'));
const DiscursiveMockUnicamp = lazy(() =>
	import('./Pages/LandingPages/FreeContent/DiscursiveMockUnicamp/Mock-Unicamp-2023.jsx')
);
const EditalLinkRevalida20241 = lazy(() => import('./Pages/LandingPages/Subscription/Revalida/Edital-Link-2024-1.jsx'));
const TrilhaR120242025 = lazy(() => import('./Pages/LandingPages/TrilhaR1-2024-2025.jsx'));
const SemanaEletrocardiograma = lazy(() =>
	import('./Pages/LandingPages/FreeContent/TrilhaR1+/Semana-Eletrocardiograma.jsx')
);
const SemanaTEP = lazy(() => import('./Pages/LandingPages/FreeContent/TrilhaR1+/Semana-TEP.jsx'));
const DesafioEnare = lazy(() => import('./Pages/LandingPages/FreeContent/TrilhaR1+/Desafio-Enare.jsx'));
const RevalidaDownload = lazy(() => import('./Pages/LandingPages/Revalida/Revalida-downloads-2024.jsx'));
// import RevalidaSpecialEdition from './Pages/LandingPages/Revalida/Revalida-ldl-special-edition.jsx';

// import RevalidaEdicaoEspecial from './Pages/LandingPages/FreeContent/TrilhaR1+/Revalida-Edicao-Especial.jsx';
const HardworkSolidario = lazy(() =>
	import('./Pages/LandingPages/FreeContent/HardworkSolidario/hardworksolidario.jsx')
);
const TemplateHwmSolidario = lazy(() =>
	import('./Pages/LandingPages/FreeContent/HardworkSolidario/template-hwm-solidario.jsx')
);
const DemoPage = lazy(() => import('./Pages/LandingPages/FreeContent/DemoPage/DemoPage.jsx'));
const TrilhaR1Express2024 = lazy(() => import('./Pages/LandingPages/TrilhaR1-express-2024.jsx'));

// import RevalidaFormLdl from './Pages/LandingPages/Revalida/RevalidaForm-ldl.jsx'; // LP de Pré inscrição  - Sempre volta
const RegistrationEventFozRevalida = lazy(() =>
	import('./Pages/LandingPages/FreeContent/HardworkEm/FozDoIguacu/Registration-03-06-2023.jsx')
);
const RegistrationEventSantaCruzRevalida = lazy(() =>
	import('./Pages/LandingPages/FreeContent/HardworkEm/SantaCruz/Registration-23-05-2023.jsx')
);
const PreRegistrationEventRevalidaFoz = lazy(() =>
	import('./Pages/LandingPages/FreeContent/HardworkEm/FozDoIguacu/Pre-Registration-03-06-2023.jsx')
);
const RegistrationEventRevalidaPontaPora = lazy(() =>
	import('./Pages/LandingPages/FreeContent/HardworkEm/PontaPora/Registration-14-06-2023.jsx')
);
const RegistrationEventRevalidaBuenosAires = lazy(() =>
	import('./Pages/LandingPages/FreeContent/HardworkEm/BuenosAires/Registration-16-06-2023.jsx')
);
const PreRegistrationEventRevalidaSantaCruz = lazy(() =>
	import('./Pages/LandingPages/FreeContent/HardworkEm/SantaCruz/Pre-Registration-23-05-2023.jsx')
);
const PreRegistrationEventRevalidaBuenosAires = lazy(() =>
	import('./Pages/LandingPages/FreeContent/HardworkEm/BuenosAires/Pre-Registration-no-date.jsx')
);
const RegistrationEventFortaleza = lazy(() =>
	import('./Pages/LandingPages/FreeContent/HardworkEm/Fortaleza/Registration-31-05-2023.jsx')
);
const RegistrationEventSaoLuis = lazy(() =>
	import('./Pages/LandingPages/FreeContent/HardworkEm/SaoLuis/Registration-30-05-2023.jsx')
);
const RegistrationEventRecife = lazy(() =>
	import('./Pages/LandingPages/FreeContent/HardworkEm/Recife/Registration-22-06-2023.jsx')
);
const RegistrationEventSalvador = lazy(() =>
	import('./Pages/LandingPages/FreeContent/HardworkEm/Salvador/Registration-23-06-2023.jsx')
);

// REVALIDAY
const RevalidayFoz = lazy(() =>
	import('./Pages/LandingPages/FreeContent/HardworkEm/FozDoIguacu/Registration-2024.jsx')
);
const RevalidayPonta = lazy(() =>
	import('./Pages/LandingPages/FreeContent/HardworkEm/PontaPora/Registration-2024.jsx')
);
const RevalidayCocha = lazy(() =>
	import('./Pages/LandingPages/FreeContent/HardworkEm/Cochabamba/Registration-2024.jsx')
);
const RevalidaySantaCruz = lazy(() =>
	import('./Pages/LandingPages/FreeContent/HardworkEm/SantaCruz/Registration-2024.jsx')
);

// REVALIDAY CONFIRMATION
const RevalidayFozSuccess = lazy(() =>
	import('./Pages/LandingPages/FreeContent/HardworkEm/FozDoIguacu/Registration-2024-confirmation.jsx')
);
const RevalidayPontaSuccess = lazy(() =>
	import('./Pages/LandingPages/FreeContent/HardworkEm/PontaPora/Registration-2024-confirmation.jsx')
);
const RevalidayCochaSuccess = lazy(() =>
	import('./Pages/LandingPages/FreeContent/HardworkEm/Cochabamba/Registration-2024-confirmation.jsx')
);
const RevalidaySantaCruzSuccess = lazy(() =>
	import('./Pages/LandingPages/FreeContent/HardworkEm/SantaCruz/Registration-2024-confirmation.jsx')
);

// EMAIL CONFIRMATION
const MailConfirmationFoz = lazy(() =>
	import('./Pages/LandingPages/FreeContent/HardworkEm/FozDoIguacu/Confirmation-email.jsx')
);
const MailConfirmationSantaCruz = lazy(() =>
	import('./Pages/LandingPages/FreeContent/HardworkEm/SantaCruz/Confirmation-email.jsx')
);

// Outros componentes
const R3CirurgiaGeral = lazy(() => import('./Pages/LandingPages/Sale/R3/R3CirurgiaGeral.jsx'));
const R3ClinicaMedica = lazy(() => import('./Pages/LandingPages/Sale/R3/R3ClinicaMedica.jsx'));
// const routesProductsTemplate = lazy(() => import('./Components/Utils/RoutesConfig/RoutesProductsTemplate.jsx'));
const DoughnutChart = lazy(() => import('./Components/Hardworq/DounghnutRadar/index.jsx'));
const VendasTrilha25 = lazy(() => import('./Pages/LandingPages/Subscription/Residencia/Vendas-Trilha-25.jsx'));
const HandsOn2024 = lazy(() => import('./Pages/LandingPages/HandsOn/Hands-on-2024.jsx'));
const FormsTeste = lazy(() => import('./Components/Utils/Forms/Trilha25Survey'));

// import { SprintFinalR12024 } from './Pages/LandingPages/Sale/SprintFinal/SprintFinal-R1-2024.jsx';
//import HandsOn2024 from './Components/Utils/TemplatesContent/Hands-on.jsx';

//HARDWORQ
const TrilhaR12024 = lazy(() => import('./Pages/LandingPages/TrilhaR1-2024.jsx'));
const TrilhaR12024Maio = lazy(() => import('./Pages/LandingPages/TrilhaR1-2024-Maio.jsx'));
const LiveDasLivesNPS = lazy(() => import('./Pages/LandingPages/NPS/Live-das-lives.jsx'));
const ArenaNps = lazy(() => import('./Pages/LandingPages/NPS/Arena-presencial-2024.jsx'));
const TemplateSprint2023 = lazy(() => import('./Pages/LandingPages/Sale/SprintFinal/TemplateSprint-2023.jsx'));
const HardworkQ2024 = lazy(() => import('./Pages/LandingPages/Hardworq-2024.jsx'));
const HardworkQ2024Trial = lazy(() => import('./Pages/LandingPages/Hardworq-trial.jsx'));
const TrilhaRPlus2024 = lazy(() => import('./Pages/LandingPages/TrilhaRPlus-2024.jsx'));

// LIVES
const LiveResidencia2023 = React.lazy(() =>
	import('./Pages/LandingPages/Subscription/Residencia/Live-13-02-2023-Residencia.jsx')
);
const LiveTipsRevalida = React.lazy(() =>
	import('./Pages/LandingPages/Subscription/Revalida/Live-02-03-2023-Tips.jsx')
);
const ReactRevalida = React.lazy(() => import('./Pages/LandingPages/Correction/React-Revalida-10-08-2023.jsx'));
const ReactRevalidaEspecial = React.lazy(() =>
	import('./Pages/LandingPages/Correction/Correction-Edicao-Especial.jsx')
);
const LiveReactRevalida = React.lazy(() =>
	import('./Pages/LandingPages/Subscription/Revalida/Live-10-08-2023-React.jsx')
);
const LiveArenaRelease = React.lazy(() =>
	import('./Pages/LandingPages/Subscription/Revalida/Live-08-04-2023-Arena.jsx')
);
const LiveSprintFinal2023 = React.lazy(() =>
	import('./Pages/LandingPages/Subscription/Revalida/Live-Sprint-Final-2023.jsx')
);
const LiveSubscriptionTrilhaRevalida2024 = React.lazy(() =>
	import('./Pages/LandingPages/Subscription/Revalida/Live-Subscription-Trilha-Revalida-2024.jsx')
);
const LiveTrilhaRevalida2024 = React.lazy(() =>
	import('./Pages/LandingPages/Subscription/Revalida/Live-Trilha-Revalida-2024.jsx')
);
const LiveSubscriptionSprintFinal2023 = React.lazy(() =>
	import('./Pages/LandingPages/Subscription/Residencia/Live-Release-Sprint-Final.jsx')
);
const LiveTrilhaRevalidaRelease2024 = React.lazy(() =>
	import('./Pages/LandingPages/Subscription/Revalida/Live-Trilha-Revalida-Release-2024.jsx')
);
const Live261023DiscussionExam = React.lazy(() =>
	import('./Pages/LandingPages/Subscription/Revalida/Live-26-10-2023.jsx')
);
const LiveDiscussionRevalida = React.lazy(() => import('./Pages/LandingPages/Correction/Live-Discussion-Revalida.jsx'));
const EsquentaLdl = React.lazy(() => import('./Pages/LandingPages/Esquenta-ldl.jsx'));
const LiveDasLivesRevalida2024 = React.lazy(() => import('./Pages/LandingPages/Live-das-lives-revalida-2024.jsx'));
const LivePreSubSprintRevalida2024 = React.lazy(() =>
	import('./Pages/LandingPages/Subscription/Revalida/Live-Pre-Sub-Sprint-Revalida-2024.jsx')
);
const LiveDasLivesResidenciaTurmaQuintoAno2024 = React.lazy(() =>
	import('./Pages/LandingPages/Sale/LDL/Residencia-Turma-Quinto-Ano-2024.jsx')
);
const CorrectionLiveResidencia2024 = React.lazy(() =>
	import('./Pages/LandingPages/Correction/Correction-Live-Residencia-2024.jsx')
);
const LiveDasLivesResidenciaTurmaQuintoAno2024SusBa = React.lazy(() =>
	import('./Pages/LandingPages/Sale/LDL/Residencia-Turma-Quinto-Ano-2024-SUS-BA.jsx')
);
const LiveDasLivesResidenciaTurmaQuintoAno2024UFRJ = React.lazy(() =>
	import('./Pages/LandingPages/Sale/LDL/Residencia-Turma-Quinto-Ano-2024-UFRJ.jsx')
);
const LiveDasLivesResidenciaTurmaQuintoAno2024PsuMg = React.lazy(() =>
	import('./Pages/LandingPages/Sale/LDL/Residencia-Turma-Quinto-Ano-2024-PSU-MG.jsx')
);
const LiveDasLivesResidenciaTurmaQuintoAno2024UspSp = React.lazy(() =>
	import('./Pages/LandingPages/Sale/LDL/Residencia-Turma-Quinto-Ano-2024-USP-SP.jsx')
);
const LiveDasLivesResidenciaTurmaQuintoAno2024SusSp = React.lazy(() =>
	import('./Pages/LandingPages/Sale/LDL/Residencia-Turma-Quinto-Ano-2024-SUS-SP.jsx')
);
const LiveR3USP = React.lazy(() => import('./Pages/LandingPages/LiveR3USP/Live-R3-USP.jsx'));
const LiveDasLivesResidenciaTurma2024IdomedUfrj = React.lazy(() =>
	import('./Pages/LandingPages/Sale/LDL/Residencia-Turma-Idomed-2024-UFRJ.jsx')
);

export function Router() {
	const [now] = useState(new Date());
	const [targetDateNps] = useState(new Date('2025-01-27T22:00:00'));

	const codes = [
		'ABC',
		'ACB',
		'AEBES',
		'AFAMCI',
		'AMP',
		'AMRIGS',
		'AMS-APUCARANA',
		'AMS-LONDRINA',
		'AMS Londrina',
		'AOCP',
		'APCC',
		'AUSTA',
		'CCANSPS',
		'CEOQ',
		'CEPOA',
		'CEREM',
		'CERMAM',
		'CESUPA',
		'CMC',
		'CENTRO OFTALMOLÓGICO DE CÁCERES',
		'CSNSC',
		'CVV',
		'EFCE',
		'EINSTEIN',
		'ESCOLA MULTICAMPI DE CIÊNCIAS MÉDICAS',
		'EMESCAM',
		'ENADE',
		'ENARE',
		'ESPCE',
		'FAMEMA',
		'FAMERP',
		'FBHC',
		'FCC',
		'TEGO',
		'FESF',
		'FESO',
		'FHEMIG',
		'FJG',
		'FMC',
		'FMJ',
		'FMP',
		'FUBOG',
		'HA-AL',
		'HACC',
		'HAC-PR',
		'HAS',
		'HA-SP',
		'HCB',
		'HCB RO',
		'HCE',
		'HCG',
		'HC-MT',
		'HCP',
		'HCPA',
		'HC UFG',
		'HDG',
		'HEA',
		'HECI',
		'HEDA',
		'HEJSN',
		'HEL',
		'HEVV',
		'HFA',
		'HFR',
		'HGG GO',
		'HIS',
		'HIVS',
		'HMAR',
		'HMA-SP',
		'HMDI',
		'HMJLF',
		'HMMG',
		'HMMKB',
		'HMTJ',
		'HNJ',
		'HNMD',
		'HOA',
		'HOA-AC',
		'HOB-DF',
		'HOC',
		'HOC-TO',
		'HOG',
		'HOG-SP',
		'HOS',
		'HEPV',
		'HPEV',
		'HPM-MG',
		'HPP',
		'HRD',
		'HRMS',
		'HRPG',
		'HSA-GUARUJA',
		'HSD-MA',
		'HSN',
		'HSI-SE',
		'HSJA',
		'HSJ-AC',
		'HSJC',
		'HSJC-SC',
		'HSJ - PR',
		'QUADRIX HSLDF',
		'HSL-DF',
		'HSL-SP',
		'HSM-DF',
		'HSM-MT',
		'HSP-SP',
		'HST',
		'HUBFS-HUJBB',
		'HUOL',
		'HUSE',
		'HVL',
		'IAMSPE',
		'ICRS',
		'IFF',
		'INCA',
		'INEP',
		'IOG',
		'IPSEMG',
		'ISCMA',
		'ISCMSC',
		'MULTIVIX',
		'OASE',
		'PASTEUR',
		'PCM',
		'PMC',
		'PMF',
		'PMFI',
		'PMSO',
		'POLICLIN',
		'PSU-AL',
		'PSU-CE',
		'PSU-MG',
		'PUC-PR',
		'PUC-RS',
		'PUC-SP',
		'QUADRIX FHGV',
		'QUADRIX-FMJ',
		'TIJUCAS CM 01 e 02',
		'RMEAP',
		'SCMA-SP',
		'SCM-BH',
		'SANTA CASA DE MISERICÓRDIA DE BELO HORIZONTE SCMBH',
		'SCM-BM',
		'Santa Casa de Misericórdia de Campo Grande',
		'SCM-CG',
		'SCM-GO',
		'SCML',
		'SCMM',
		'SCMMA',
		'SCM-MA',
		'SCMRP',
		'SCM-RP',
		'SCMSJRP',
		'SCM-SP',
		'SCMV',
		'SCO',
		'SEMAD',
		'SES-DF',
		'SES-GO',
		'SES-MA',
		'SECRETARIA DE ESTADO DA SAÚDE DO MARANHÃO - SES-MA',
		'SES-PB',
		'SES-PE',
		'SES-RJ',
		'SES-SC',
		'SGCH',
		'SISE',
		'SMA-VR',
		'SMA VR',
		'SM-RJ',
		'SMS-CG',
		'SMS-SG',
		'SMS-FLORIPA',
		'SMS-JP',
		'SMS-OLIMPIA',
		'SMS-PIRACICABA',
		'SMS Piracicaba',
		'SMS-PR',
		'SMS-SINOP',
		'SMS-SJP',
		'Secretaria Municipal de São Paulo',
		'Secretaria Municipal da Saúde de São Paulo - SP',
		'SMS-SP',
		'Secretaria Municipal de Saúde de São Paulo',
		'SMS SP',
		'SSPPG',
		'SUPREMA',
		'SURCE',
		'SUS-BA',
		'SUS-RR',
		'SUS-SP',
		'TECM',
		'AMIB',
		'TEP',
		'UCPEL',
		'UDI',
		'UEL',
		'UEM',
		'UEPA',
		'UERJ',
		'UE-RJ',
		'UERN',
		'UESPI',
		'UFAL',
		'UFCG',
		'UGCG',
		'UFCG R1 2021',
		'UFCSPA',
		'UFES',
		'UFF',
		'UFFS',
		'UFG',
		'UFGD',
		'UFMA',
		'UFMS',
		'UFMT',
		'HOSPITAL UNIVERSITÁRIO JÚLIO MÜLLER - UFMT',
		'UFPA',
		'UFPB',
		'UFPE',
		'UFPI',
		'UFPR',
		'UFRJ',
		'UFRR',
		'UFS',
		'UFSC',
		'UFSCAR',
		'UFSM',
		'UFT',
		'UFU',
		'UNAERP',
		'UNB',
		'UNCISAL',
		'UNESC-ES',
		'Centro Universitário do Espirito Santo',
		'UNESP',
		'UNICAMP',
		'UNICESUMAR',
		'UNIFESP',
		'UNIGRANRIO',
		'UNIMED-RJ',
		'UNIMED RJ',
		'UNIMONTES',
		'UNIOESTE',
		'UNIRG',
		'UNIRIO',
		'UNIRV',
		'UNITAU',
		'UNIUBE',
		'USP',
		'USP-RP',
		'USS',
		'VUNESP',
	];

	const ldl_editions = [
		'surce',
		'enare',
		'usp-rp',
		'sus-ba',
		'sus-ba-vitoria-da-conquista',
		'sus-ba-juazeiro-petrolina',
		'amrigs',
		'psu-mg',
		'psu-mg-online',
		'famerp',
		'unifesp',
		'usp',
		'usp-online',
		'sus',
		'iamspe',
		'santa-casa-sp',
		'ses-psu-go',
		'unicamp',
		'ufrj',
	];

	const revalida_cities = [
		'salvador',
		'recife',
		'sao-paulo',
		'brasilia',
		'porto-velho',
		'curitiba',
		'belo-horizonte',
		'campo-grande',
		'porto-alegre',
		'rio-branco',
		'teste',
		'rio-de-janeiro',
	];

	const sprintExams = [
		'surce',
		'enare',
		'einstein',
		'amp',
		'amrigs',
		'usp',
		'famema',
		'fmj',
		'hsl',
		'iamspe',
		'sus',
		'ufes',
		'uerj',
		'ufrj',
		'unicamp',
		'unifesp',
		'abc',
		'psu-go',
		'psu-mg',
		'santa-casa-sp',
		'ses-df',
		'ses-pe',
		'sus-ba',
		'unesp-botucatu',
		'usp-rp',
	];

	const sprintFinalPages = [
		{ name: 'ABC', id: '1383' },
		{ name: 'AMP', id: '1384' },
		{ name: 'AMRIGS', id: '1385' },
		{ name: 'EINSTEIN', id: '1386' },
		{ name: 'ENARE', id: '1387' },
		// { name: 'FAMEMA', id: '1388'},
		{ name: 'FAMERP', id: '1388' },
		// { name: 'F.MED. JUNDIAÍ', id: '1305'},
		{ name: 'SIRIO-LIBANES', id: '1394' },
		// { name: 'IAMSPE', id: '1307'},
		{ name: 'PSU-GO', id: '1389' },
		{ name: 'PSU-MG', id: '1390' },
		{ name: 'SANTACASA-SP', id: '1391' },
		{ name: 'SES-DF', id: '1392' },
		{ name: 'SES-PE', id: '1393' },
		// { name: 'SURCE', id: '1314'},
		{ name: 'SUS-BA', id: '1395' },
		{ name: 'SUS-SP', id: '1396' },
		{ name: 'UERJ', id: '1397' },
		{ name: 'UFES', id: '1398' },
		{ name: 'URFJ', id: '1399' },
		{ name: 'UNESP-BOTUCATU', id: '1400' },
		{ name: 'UNICAMP', id: '1401' },
		{ name: 'UNIFESP', id: '1UNICAMP402' },
		{ name: 'USP', id: '1403' },
		{ name: 'USP-RIBEIRAO', id: '1404' },
	];

	const ldl_exams = [
		'enare',
		'einstein',
		'surce',
		'amp',
		'ufes',
		'usp-rp',
		'uerj',
		'ufrj',
		'amrigs',
		'sus-ba',
		'unicamp',
		'psu-go',
		'abc',
		'unesp',
		'psu-mg',
		'unifesp',
		'hsl',
		'fmj',
		'usp-sp',
		'famerp',
		'santa-casa-sp',
		'sus',
	];

	const ldl_exams_correction = ['sus-ba', 'psu-mg', 'usp-sp', 'sus-sp'];

	const codeGeneralNps = ['santa-casa-sp', 'sus', 'usp', 'psu-mg', 'ufrj', 'sus-ba', 'amrigs'];

	const codeRevalidaLDLS = ['rcf', 'poa', 'ctb', 'svd', 'rb', 'pv', 'bsb', 'bh', 'cg', 'sp'];

	// const arenaCodes = ['rcf', 'poa', 'ctb', 'svd', 'rb', 'pv', 'bsb', 'bh', 'cg', 'sp'];

	const subscriptionPages_urls = [
		'trilha-residencia',
		'trilha-residencia-2-anos',
		'trilha-r3-cg',
		'trilha-r3-cm',
		'arena-revalida',
		'sprint-final-revalida',
		'sprint-final/r3-cm',
		'hardworq/r3-cg-usp',
	];

	const hardworkPeloSulUrls = [
		'hardwork-pelo-sul/enare-dominado',
		'hardwork-pelo-sul/ldl-revalida',
		'hardwork-pelo-sul/tep',
		'hardwork-pelo-sul/tego',
	];

	return (
		<Suspense fallback={<LoaderSpinner />}>
			<Routes>
				{/* Institutional */}
				{/* <Route
                    path="/"
                    element={<DefaultLayout />}
                >
                    <Route
                        path="/"
                        element={<HomeTestPage />}
                    />
                </Route> */}
				<Route
					path="/"
					element={<Home />}
				/>
				<Route
					path="/treinamentos"
					element={<Products />}
				/>
				<Route
					path="/faq"
					element={<Faq />}
				/>
				<Route
					path="/fale-conosco"
					element={<Contact />}
				/>
				<Route
					path="/sobre-nos"
					element={<AboutUs />}
				/>
				<Route
					path="/residencia-medica"
					element={<ResidenciaMedica2023 />}
				/>
				<Route
					path="/revalida-inep"
					element={<Revalida2023 />}
				/>
				{/* Landing Pages */}
				<Route
					path="/extensivo-r1"
					element={<ExtensivoR12023 />}
				/>
				<Route
					path="/extensivo-r1-2024"
					element={<ExtensivoR12024 />}
				/>
				<Route
					path="/trilha-r1-2024"
					element={<TrilhaR12024 />}
				/>
				<Route
					path="/trilha-r1-2024-turma-maio"
					element={<TrilhaR12024Maio />}
				/>
				<Route
					path="/trilha-rplus-2024"
					element={<TrilhaRPlus2024 />}
				/>
				<Route
					path="/trilha-r1-2024-2025"
					element={<Navigate to="/trilha-r1-2-anos" />}
				/>
				<Route
					path="/trilha-r1-2-anos"
					element={<TrilhaR120242025 />}
				/>
				<Route
					path="/trilha-r1-express-2024"
					element={<TrilhaR1Express2024 />}
				/>
				<Route
					path="/extensivo-r1-alunos-idomed"
					element={<ExtensivoR12023Idomed />}
				/>
				<Route
					path="/extensivo-r1-2-anos-alunos-idomed"
					element={<ExtensivoR120232024Idomed />}
				/>
				<Route
					path="/extensivo-r1-2-anos"
					element={<ExtensivoR120232024 />}
				/>
				<Route
					path="/trilha-revalida-2026"
					element={<TrilhaRevalida232425 />}
				/>
				<Route
					path="/download-hardworq"
					element={<HardworqDownloadRedirect />}
				/>
				{/* here */}
				<Route
					path="/trilha-revalida"
					element={<TrilhaRevalida232425 />}
				/>
				<Route
					path="/trilha-revalida-express"
					element={<TrilhaRevalida232425Express />}
				/>
				<Route
					path="/extensivo-r1-migracao"
					element={<ExtensivoR1Migracao2023 />}
				/>
				<Route
					path="/extensivo-r3-clinica-medica"
					element={<ExtensivoR3ClinicaMedica />}
				/>
				<Route
					path="/trilha-r3-clinica-medica"
					element={<TrilhaR3Cm />}
				/>
				<Route
					path="/extensivo-r3-cirurgia-geral"
					element={<ExtensivoR3CirurgiaGeral />}
				/>
				{/* <Route
                    path="/live-das-lives-revalida"
                    element={<LiveDasLivesRevalida2023 />}
                /> */}
				<Route
					path="/live-das-lives"
					element={<LiveDasLives />}
				/>
				<Route
					path="/correcao-de-prova"
					element={<LiveDeCorrecao />}
				/>
				<Route
					path="/teste-componente"
					element={<FormsTeste />}
				/>
				<Route
					path="/live/correcao-prova-pratica-revalida-24-2"
					element={<CorrectionArena20241 />}
				/>
				<Route
					path="/live-das-lives-2024"
					element={<LiveDasLivesSubscription />}
				/>
				{ldl_editions.map((contest, index) => {
					return (
						<Route
							key={index}
							path={`/live-das-lives-${contest}`}
							element={<LiveDasLivesResidenciaTemplate2024 />}
						/>
					);
				})}
				<Route
					path={`/live-das-lives-quinto-ano/enare`}
					element={<LiveDasLivesResidenciaTurmaQuintoAno2024 />}
				/>
				<Route
					path={`/live-das-lives-quinto-ano/sus-ba`}
					element={<LiveDasLivesResidenciaTurmaQuintoAno2024SusBa />}
				/>
				<Route
					path={`/live-das-lives-quinto-ano/ufrj`}
					element={<LiveDasLivesResidenciaTurmaQuintoAno2024UFRJ />}
				/>
				<Route
					path={`/live-das-lives/idomed-ufrj`}
					element={<LiveDasLivesResidenciaTurma2024IdomedUfrj />}
				/>
				<Route
					path={`/live-das-lives-quinto-ano/psu-mg`}
					element={<LiveDasLivesResidenciaTurmaQuintoAno2024PsuMg />}
				/>
				<Route
					path={`/live-das-lives-quinto-ano/usp-sp`}
					element={<LiveDasLivesResidenciaTurmaQuintoAno2024UspSp />}
				/>
				<Route
					path={`/live-das-lives-quinto-ano/sus-sp`}
					element={<LiveDasLivesResidenciaTurmaQuintoAno2024SusSp />}
				/>
				{revalida_cities.map((contest, index) => {
					return (
						<Route
							key={index}
							path={`/live-das-lives-revalida/${contest}`}
							element={<RevalidaTemplate2024 />}
						/>
					);
				})}
				<Route
					path="/live-das-lives-revalida"
					element={<LiveDasLivesRevalida2024 />}
				/>
				<Route
					path="/sou-hardwork"
					element={<SubscriptionSouHardwork />}
				/>
				<Route
					path="/sou-hardwork-inscricao"
					element={<SubscriptionSouHardworkForm />}
				/>
				<Route
					path="/migracao-trilha-revalida"
					element={<TrilhaRevalidaMigracao2023 />}
				/>
				<Route
					path="/pesquisa-revalida-inep-pratica"
					element={<AppAssesmentPage />}
				/>
				<Route
					path="/pesquisa-revalida-inep-objetiva"
					element={<ResearchRevalida27042024 />}
				/>
				{/* <Route
					path="/legiao-hardwork"
					element={<LegiaoHardworkResidencia />}
				/> */}
				<Route
					path="/legiao-hardwork"
					element={<LegiaoHardworkResidencia2 />}
				/>
				<Route
					path="/pesquisa-revalida-inep"
					element={<RevalidaResearchPage />}
				/>
				{/* Sale */}
				<Route
					path="/arena-hardwork"
					// element={<ArenaSale2023 />}
					element={<ArenaSale20242 />}
				/>
				<Route
					path="/arena-hardwork-pro"
					// element={<ArenaSale2023 />}
					element={<ArenaSale2024 />}
				/>
				<Route
					path="/sprint-final-revalida"
					element={<SprintFinalRevalida />}
				/>
				<Route
					path="/sprint-final"
					//element={<SprintFinalR12024 />}
					element={<SprintFinalResidencia2024 />}
				/>
				<Route
					path="/pesquisa-hardworq"
					//element={<SprintFinalR12024 />}
					element={<PesquisaHQ />}
				/>
				{/* <Route
                    path="/sprint-final/enare"
                    //element={<SprintFinalR12024 />}
                    element={<SprintFinalEnare />}
                /> */}
				{sprintFinalPages?.map((e, i) => (
					<Route
						key={i}
						path={`/sprint-final/${e?.name}`}
						//element={<SprintFinalR12024 />}
						element={<SprintFinalEnare turmaId={e?.id} />}
					/>
				))}
				<Route
					path="/sprint-final-r1"
					element={<Navigate to="/sprint-final" />}
				/>
				{sprintExams.map((code, i) => (
					<Route
						key={i}
						path={`/sprint-final/${code}`}
						element={<TemplateSprint2023 code={code} />}
					/>
				))}
				<Route
					path="/sprint-final-r3"
					element={<SprintFinalR32023 />}
				/>
				<Route
					path="/trilha-r1-2025"
					element={<Navigate to="/trilha-r1" />}
				/>
				<Route
					path="/trilha-r1"
					element={<TrilhaR1Residencia2025 />}
				/>
				{/* Subscription */}
				<Route
					path="/pre-inscricao/extensivo-r1-2-anos"
					element={<ExtensivoR12324Subscription />}
				/>
				<Route
					path="/pre-inscricao/arena-online-24-2"
					element={<SubscriptionEventArena20242 />}
				/>
				<Route
					path="/pre-inscricao/arena-hardwork"
					element={<Arena />}
				/>
				{ldl_exams_correction.map((exam, i) => {
					return (
						<Route
							key={i}
							path={`/pre-inscricao/correcao-${exam}-:id`}
							element={<Arena />}
						/>
					);
				})}
				<Route
					path="/hardworq"
					element={<HardworkQ2024 />}
				/>
				<Route
					path="/hardworq-premium"
					element={<HardworkQ2024Trial />}
				/>
				<Route
					path="/hardworq/cirurgia"
					element={<R3CirurgiaGeral />}
				/>
				<Route
					path="/sprint-final/r3-clinica-medica"
					element={<R3ClinicaMedica />}
				/>
				<Route
					path="/apphardworq-radarchart-1"
					element={<RadarChartPerformance />}
				/>
				<Route
					path="/apphardworq-radarchart-2"
					element={<RadarQuestions />}
				/>
				<Route
					path="/apphardworq-stackedbarchart"
					element={<StackedBarChart />}
				/>
				<Route
					path="/apphardworq-doungnutchart"
					element={<DoughnutChart />}
				/>
				<Route
					path="/pre-inscricao/abertura-arena-hardwork"
					element={<LiveDemo />}
				/>
				<Route
					path="/pre-inscricao/live-treinamento-discursiva-revalida"
					element={<LiveProvaDiscursivaRevalida />}
				/>
				<Route
					path="/pre-inscricao/revalida-foz"
					element={<PreRegistrationEventRevalidaFoz />}
				/>
				<Route
					path="/pre-inscricao/revalida-santa-cruz"
					element={<PreRegistrationEventRevalidaSantaCruz />}
				/>
				<Route
					path="/pre-inscricao/revalida-buenos-aires"
					element={<PreRegistrationEventRevalidaBuenosAires />}
				/>
				<Route
					path="/simulado-discursiva-unicamp"
					element={<LiveMockTestUnicampSubscription />}
				/>
				{sprintExams.map((code, i) => (
					<Route
						key={i}
						path={`/download-raio-x-${code}-:id`}
						element={<RaioXTemplateSubscription />}
					/>
				))}
				{codes.map((code) => (
					<Route
						key={code}
						path={`/materiais/cadernos-prova-${code.toLowerCase().replace(/ /g, '+')}`}
						element={<TestsLibrarySubscriptionPage />}
					/>
				))}
				<Route
					path="/materiais/pesquisa-prova-pratica-revalida-24-2"
					element={<CorrectionArena20242Materiais />}
				/>
				<Route
					path="/inscricao-revisao-enare"
					element={<EnareReviewSubscriptionPage />}
				/>
				{subscriptionPages_urls.map((url, index) => (
					<Route
						key={index}
						path={`/pre-inscricao/${url}`}
						element={<TemplateSalePage20232 />}
					/>
				))}
				<Route
					path={`/pre-inscricao/trilha-2025-r1`}
					element={<VendasTrilha25 />}
				/>
				<Route
					path={`/pre-inscricao/sprint-final-2024`}
					element={<SprintFinal2024Pre />}
				/>
				{/* Template de página de pré inscrição - Produtos 2024 */}
				{routesProductsTemplate.map((route, index) => (
					<Route
						key={index}
						path={route.path}
						element={<route.component {...route.props} />}
					/>
				))}
				{/* Lives */}
				<Route
					path="/live/preparacao-prova-residencia"
					element={<LiveResidencia2023 />}
				/>
				<Route
					path="/live/inscricao-react-revalida"
					element={<LiveReactRevalida />}
				/>
				{/* {ldl_exams.map((exam, index) => {
                    return (
                        <Route
                            key={index}
                            path={`/live/correcao-${exam}-:id`}
                            element={<CorrectionLiveResidencia />}
                        />
                    );
                })} */}
				{ldl_exams_correction.map((exam, index) => {
					return (
						<Route
							key={index}
							path={`/live/correcao-${exam}-:id`}
							element={<CorrectionLiveResidencia2024 />}
						/>
					);
				})}
				<Route
					path="/live/arena-hardwork"
					element={<LiveArenaRelease />}
				/>
				<Route
					path="/live/dicas-de-ultima-hora-revalida-inep"
					element={<LiveTipsRevalida />}
				/>
				<Route
					path="/live/7-erros-prova-pratica-inep"
					element={<LiveTipsRevalida08052023 />}
				/>
				<Route
					path="/live/inscricao-sprint-final-revalida-2024"
					element={<LiveSprintFinal2023 />}
				/>
				<Route
					path="/live/inscricao-trilha-revalida-2024"
					element={<LiveSubscriptionTrilhaRevalida2024 />}
				/>
				<Route
					path="/live/trilha-revalida"
					element={<LiveTrilhaRevalida2024 />}
				/>
				<Route
					path="/live/inscricao-sprint-final"
					element={<LiveSubscriptionSprintFinal2023 />}
				/>
				<Route
					path="/live/abertura-trilha-revalida-2024"
					element={<LiveTrilhaRevalidaRelease2024 />}
				/>
				<Route
					path="/live/r3-clinica-usp"
					element={<LiveR3191023Subscription />}
				/>
				<Route
					path="/live/estudo-usp"
					element={<LiveR1251023Subscription />}
				/>
				<Route
					path="/live/discussao-revalida-inep"
					element={<Live261023DiscussionExam />}
				/>
				<Route
					path="/live-discussao-revalida-inep"
					element={<LiveDiscussionRevalida />}
				/>
				<Route
					path="/esquenta-ldl-usp"
					element={<EsquentaLdl />}
				/>
				<Route
					path="/live/abertura-sprint-revalida"
					element={<LivePreSubSprintRevalida2024 />}
				/>
				{/* Free Content */}
				<Route
					path="/materiais/central-de-concursos"
					element={<ContestCentral2023 />}
				/>
				<Route
					path="/materiais/revalida-foz"
					element={<RegistrationEventFozRevalida />}
				/>
				<Route
					path="/materiais/revalida-santa-cruz"
					element={<RegistrationEventSantaCruzRevalida />}
				/>
				<Route
					path="/materiais/hardwork-revalida-em-ponta-pora"
					element={<RegistrationEventRevalidaPontaPora />}
				/>
				<Route
					path="/materiais/hardwork-revalida-em-buenos-aires"
					element={<RegistrationEventRevalidaBuenosAires />}
				/>
				<Route
					path="/materiais/hardwork-em-fortaleza"
					element={<RegistrationEventFortaleza />}
				/>
				<Route
					path="/materiais/hardwork-em-sao-luis"
					element={<RegistrationEventSaoLuis />}
				/>
				<Route
					path="/materiais/hardwork-em-recife"
					element={<RegistrationEventRecife />}
				/>
				<Route
					path="/materiais/hardwork-em-salvador"
					element={<RegistrationEventSalvador />}
				/>
				<Route
					path="/materiais/banco-de-lives"
					element={<LiveBank />}
				/>
				<Route
					path="/materiais/residencia-medica/:concurso-:id"
					element={<ContestTemplate />}
				/>
				<Route
					path="/materiais/curso-emergencia-campina-grande"
					element={<CampinaGrande2023 />}
				/>
				<Route
					path="/materiais/yduqs"
					element={<YduqsPage />}
				/>
				<Route
					path="/materiais/edital-revalida-2024-2"
					element={<EditalLinkRevalida20241 />}
				/>
				{codes.map((code) => (
					<Route
						key={code}
						path={`/materiais/cadernos-prova-${code.toLowerCase().replace(/ /g, '+')}-download`}
						element={<TestsTemplatePage />}
					/>
				))}
				<Route
					path="/materiais/simulado-discursiva-unicamp"
					element={<DiscursiveMockUnicamp />}
				/>
				<Route
					path="/materiais/semana-eletrocardiograma"
					element={<SemanaEletrocardiograma />}
				/>
				<Route
					path="/materiais/semana-tep"
					element={<SemanaTEP />}
				/>
				<Route
					path="/materiais/desafio-enare"
					element={<DesafioEnare />}
				/>
				<Route
					path="/live/react-revalida-marco"
					element={<ReactRevalida />}
				/>
				<Route
					path="/correcaodeprovarevalida2024"
					element={<ReactRevalidaEspecial />}
				/>
				{/* redirect para a correção, vindo da pre-inscricao */}
				<Route
					path="/pre-inscricao/live-correcao-revalida-inep"
					element={<Navigate to="/correcaodeprovarevalida2024" />}
				/>
				{/* <Route
                    path="/pre-inscricao/live-correcao-revalida-inep"
                    element={<RevalidaSpecialEdition />}
                /> */}
				<Route
					path="/materiais/atualizacao-temas-2023-2024"
					element={<RevalidaDownload />}
				/>
				{/* <Route
                    path="/materiais/ldl-revalida-edicao-especial"
                    element={<RevalidaEdicaoEspecial />}
                /> */}
				<Route
					path="/hardwork-pelo-sul"
					element={<HardworkSolidario />}
				/>
				<Route
					path="/materiais/demo-campina-grande"
					element={<DemoPage />}
				/>
				{hardworkPeloSulUrls.map((urlItem, index) => {
					return (
						<Route
							key={index}
							path={urlItem}
							element={<TemplateHwmSolidario />}
						/>
					);
				})}
				{/* Redirect all 404's to home */}
				<Route
					path="*"
					element={<Home />}
				/>
				{/* Redirects */}
				<Route
					path="/extensivo-r1-2023"
					element={<Navigate to="/extensivo-r1" />}
				/>
				<Route
					path="/extensivo-r1-2023-2024"
					element={<Navigate to="/extensivo-r1-2-anos" />}
				/>
				<Route
					path="/extensivo-r1-2023-migracao"
					element={<Navigate to="/extensivo-r1-migracao" />}
				/>
				<Route
					path="/live-das-lives-revalida-2023"
					element={<Navigate to="/live-das-lives-revalida" />}
				/>
				<Route
					path="/revaliday/foz-do-iguacu"
					element={<RevalidayFoz />}
				/>
				<Route
					path="/revaliday/ponta-pora"
					element={<RevalidayPonta />}
				/>
				<Route
					path="/revaliday/cochabamba"
					element={<RevalidayCocha />}
				/>
				<Route
					path="/revaliday/santa-cruz-de-la-sierra"
					element={<RevalidaySantaCruz />}
				/>
				<Route
					path="/revaliday/foz-do-iguacu-sucesso"
					element={<RevalidayFozSuccess />}
				/>
				<Route
					path="/revaliday/foz-do-iguacu-obrigado-:email"
					element={<MailConfirmationFoz />}
				/>
				<Route
					path="/revaliday/santa-cruz-de-la-sierra-obrigado-:email"
					element={<MailConfirmationSantaCruz />}
				/>
				<Route
					path="/revaliday/foz-do-iguacu-:email"
					element={<MailConfirmationFoz />}
				/>
				<Route
					path="/revaliday/ponta-pora-obrigado-:email"
					element={<RevalidayPontaSuccess />}
				/>
				<Route
					path="/revaliday/cochabamba-sucesso"
					element={<RevalidayCochaSuccess />}
				/>
				<Route
					path="/revaliday/santa-cruz-de-la-sierra-sucesso"
					element={<RevalidaySantaCruzSuccess />}
				/>
				<Route
					path="/hands-on"
					element={<HandsOn2024 />}
				/>
				<Route
					path="/live-r3-cm-usp"
					element={<LiveR3USP />}
				/>
				{/* NPS */}
				{targetDateNps >= now &&
					codeGeneralNps.map((code) => (
						<Route
							key={code}
							path={`/live-das-lives/nps/${code.toLowerCase().replace(/ /g, '+')}`}
							element={<LiveDasLivesNPS code={code} />}
						/>
					))}
				{/* NPS REVALIDA */}
				{codeRevalidaLDLS.map((code) => (
					<Route
						key={code}
						path={`/live-das-lives/revalida/nps/${code}`}
						element={
							<LiveDasLivesNPS
								code={code}
								type="revalida"
							/>
						}
					/>
				))}
				{/* NPS ARENA */}
				<Route
					path={`/arena-presencial-2024/nps/:id`}
					element={<ArenaNps type="revalida" />}
				/>
				<Route
					path={`/inscricao-hardwork-juazeiro-norte`}
					element={<SubscriptionHardworkJuazeiro />}
				/>
				{/* teresina */}
				<Route
					path={`/inscricao-hardwork-teresina`}
					element={<SubscriptionHardworkTeresina />}
				/>
				{/* juazeiro */}
				<Route
					path={`/inscricao-hardwork-juazeiro-petrolina`}
					element={<SubscriptionHardworkJua />}
				/>
			</Routes>
		</Suspense>
	);
}
